import React, { Fragment } from "react";

import {
    Column,
    ColumnContainer,
    Divider,
    TitleContainer,
    SectionTitle,
    Paragraph,
} from "@beacharound/beacharound-ui";

export default function RulesList({ title, rules, type }) {

    let realRules = rules

    if (type) {
        realRules = rules?.filter(r => r.type === type);
    }

    if (!realRules) {
        return <Fragment></Fragment>;
    }

    return (
        <ColumnContainer noMargin>
            <Column>
                <TitleContainer>
                    <SectionTitle text={title || "Regole della spiaggia"} />
                    {realRules?.map(({description}, index) => {
                        return <Paragraph key={index} text={`• ${description}`} />
                    })}
                </TitleContainer>
                <Divider/>
            </Column>
        </ColumnContainer>
    )
}