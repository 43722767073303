import { lazy } from 'react';
import MainLayout from "../layouts/MainLayout/MainLayout";

// import SearchExperiences from "../pages/Experience/SearchExperiences";
// import ExperienceDetail from "../pages/Experience/ExperienceDetail";
// import ExperiencePriceList from "../pages/Experience/ExperiencePriceList";

const SearchExperiences = lazy(() => import("../pages/Experience/SearchExperiences"));
const ExperienceDetail = lazy(() => import("../pages/Experience/ExperienceDetail"));
const ExperiencePriceList = lazy(() => import("../pages/Experience/ExperiencePriceList"));

const generalRoutes = [
    {
        path: "/esperienze",
        component: SearchExperiences,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        exact: true
    },
    {
        path: "/esperienze/:experienceSlug",
        component: ExperienceDetail,
        layout: MainLayout,
        exact: true
    },
    {
        path: "/esperienze/:experienceSlug/listino",
        component: ExperiencePriceList,
        layout: MainLayout,
        exact: true
    }
]

export default generalRoutes