import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { MainContainer, MainForm, MainFormContent, SelectionBoxInput, StepsHeader, BookingBar, LoadingContainer } from '@beacharound/beacharound-ui';

import { getHotel } from '../../../api/hotels';
import { getPublicQuotation, updateQuotation } from '../../../api/quotations';
import { differenceInCalendarDays } from 'date-fns';
import { formatDate } from '@beacharound/beacharound-ui-helpers';

export default function QuotationHotelServices({match, history}) {

    const { hotelSlug, quotationId } = match.params;
    const { data: hotel, isLoading: isLoadingHotel } = useQuery(["hotels", hotelSlug], () =>
        getHotel({ slug: hotelSlug })
    )

    const { data: quotation, isLoading: isLoadingQuotation, error } = useQuery(["quotations", quotationId], () =>
        getPublicQuotation({ quotationId })
    )

    const {mutate: update, status } = useMutation(updateQuotation, {
        onSuccess: () => history.push(`/preventivo/${hotelSlug}/${quotationId}/riepilogo`)
    });

    const [ services, setServices] = useState({});

    const parkingOptions = []

    function updateServices(name, value) {
        setServices({...services, [name]: value})
    }

    async function nextStep() {
        try {

            await update({
                quotationId,
                data: {
                    additions: Object.keys(services).map((key) => {

                        const days = differenceInCalendarDays(new Date(quotation?.checkout), new Date(quotation?.checkin)) + 1

                        return {
                            product: services[key]?._id,
                            productType: "products",
                            price: services[key]?.price * days
                        }
                    })
                }
            })

        } catch (error) {
            console.log(error);
        }
    }

    if (!quotationId || error || (quotation && quotation.status !== "CHECKINGOUT")) {
        return <Redirect to={`/preventivo/${hotelSlug}/date`} />
    }

    return (
        <Fragment>
            {hotel && (
                <StepsHeader
                    title={`${hotel?.name} - ${quotation?.products?.[0]?.product?.name}`}
                    date={`${formatDate(new Date(quotation?.checkin), "dd MMMM")} - ${formatDate(new Date(quotation?.checkout), "dd MMMM")}`}
                    subtitle="Rendi speciale la tua vacanza"
                    stepsLink={[
                        "date",
                        "camera",
                        "persone",
                        "servizi",
                        "riepilogo",
                    ]}
                    linkComponent={Link}
                    marginBottom
                />
            )}

            <MainContainer>
                <LoadingContainer isLoading={isLoadingHotel || isLoadingQuotation}>
                    <MainForm>
                        <MainFormContent>
                            <SelectionBoxInput
                                options={parkingOptions}
                                name="parking"
                                label="Parcheggio"
                                onChangeInput={updateServices}
                            />
                        </MainFormContent>
                    </MainForm>

                    <BookingBar
                        text="Scegli i servizi aggiuntivi"
                        action={nextStep}
                        buttonText="AVANTI"
                        status={status}
                    />
                </LoadingContainer>
            </MainContainer>
        </Fragment>
    );
}