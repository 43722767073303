import React, { useState, Fragment } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import qs from "query-string";

import { BookingBar, ButtonsRow, LoadingContainer, MainButton, MainContainer, NormalPopup, Paragraph, TitleContainer, Divider } from '@beacharound/beacharound-ui';
import { MapBuilderGrid, MapConfigurator, MapContainer, MapContext } from '@beacharound/beacharound-map';

import { getBeach, getBeachMap } from '../../../api/beach';
import { cleanQueryPlacedProductsReservation } from '../../../helpers/placedProducts';

export default function BookingBeachPublicMap({match, history, step, setIsTooLate}) {

    const { beachSlug } = match.params;
    const { search } = useLocation();
    const parsedSearch = qs.parse(search);
    const { date, type, availability} = parsedSearch;

    const [ errorPopup, setErrorPopup] = useState()
    const [ selectedProduct, setSelectedProduct] = useState()

    const { data: beach, isLoading: isLoadingBeach } = useQuery(["beaches", beachSlug], () =>
        getBeach({ slug: beachSlug })
    )

    const options = {
        slug: beachSlug,
        params: {
            fromDate: date,
            toDate: date,
            durationType: type,
            relatedProduct: availability
        }
    }

    const { data: beachMap, isLoading } = useQuery(["beachMap", options], () =>
        getBeachMap(options),
        {enabled: !!beach}
    )

    const placedProducts = cleanQueryPlacedProductsReservation(beachMap?.placedProducts)

    function nextStep() {
        if (!selectedProduct) {
            return setErrorPopup(true)
        }

        let newSearch = {
            ...parsedSearch,
            placedProduct: selectedProduct?._id
        }
        return history.push(`${match.url.replace("posto", step.next)}?${qs.stringify(newSearch)}`)
    }

    function onCellSelectHandler(data) {
        return setSelectedProduct(data)
    }

    return (

        <Fragment>
            <LoadingContainer isLoading={isLoading || isLoadingBeach}>
                <MapContainer marginBottom fullWidth fullHeight low>
                    <MapConfigurator
                        imagesPathRoot={`${process.env.REACT_APP_IMAGES_PATH_ROOT}/products/`}
                        placedProducts={placedProducts}
                        selectedCells={selectedProduct}
                        onCellSelect={(data) => onCellSelectHandler(data)}
                        options={{
                            minSize: 50,
                            columns: beachMap?.map?.columns,
                            rows: beachMap?.map?.rows,
                            sort: {
                                vertical: beachMap?.map?.verticalDirection,
                                horizontal: beachMap?.map?.horizontalDirection
                            }
                        }}
                    >
                        <MapContext.Consumer>
                            {() => {
                                return (
                                    <Fragment>
                                        <MapBuilderGrid noIndex center></MapBuilderGrid>
                                    </Fragment>
                                )
                            }}
                        </MapContext.Consumer>
                    </MapConfigurator>
                </MapContainer>
            </LoadingContainer>

            <MainContainer>
                <BookingBar
                    text={step?.title}
                    action={nextStep}
                    buttonText="AVANTI"
                    icon="arrow-right"
                    fixed
                />
            </MainContainer>

            {/* ERRORE */}
            <NormalPopup
                visible={errorPopup}
                setVisible={setErrorPopup}
                title="Attenzione"
            >
                <TitleContainer>
                    <Paragraph text="Non hai ancora selezionato un posto valido"/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill center>
                    <MainButton text="Ok" action={() => setErrorPopup()}/>
                </ButtonsRow>
            </NormalPopup>
        </Fragment>
    )
}