import React from 'react';
import { Link } from 'react-router-dom'
import { MainContainer, TitleContainer, MainButton, MainTitle, Paragraph, ButtonsRow, Footer, FullImageContainer, MainLayoutContent, MainLayoutContainer } from '@beacharound/beacharound-ui';
import MainHeader from '../components/MainHeader';
import { getPlaceholderImage } from '../helpers/images/getPlaceholderImage';

export default function NotFound() {

    return (

        <MainLayoutContainer>
            <MainHeader/>

            <MainLayoutContent noPadding>
                <FullImageContainer image={getPlaceholderImage(undefined, undefined, "/placeholders/not-found.jpg")} high/>

                <MainContainer>
                    <TitleContainer center>
                        <MainTitle text="Pagina non trovata" component="h1" center/>
                        <Paragraph text="Ci dispiace.. la pagina che stai cercando non esiste" center/>
                    </TitleContainer>

                    <ButtonsRow center>
                        <MainButton text="Torna alla home" component={Link} to="/"/>
                    </ButtonsRow>
                </MainContainer>
            </MainLayoutContent>

            <Footer/>
        </MainLayoutContainer>

    )
}