import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { MainContainer, StepsHeader, NumericInput, MainFormContent, BookingBar, LoadingContainer } from '@beacharound/beacharound-ui';

import { getHotel } from '../../../api/hotels';
import { getPublicQuotation, updateQuotation } from '../../../api/quotations';
import { formatDate } from '@beacharound/beacharound-ui-helpers';

export default function QuotationHotelPeople({match, history}) {

    const [people, setPeople] = useState({});
    const [bookingText, setBookingText] = useState('Scegli il numero di persone');

    const { hotelSlug, quotationId } = match.params;
    const { data: hotel, isLoading: isLoadingHotel } = useQuery(["hotels", hotelSlug], () =>
        getHotel({ slug: hotelSlug })
    )

    const { data: quotation, isLoading: isLoadingQuotation, error } = useQuery(["quotations", quotationId], () =>
        getPublicQuotation({ quotationId })
    )

    const {mutate: update, status } = useMutation(updateQuotation, {
        onSuccess: () => history.push(`/preventivo/${hotelSlug}/${quotationId}/riepilogo`)
    });

    function changePeopleHandler(name, value) {
        return setPeople({...people, [name]: value})
    }

    function getPeopleDefaultValue(name) {
        return people[name]
    }

    async function nextStep() {
        try {
            if (!people.adults) {
                return setBookingText('Il numero di adulti è obbligatorio')
            }

            await update({
                quotationId,
                data: {
                    details: Object.keys(people).map(key => {
                        return {
                            key,
                            value: people[key]
                        }
                    })
                },
            });

        } catch (error) {
            console.log(error);
        }
    }

    if (!quotationId || error || (quotation && quotation.status !== "CHECKINGOUT")) {
        return <Redirect to={`/preventivo/${hotelSlug}/date`} />
    }

    return (
        <Fragment>
            <StepsHeader
                title={`${hotel?.name} - ${quotation?.products?.[0]?.product?.name}`}
                stepsNum={4}
                activeIndex={2}
                date={`${formatDate(new Date(quotation?.checkin), "dd MMMM")} - ${formatDate(new Date(quotation?.checkout), "dd MMMM")}`}
                subtitle="Indica quante persone"
                stepsLink={[
                    "date",
                    "camera",
                    "persone",
                    // "servizi",
                    "riepilogo",
                ]}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer>
                <LoadingContainer isLoading={isLoadingHotel || isLoadingQuotation}>
                    <MainFormContent>
                        <NumericInput
                            label="Numero adulti"
                            name="adults"
                            defaultValue={getPeopleDefaultValue}
                            onChangeInput={changePeopleHandler}
                        />
                        <NumericInput
                            label="Numero bambini"
                            sublabel="da 2 a 12 anni"
                            name="children"
                            defaultValue={getPeopleDefaultValue}
                            onChangeInput={changePeopleHandler}
                        />
                        <NumericInput
                            label="Numero neonati"
                            sublabel="da 0 a 2 anni"
                            name="babies"
                            defaultValue={getPeopleDefaultValue}
                            onChangeInput={changePeopleHandler}
                        />
                    </MainFormContent>

                    <BookingBar
                        text={bookingText}
                        action={nextStep}
                        status={status}
                        buttonText="AVANTI"
                    />
                </LoadingContainer>
            </MainContainer>
        </Fragment>
    );
}