import React, { Fragment, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router';
import qs from 'query-string'

import { MainContainer, TitleContainer, SectionTitle, ColumnContainer, Column, MainFormContent, SimpleField, Divider, LoadingContainer, ButtonsRow, MainButton, NormalPopup, Paragraph } from '@beacharound/beacharound-ui';
import { formatDate, trackPurchase } from '@beacharound/beacharound-ui-helpers';

import SignUserComponent from '../../../components/User/SignUserComponent';
import { getUser } from '../../../api/user';
import { getBeach, getBeachProducts } from '../../../api/beach';
import { createPublicReservation, createHybridReservation } from '../../../api/reservations/beaches';

export default function BookingBeachPublicReview({ match, history }) {

    const { beachSlug } = match.params;
    const [errorPopup, setErrorPopup] = useState()
    const { search } = useLocation();
    const { externalWebsiteUrl, externalSocialLink, date, type, placedProduct, availability, people, hybrid } = qs.parse(search);
    let futureQuery;

    if (externalWebsiteUrl) {
        futureQuery = { externalWebsiteUrl }
    }
    if (externalSocialLink) {
        futureQuery = { externalSocialLink }
    }

    const { data: user, isLoading: isLoadingUser } = useQuery("user", getUser);
    const { data: beach } = useQuery(["beaches", beachSlug], () =>
        getBeach({ slug: beachSlug }),
        { enabled: !!beachSlug }
    );
    const { data: availabilities, isLoading: isLoadingAvailabilities } = useQuery(['products', beachSlug, 'public-availabilities'], () =>
        getBeachProducts({ slug: beachSlug, params: { params: { type: "public-availabilities" } } }),
        { enabled: !!beach && !!beachSlug }
    );
    const { mutate: create, status } = useMutation(hybrid ? createHybridReservation : createPublicReservation, {
        onSuccess: (data) => {

            trackPurchase({
                _id: data._id,
                name: `reservation | ${beach?.name} | ${type || "daily"}`,
                type: hybrid ? "hybrid-beach" : "public-beach",
                activity: beach?.name,
                category: type || "daily",
                value: "0",
                price: "0",
                transaction: data._id
            });

            if (futureQuery) {
                return history.push(`/prenotazione/grazie/${data?._id}?${qs.stringify(futureQuery)}`)
            }
            return history.push(`/prenotazione/grazie/${data?._id}`)
        },
        onError: (error) => {
            if (error?.response?.data?.message) {
                return setErrorPopup({ message: error?.response?.data?.message });
            }
            return setErrorPopup({});
        }
    });

    const availabilityItem = availabilities?.find(item => item._id === availability)
    const dateString = date && formatDate(date, 'iiii dd MMMM')

    function submitHandler() {
        const payload = {
            checkin: formatDate(new Date(date), "yyyy-MM-dd"),
            addition: availability,
            people,
            placedProduct,
            durationType: hybrid ? "daily" : type
        };

        return create({
            beachSlug,
            data: payload
        });
    }

    function errorHandler() {
        history.push(`/spiagge/${beachSlug}`);
    }

    return (
        <MainContainer marginTop>
            <LoadingContainer isLoading={isLoadingUser || isLoadingAvailabilities}>
                {!user &&
                    <SignUserComponent
                        match={match}
                        history={history}
                        isPublicBeach
                    />
                }

                {user &&
                    <ColumnContainer lowMargin>
                        <Column>
                            <TitleContainer>
                                <SectionTitle text="Panoramica prenotazione" />
                            </TitleContainer>

                            <Column>
                                <MainFormContent flex>
                                    <SimpleField
                                        label="Data della prenotazione"
                                        value={dateString && dateString.charAt(0).toUpperCase() + dateString.slice(1)}
                                        half
                                    />
                                    {!hybrid &&
                                        <Fragment>
                                            <SimpleField
                                                label="Fascia giornaliera"
                                                value={`${type === 'morning' ? 'Solo mattina' : 'Solo pomeriggio'}`}
                                                half
                                            />
                                            <SimpleField
                                                label="Tipologia di accesso"
                                                value={availabilityItem?.name || '-'}
                                                half
                                            />
                                        </Fragment>
                                    }
                                    <SimpleField
                                        label="Numero di persone"
                                        value={people || '-'}
                                        half
                                    />
                                </MainFormContent>
                            </Column>

                            <Divider />

                            <TitleContainer>
                                <SectionTitle text="I tuoi dati" />
                            </TitleContainer>

                            <Column>
                                <MainFormContent flex>
                                    <SimpleField
                                        label="Nome"
                                        value={`${user.firstName} ${user.lastName}`}
                                        half
                                    />
                                    <SimpleField
                                        label="Email"
                                        value={user.email || '-'}
                                        half
                                    />
                                    <SimpleField
                                        label="Numero di telefono"
                                        value={user.phone || '-'}
                                        half
                                    />
                                </MainFormContent>
                            </Column>

                            <Divider />

                            <ButtonsRow fill flexEnd>
                                <MainButton
                                    text="Conferma richiesta"
                                    action={submitHandler}
                                    status={status}
                                />
                            </ButtonsRow>
                        </Column>
                    </ColumnContainer>
                }
            </LoadingContainer>

            {/* ERRORE */}
            <NormalPopup
                visible={errorPopup}
                setVisible={errorHandler}
                title="Attenzione"
            >
                <TitleContainer>
                    <Paragraph text={errorPopup?.message || "Ops.. si è verificato un errore. Ti preghiamo di riprovare più tardi."} />
                </TitleContainer>
                <Divider />
                <ButtonsRow fill center>
                    <MainButton text="Ok" action={errorHandler} />
                </ButtonsRow>
            </NormalPopup>
        </MainContainer>
    );
}