import React from 'react';

import { ButtonsRow, MainButton, NormalPopup, Paragraph, TitleContainer } from '@beacharound/beacharound-ui';

export default function PopupError({
    visible,
    setVisible,
    title = "Ops... si è verificato un errore",
    message = "Ops... purtroppo si è verificato un errore!"
}) {
    return (
        <NormalPopup
            visible={visible}
            setVisible={setVisible}
            title={title}
        >
            <TitleContainer>
                <Paragraph text={message} />
            </TitleContainer>

            <ButtonsRow center>
                <MainButton border text="Ho capito" action={() => setVisible()} />
            </ButtonsRow>
        </NormalPopup>
    );
}