import axios from "axios";
import qs from 'query-string';

export async function uploadImage({url, file}) {

    try {

        const headers = qs.parse(url?.split("?")[1]) || {};

        await axios.put(url, file, {
            headers: {
                "Cache-Control": headers["Cache-Control"],
                "Content-Type": headers["Content-Type"],
            },
        });

    } catch(error) {
        throw error;
    }


}