import Cookies from 'js-cookie';

import axiosV2 from "../_defaults/v2/user";
import anonymousV2 from '../_defaults/v2/anonymous';

export async function createReservation({ data }) {
    try {
        const userId = Cookies.get("user-id");
        const response = await anonymousV2.post(
            `/reservations/offers`,
            data,
            { user: userId }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function updateReservation({ reservationId, data }) {
    try {
        const userId = Cookies.get("user-id");
        const response = await anonymousV2.post(
            `/reservations/offers/${reservationId}`,
            data,
            { user: userId }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getReservations({ params }) {
    try {
        const response = await axiosV2.get(`/reservations/offers`, {
            params,
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getReservation({ reservationId, params }) {
    try {
        const response = await axiosV2.get(`/reservations/offers/${reservationId}`, {
            params,
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getPublicReservation({ reservationId, customerId }) {
    try {
        const response = await anonymousV2.get(`/reservations/public/${customerId}/${reservationId}`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return {};
        console.log(error.response);
        throw error;
    }
}

export async function sendReservation({ reservationId }) {
    try {
        const response = await axiosV2.post(`/reservations/offers/${reservationId}/send`, {
            status: "PENDING",
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function replyReservation({ reservationId, data }) {
    try {
        const response = await axiosV2.post(`/reservations/offers/${reservationId}/send`, {
            ...data,
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function connectReservation({ reservationId }) {
    try {
        const response = await axiosV2.post(`/reservations/offers/${reservationId}/user`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function connectAndSendReservation({ reservationId }) {
    try {
        await connectReservation({ reservationId });
        const response = await sendReservation({ reservationId });
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}