import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from "react-query";
import { MainContainer, MainTitle, TitleContainer, ColumnContainer, Column, SimpleLink } from '@beacharound/beacharound-ui';

import { getRegions } from '../../api/places';

export default function RegionsSitemap() {

    let options = {
        params: {},
        skip: 0,
        limit: 2000000,
        sort: {name: 1}
    }

    const { data } = useQuery(["regions", options], () =>
        getRegions({ params: options })
    )

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Mappa del sito - Regioni" component="div" />
            </TitleContainer>

             <ColumnContainer flex>
                <Column column={2}>
                    <SimpleLink text={`Spiagge in Italia`} to={`/le-migliori-spiagge-italiane`} component={Link} />
                </Column>
                <Column column={2}>
                    <SimpleLink text={`Hotel in Italia`} to={`/i-migliori-hotel-italiani`} component={Link} />
                </Column>
                {data?.map((sitemap, index) => {
                    return (
                        <Column column={2} key={index}>
                            <SimpleLink index={sitemap.slug} text={`Spiagge in ${sitemap.name}`} to={`/le-migliori-spiagge-in-${sitemap.slug}`} component={Link} />
                        </Column>
                    )
                })}
                {data?.map((sitemap, index) => {
                    return (
                        <Column column={2} key={index}>
                            <SimpleLink index={sitemap.slug} text={`Hotel in ${sitemap.name}`} to={`/i-migliori-hotel-in-${sitemap.slug}`} component={Link} />
                        </Column>
                    )
                })}
            </ColumnContainer>
        </MainContainer>
    );
}