import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { LoginLayoutContainer, LoginLayoutContent } from '@beacharound/beacharound-ui';
import MainFooter from '../../components/MainFooter';

export default function LoginLayout({ children }) {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);


    return (

        <LoginLayoutContainer>
            <LoginLayoutContent>
                {children}
            </LoginLayoutContent>

            <MainFooter/>
        </LoginLayoutContainer>
    )
}
