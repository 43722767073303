import axiosV2 from '../_defaults/v2/user';
import anonymousV2 from '../_defaults/v2/anonymous';

export async function getReservations({ params }) {
    try {
        const response = await axiosV2.get(
            `/reservations`,
            {
                params,
            }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}

export async function getReservation({ activitySlug, reservationId }) {
    try {
        const response = await axiosV2.get(
            `/reservations/${activitySlug}/${reservationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return {};
        }

        throw error;
    }
}

export async function getPublicReservation({ activitySlug, reservationId }) {
    try {
        const response = await anonymousV2.get(`/reservations/${activitySlug}/${reservationId}`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}