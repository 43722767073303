import axiosV2 from './_defaults/v2/anonymous';

export async function getHotels({ params }) {
    try {
        const response = await axiosV2.get(`/hotels`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getHotel({ slug, params }) {
    try {
        const response = await axiosV2.get(`/hotels/${slug}`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getOffers({ params }) {
    try {
        const response = await axiosV2.get(`/hotels/offers`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getOffer({ slug, params }) {
    try {
        const response = await axiosV2.get(`/hotels/offers/${slug}`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getHotelOffers({ hotel, params }) {
    try {
        const response = await axiosV2.get(`/hotels/${hotel}/offers`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getRoom({ slug, params }) {
    try {
        const response = await axiosV2.get(`/hotels/rooms/${slug}`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getHotelRooms({ hotel, params }) {
    try {
        const response = await axiosV2.get(`/hotels/${hotel}/rooms`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getHotelContacts({ slug, params }) {
    try {
        const response = await axiosV2.get(`/hotels/${slug}/publicContacts`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}
