import React from 'react';
import { Link } from 'react-router-dom';
import { MainContainer, MainTitle, TitleContainer, ColumnContainer, Column, SimpleLink } from '@beacharound/beacharound-ui';

import beachesCategoriesPages from '../../data/pages/beachesCategoriesPages';

export default function CategoriesSitemap() {

    const data = beachesCategoriesPages?.map((item) => {
        return {
            name: item.title,
            slug: item.slug
        }
    })

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Mappa del sito - Categorie" component="div" />
            </TitleContainer>

             <ColumnContainer flex>
                {data?.map((sitemap, index) => {
                    return (
                        <Column column={2} key={index}>
                            <SimpleLink index={sitemap.slug} text={sitemap.name} to={`/${sitemap.slug}`} component={Link} />
                        </Column>
                    )
                })}
            </ColumnContainer>
        </MainContainer>
    );
}