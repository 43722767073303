import { lazy } from 'react';
import beachesServicesPages from '../../data/pages/beachesServicesPages';

const SearchBeaches = lazy(() => import("../../pages/Beach/SearchBeaches"));
const MainLayout = lazy(() => import("../../layouts/MainLayout/MainLayout"));
const BeachesService = lazy(() => import("../../pages/Beach/BeachesService"));
const BeachesServiceDetail = lazy(() => import("../../pages/Beach/BeachesServiceDetail"));

const beachesServicesRoutes = [...beachesServicesPages.map(({ slug, title }) => {
    return {
        path: `/${slug}`,
        component: BeachesService,
        layoutOptions: { noPadding: true },
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["seo", "Servizi", title]
        }
    };
}), {
    path: "/servizi",
    component: SearchBeaches,
    layoutOptions: { noPadding: true },
    layout: MainLayout,
    exact: true,
    analytics: {
        name: ["Servizi"]
    }
}, {
    path: "/servizi/:serviceSeoSlug",
    component: BeachesServiceDetail,
    layoutOptions: { noPadding: true },
    layout: MainLayout,
    exact: true
}];

export default beachesServicesRoutes;
