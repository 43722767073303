import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { MainContainer, StepsHeader, Calendar, BookingBar, LoadingContainer } from '@beacharound/beacharound-ui';

import { getHotel } from '../../../api/hotels';
import { createQuotation, updateQuotation } from '../../../api/quotations';
import { trackAddToCart } from "@beacharound/beacharound-ui-helpers";

export default function QuotationHotelDate({match, history}) {

    const { hotelSlug, quotationId } = match.params;

    const { data: hotel, isLoading: isLoadingHotel } = useQuery(["hotels", hotelSlug], () =>
        getHotel({ slug: hotelSlug })
    )

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [bookingText, setBookingText] = useState('Scegli le date');

    const {mutate: create, status} = useMutation(createQuotation, {
        onSuccess: (data) => {
            trackAddToCart({
                _id: hotel?._id,
                name: "quotation",
                type: "hotel",
                activity: hotel?.name,
            });

            return history.push(`/preventivo/${hotelSlug}/${data?._id}/camera`);
        }
    });
    const {mutate: update, status: statusUpdate} = useMutation(updateQuotation, {
        onSuccess: () => history.push(`/preventivo/${hotelSlug}/${quotationId}/camera`)
    });

    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    async function nextStep() {
        try {

            if (!startDate || !endDate) {
                return setBookingText('Non hai ancora inserito le date')
            }

            if (quotationId) {
                await update({
                    quotationId,
                    data: {
                        checkin: startDate,
                        checkout: endDate,
                        hotelSlug
                    }
                })
            } else {
                await create({
                    data: {
                        checkin: startDate,
                        checkout: endDate,
                        hotelSlug
                    }
                });
            }
        } catch(error) {
            console.log(error);
        }
    }

    return (

        <Fragment>
            <StepsHeader
                title={hotel?.name}
                date=""
                stepsNum={4}
                activeIndex={0}
                subtitle="Scegli una data per il preventivo"
                stepsLink={[
                    "date",
                    "camera",
                    "persone",
                    // "servizi",
                    "riepilogo",
                ]}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer>
                <LoadingContainer isLoading={isLoadingHotel}>
                    <Calendar
                        startDate={startDate}
                        onChange={onChange}
                        endDate={endDate}
                        minDate={startDate && endDate ? new Date() : (startDate || new Date())}
                    />

                    <BookingBar
                        text={bookingText}
                        action={nextStep}
                        status={status || statusUpdate}
                        buttonText="AVANTI"
                    />
                </LoadingContainer>
            </MainContainer>
        </Fragment>
    )
}
