import { getDescription, getMetaDescription, getSubtitle } from "../../helpers/seo/services";

const initialSlug = "prenota-un-ombrellone-nelle-migliori-spiagge-";
const initialTitle = "Prenota il tuo ombrellone nelle spiagge";
// const initialCoverImage = "https://source.unsplash.com/600x400/?beach,sea";

const services = [{
    slug: 'fitness',
    name: 'adatte al fitness',
    image: 'fitness',
    listName: 'Fitness',
    icon: 'fitness',
    services: [
        {name:'con area fitness', slug:`fitness`, serviceSlugs:['fitness']},
        {name:'con palestra', slug:`palestra`, serviceSlugs:['palestra']},
        {name:'con piscina', slug:`piscina`, serviceSlugs:['piscina']},
        {name:'con sauna', slug:`sauna`, serviceSlugs:['sauna']},
        {name:'con personal trainer', slug:`personal-trainer`, serviceSlugs:['personal-trainer']},
    ]
},{
    slug: 'divertimento',
    name: 'per il divertimento',
    image: 'fun',
    listName: 'Divertimento',
    icon: 'fun',
    services: [
        {name:'con sport acquatici', slug:`sport-acquatici`, serviceSlugs:['sport-acquatici']},
        {name:'con noleggio', slug:`noleggio`, serviceSlugs:['noleggio']},
        // {name:'con campo da calcio', slug:`campo-da-calcio`, serviceSlugs:['campo-da-calcio']},
        // {name:'con campo da rugby', slug:`campo-da-rugby`, serviceSlugs:['campo-da-rugby']},
        // {name:'con campo da tennis', slug:`campo-da-tennis`, serviceSlugs:['campo-da-tennis']},
        // {name:'con campo da beachvolley', slug:`campo-da-beachvolley`, serviceSlugs:['campo-da-beachvolley']},
        {name:'con campi sportivi', slug:`campi-sportivi`, serviceSlugs:['campo-da-beachvolley','campo-da-calcio','campo-da-rugby','campo-da-tennis']},
        {name:'con discoteca', slug:`discoteca`, serviceSlugs:['discoteca']},
        {name:'con feste in spiaggia', slug:`feste-in-spiaggia`, serviceSlugs:['feste-in-spiaggia']},
        {name:'con bar', slug:`bar`, serviceSlugs:['bar']},
        {name:'con area aperitivi', slug:`aperitivi`, serviceSlugs:['aperitivi']},
    ]
},{
    slug: 'relax',
    name: 'adatte al relax',
    image: 'relax',
    listName: 'Relax',
    icon: 'relax',
    services: [
        {name:'con centro benessere', slug:`centro-benessere`, serviceSlugs:['centro-benessere']},
        {name:'per non fumatori', slug:`non-fumatori`, serviceSlugs:['non-fumatori']},
        {name:'con ristoranti', slug:`ristoranti`, serviceSlugs:['ristorante']},
        {name:'con sauna', slug:`sauna`, serviceSlugs:['sauna']},
        {name:'con solarium', slug:`solarium`, serviceSlugs:['solarium']},
        {name:'con spa', slug:`spa`, serviceSlugs:['spa']},
        {name:'con area yoga', slug:`yoga`, serviceSlugs:['yoga']},
    ]
},{
    slug: 'bambini',
    name: 'adatte ai bambini',
    image: 'baby',
    listName: 'Bambini',
    icon: 'baby',
    services: [
        {name:'con area-bimbi', slug:`area-bimbi`, serviceSlugs:['bimbi']},
        {name:'con animazione', slug:`animazione`, serviceSlugs:['animazione']},
        {name:'con animazione', slug:`animazione-per-bambini`, serviceSlugs:['animazione-per-bambini']},
        {name:'con baby sitter', slug:`baby-sitter`, serviceSlugs:['baby-sitter']},
        {name:'con nursery', slug:`nursery`, serviceSlugs:['nursery']},
        {name:'con giochi per bambini', slug:`giochi-bambini`, serviceSlugs:['giochi-bambini']},
        {name:'con culle per bambini', slug:`culle-per-bambini`, serviceSlugs:['culle-per-bambini']},
    ]
}]

function getServicePage({slug, name, image, listName, icon, services}) {
    return {
        slug: `${initialSlug}${slug}`,
        title: `${initialTitle} ${name}`,
        subtitle: getSubtitle(name),
        description: getDescription(name),
        image: image,
        name: listName,
        icon: icon,
        meta: { description: getMetaDescription(name) },
        nearest: {
            title: "Le spiagge più vicine a te",
            subtitle: getSubtitle(name),
            nearest: true,
            services: services?.reduce((accumulator, service) => { return accumulator = [...accumulator, ...service.serviceSlugs?.map((s) => s)]}, [])
        },
        highlight: {
            title: "Le spiagge consigliate",
            subtitle: getSubtitle(name),
            highlight: true,
            services: services?.reduce((accumulator, service) => { return accumulator = [...accumulator, ...service.serviceSlugs?.map((s) => s)]}, [])
        },
        blocks: services?.map((service) => {
            return {
                title: `${initialTitle} ${service.name}`,
                description: getDescription(service.name),
                slug: `/servizi/${initialSlug}${service.slug}`,
                services: service.serviceSlugs?.map((s) => s)
            }
        })
    }
}

const beachesCategoriesPages = services.map((service) => {
    return getServicePage(service);
})

export default beachesCategoriesPages;

