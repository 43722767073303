import React, { Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';

import { CardPayment, Column, ColumnContainer, LoadingContainer, MainButton, MainContainer, Paragraph, SimpleField, ButtonsRow } from '@beacharound/beacharound-ui';
import { trackPurchase } from '@beacharound/beacharound-ui-helpers';

import { getStripeClientSecret } from '../../api/payments';
import { connectGroupReservations } from '../../api/reservations/groups';
import { connectReservation, connectToPublicPayment, checkReservation, confirmReservationWithLocalPayment } from '../../api/reservations/beaches'

import PopupError from '../Popups/PopupError';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function ReservationPaymentComponent({ price, paymentId, reservationId, groupId, reservationsId, activityId, activitySlug, activityName, returnUrl, setIsTooLate, isTooLate, isFood, isEvents, isRental, canPayLocal, onlyPayLocal, eventSlug, reservationToConfirm, coupon, paymentType, paymentValue }) {

    const history = useHistory();
    const cache = useQueryClient();

    const [errorPopup, setErrorPopup] = useState();

    let returnUrlPath = `/pagamento/${paymentId}/grazie`;
    if (reservationId) {
        returnUrlPath = `/prenotazione/spiaggia/${activitySlug}/${reservationId}/grazie`
    }
    if (groupId) {
        returnUrlPath = `/prenotazione/spiaggia/${activitySlug}/gruppi/${groupId}/grazie`
    }
    if (isFood) {
        returnUrlPath = `/ordine-in-spiaggia/${activitySlug}/${reservationId}/grazie`
    }
    if (isEvents) {
        returnUrlPath = `/prenotazione-eventi/${activitySlug}/${eventSlug}/${reservationId}/grazie`
    }
    if (isRental) {
        returnUrlPath = `/prenotazione-servizi/${activitySlug}/${reservationId}/grazie`
    }

    function onConfirmHandler() {
        let productName = "payment";
        if (reservationId) {
            productName = "reservation"
        }
        if (groupId) {
            productName = "reservations_group"
        }
        if (isFood) {
            productName = "food"
        }
        if (isEvents) {
            productName = "event"
        }
        if (isRental) {
            productName = "rental"
        }

        if (activityName) {
            productName = `${productName} | ${activityName}`;
        }

        trackPurchase({
            _id: reservationId || paymentId || groupId,
            name: productName,
            activity: activityName,
            type: "beach",
            value: price,
            transaction: paymentId
        });
        cache.invalidateQueries(['reservations', reservationId])
        if (returnUrl) {
            return history.push(`${returnUrlPath}?externalWebsiteUrl=${returnUrl}`)
        }
        return history.push(returnUrlPath)
    }

    let connectRightFunction = connectReservation;
    if (groupId) {
        connectRightFunction = connectGroupReservations;
    }
    if (paymentId) {
        connectRightFunction = connectToPublicPayment;
    }
    // const { mutate: connect, isLoading: isConnectLoading } = useMutation(reservationId ? connectReservation : connectToPublicPayment, {
    const { mutate: connect, isLoading: isConnectLoading } = useMutation(connectRightFunction, {
        onSuccess: onConfirmHandler
    });

    const { mutateAsync: confirm, status } = useMutation(confirmReservationWithLocalPayment, {
        onSuccess: () => {
            cache.invalidateQueries(['reservations', reservationId]);
            connect({ reservationId, beachSlug: activitySlug });
        },
        onError: (error) => {
            if (error?.response) {
                setErrorPopup(error.response?.data?.message || true);
            }
        }
    });

    const { mutateAsync: check } = useMutation(checkReservation, {
        onSuccess: (data) => data,
        onError: (error) => {
            if (error?.response?.status === 410) {
                setIsTooLate(true)
            }
            throw error;
        }
    });

    async function checkReservationHandler() {
        if (!reservationId && !!groupId) {
            return await Promise.all(reservationsId.map(async (reservationIdGroup) => await check({ beachSlug: activitySlug, reservationId: reservationIdGroup })))
        }
        if (!reservationId && !!paymentId) {
            return;
        }
        return await check({ beachSlug: activitySlug, reservationId });
    }

    useQuery(["reservation", "check", reservationId], checkReservationHandler, {
        enabled: !!reservationId && !isTooLate,
        refetchInterval: 1000,
        retry: false,
        onError: (error) => {
            if (error?.response?.status === 410) {
                setIsTooLate(true)
            }
            if (error?.response?.status === 404 && !isConnectLoading) {
                history.push(`/prenotazione/spiaggia/${activitySlug}/date`)
            }
            throw error;
        }
    });

    const { data: stripeIntent, isLoading: isIntentLoading, isFetching: isIntentFetching } = useQuery(["stripe", "intent", (paymentId || reservationId || groupId), paymentType],
        () => getStripeClientSecret({
            data: {
                paymentType,
                paymentId,
                reservationId,
                groupId,
                activityId,
                activityType: "beaches",
            },
        }),
        {
            enabled: (!!paymentId || !!reservationId || !!groupId) && !!activityId && price > 0,
            refetchOnWindowFocus: false,
            onError: (error) => {
                if (error?.response?.status === 410) {
                    setIsTooLate(true)
                }
                throw error;
            }
        }
    );

    function onPaymentSuccess() {
        return connect({
            paymentId,
            reservationId,
            reservations: reservationsId,
            beachSlug: activitySlug
        })
    }

    function confirmHandler(paymentType) {
        confirm({
            beachSlug: activitySlug,
            reservationId,
            data: {
                "payment-type": paymentType
            }
        });
    }

    if (reservationToConfirm) {
        return (
            <Fragment>
                <MainContainer flex>
                    <SimpleField
                        label={isFood ? "Importo dell'ordine" : "Importo della prenotazione"}
                        value="Non hai importi aggiuntivi da pagare per questa prenotazione."
                        half
                    />
                    <Column>
                        <ButtonsRow fill>
                            <MainButton text="Conferma la prenotazione" status={status} action={() => confirmHandler()} />
                        </ButtonsRow>
                    </Column>
                </MainContainer>
            </Fragment>
        );
    }

    let columns = 6;
    if (!!paymentValue) {
        columns = 4;
    }

    return (
        <LoadingContainer isLoading={isIntentLoading || isIntentFetching}>
            {!onlyPayLocal &&
                <Fragment>
                    {stripeIntent?.userFee > 0 &&
                        <MainContainer flex>
                            <ColumnContainer noMargin>
                                <Column columns={columns}>
                                    <SimpleField
                                        label={isFood ? "Importo dell'ordine" : "Importo della prenotazione"}
                                        value={price}
                                        price
                                    />
                                </Column>

                                {!!paymentValue &&
                                    <Column columns={columns}>
                                        <SimpleField
                                            label="Importo della caparra"
                                            value={paymentValue}
                                            price
                                        />
                                    </Column>
                                }
                                <Column columns={columns}>
                                    <SimpleField
                                        label="Costo del servizio"
                                        value={(stripeIntent?.userFee / 100)}
                                        price
                                    />
                                </Column>

                                {coupon > 0 &&
                                    <Column columns={columns}>
                                        <SimpleField
                                            label="Sconto applicato"
                                            value={`${coupon?.toFixed(2)} €`}
                                            half
                                        />
                                    </Column>
                                }
                            </ColumnContainer>
                        </MainContainer>
                    }

                    <ColumnContainer lowMargin>
                        <CardPayment
                            stripePromise={stripePromise}
                            clientSecret={stripeIntent?.clientSecret}
                            onSuccess={onPaymentSuccess}
                            checkReservation={checkReservationHandler}
                            description={`Totale da pagare ${(stripeIntent?.amount / 100).toFixed(2)}€`}
                            isIntentLoading={isIntentLoading || isConnectLoading}
                            returnUrl={`${window.location.origin}${returnUrlPath}`}
                        />
                    </ColumnContainer>
                </Fragment>
            }

            {isFood && canPayLocal &&
                <Fragment>
                    {!onlyPayLocal &&
                        <ColumnContainer lowMargin>
                            <Column>
                                <Paragraph text="oppure" center />
                            </Column>
                        </ColumnContainer>}
                    <ColumnContainer>
                        <Column>
                            <ButtonsRow fill>
                                <MainButton fullWidth text={onlyPayLocal ? "Conferma prenotazione" : "Paga in contanti"} border status={status} action={() => confirmHandler("cash")} />
                                {!onlyPayLocal && <MainButton text="Paga con carta" border status={status} action={() => confirmHandler("card")} />}
                            </ButtonsRow>
                        </Column>
                    </ColumnContainer>
                </Fragment>
            }

            <PopupError visible={errorPopup} setVisible={setErrorPopup} message={errorPopup?.length > 0 && errorPopup} />

        </LoadingContainer>
    );
}