export function getTitle(text) {
    return `Prenota il tuo ombrellone nelle spiagge ${text}`
}

export function getSubtitle(text) {
    return `Prenota il tuo ombrellone nelle spiagge ${text}. Sdraio o lettino, stabilimento balneare o spiaggia libera?`
}

export function getDescription(text) {
    return `Cosa scegliere: sdraio o lettino? Stabilimento balneare o spiaggia libera? L'importante è avere un posto riservato! Prenota subito il tuo ombrellone nelle spiagge ${text}. Basta code e stressanti attese sotto il sole ma solo pace e relax. Sdraio, lettino e tutto ciò che ti serve è già in stabilimento ad attenderti. Se preferisci puoi vivere il tuo meritato riposo anche nella pace di una spiaggia libera. Non ti dovrai più preoccupare di nulla e potrai dedicarti unicamente a divertirti e rilassarti. Dai un occhiata qui di seguito e scopri qual è il luogo più adatto alle tue esigenze; a tutto il resto ci pensa Beacharound!`
}

export function getMetaDescription(text) {
    return `Prenota il tuo ombrellone nelle spiagge ${text}. Scegli tra sdraio o lettino, stabilimento balneare o spiaggia libera ed al tuo posto pensiamo noi`
}