import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { MainContainer, StepsHeader, BookingBar, ColumnContainer, Column, SimpleField, LoadingContainer, TitleContainer, SectionTitle, DatesField } from '@beacharound/beacharound-ui';
import { trackPurchase, formatDate } from '@beacharound/beacharound-ui-helpers';


import { getOffer } from '../../../../api/hotels';
import { connectAndSendReservation } from '../../../../api/reservations/offers';
import { getPublicReservation } from '../../../../api/reservations/shared';
import { getUser } from '../../../../api/user';

import SignUserComponent from '../../../../components/User/SignUserComponent';

export default function BookingOfferSummary({ match, history }) {

    const { offerSlug, reservationId } = match.params;

    const { data: user, isLoading: isLoadingUser } = useQuery("user", getUser);
    
    const { data: offer } = useQuery(["offers", offerSlug], () =>
        getOffer({ slug: offerSlug })
    );
    const { data: reservation, isLoading, error } = useQuery(["reservations", reservationId], () =>
        getPublicReservation({ reservationId })
    );

    const {mutate: send, status } = useMutation(connectAndSendReservation, {
        onSuccess: (data) => {

            trackPurchase({
                _id: offer?._id,
                name: offer?.name,
                type: "offer",
                activity: offer?.activity?.name,
                value: data?.price
            });

            return history.push(`/profilo/prenotazioni/${reservationId}`);
        }
    });

    async function completeBooking() {
        try {

            await send({
                reservationId,
            });

        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    let title = offer?.name;
    let room = reservation?.additions?.find(
        (addition) => addition.productType === "rooms"
    );

    if (room?.product) {
        title = `${title} - ${room?.product?.name}`;
    }

    if (!reservationId || error || (reservation && reservation.status !== "CHECKINGOUT")) {
        return <Redirect to={`/prenotazione/offerta/${offerSlug}/date`}/>
    }

    return (
        <Fragment>
            {offer && (
                <StepsHeader
                    title={title}
                    stepsNum={4}
                    activeIndex={3}
                    date={`${formatDate(new Date(reservation?.checkin), "dd MMM")} - ${formatDate(new Date(reservation?.checkout), "dd MMM")}`}
                    subtitle="Riepilogo della richiesta di disponibilità"
                    stepsLink={[
                        "date",
                        "camera",
                        "persone",
                        "riepilogo",
                    ]}
                    linkComponent={Link}
                    marginBottom
                />
            )}

            <LoadingContainer isLoading={isLoading || isLoadingUser}>

                <MainContainer marginTop>
                    <TitleContainer>
                        <SectionTitle text="Riepilogo"/>
                    </TitleContainer>

                    {!user ?
                        <SignUserComponent
                            match={match}
                        />
                        :
                        <Fragment>
                            <ColumnContainer lowMargin>
                                <Column>
                                    <DatesField
                                        checkin={reservation?.checkin && {date: formatDate(new Date(reservation.checkin), "dd MMMM yyyy")}}
                                        checkout={reservation?.checkout && {date: formatDate(new Date(reservation.checkout), "dd MMMM yyyy")}}
                                    />

                                    {reservation?.price > 0 &&
                                        <SimpleField
                                            label="Prezzo"
                                            value={`${Number(reservation?.price)?.toFixed(2)} €`}
                                            half
                                        />
                                    }
                                    
                                    <SimpleField
                                        label="Offerta"
                                        value={reservation?.products?.[0]?.product?.name}
                                    />

                                    {reservation?.details?.map((detail) => {
                                        let label;
                                        switch (detail.key) {
                                            case "adults":
                                                label = "Adulti";
                                                break;
                                            case "children":
                                                label = "Bambini";
                                                break;
                                            case "babies":
                                                label = "Neonati";
                                                break;
                                            default:
                                                label = "Persone";
                                        }
                                        return (
                                            <SimpleField
                                                key={label}
                                                label={label}
                                                value={detail.value}
                                                half
                                            />
                                        );
                                    })}

                                    {reservation?.additions?.length > 0 && (
                                        <SimpleField
                                            label="Camera"
                                            value={reservation?.additions?.map(
                                                (data) => data?.product?.name
                                            )}
                                        />
                                    )}
                                </Column>
                            </ColumnContainer>

                            <BookingBar
                                text="Conferma la richiesta senza impegno. Verrai contattato dalla struttura per disponibilità, prezzi e pagamento."
                                action={completeBooking}
                                status={status}
                                buttonText="Invia"
                            />
                        </Fragment>
                    }
                </MainContainer>
            </LoadingContainer>
        </Fragment>
    );
}