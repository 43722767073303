import beachesCategoriesRoutes from "./beaches/beachesCategories";
import beachesDisableRoutes from "./beaches/beachesDisable";
import beachesServicesRoutes from "./beaches/beachesServices";

const seoRoutes = [
    ...beachesCategoriesRoutes,
    ...beachesServicesRoutes,
    ...beachesDisableRoutes
]

export default seoRoutes;