
export function cleanQueryPlacedProductsReservation(queryProducts, {date, seasonal, seasonals, cabin, placedProductsToIgnore} = {}) {
    return queryProducts?.map(queryProduct => {

        const placedProductsInfo = getPlacedProductInfo(queryProduct, {checkin: true, seasonal, seasonals, cabin, placedProductsToIgnore});

        return {
            ...placedProductsInfo
        };
    }) || []
}

export function getPlacedProductInfo(placedProduct, options) {
    if(!placedProduct) {
        return {};
    }

    const productType = placedProduct.gridType === "furniture" ? placedProduct.relatedProduct : placedProduct.relatedProduct?.type;

    let inactive = !placedProduct?.bookable;

    if(options?.seasonal) {
        inactive = !placedProduct?.bookable || !placedProduct?.seasonal?.hasProduct || !placedProduct?.seasonal?.hasSeasonal;
    }

    if(!inactive && !!options?.seasonals?.length) {
        inactive = !placedProduct?.bookable || !placedProduct?.seasonals?.length;
    }

    if(options?.cabin) {
        inactive = !placedProduct?.bookable || (placedProduct?.relatedProduct?.type?.type !== 'cabin');
    } else {
        inactive = !placedProduct?.bookable || (placedProduct?.relatedProduct?.type?.type === 'cabin');
    }

    if(!inactive && !!options?.placedProductsToIgnore && Array.isArray(options?.placedProductsToIgnore)) {
        inactive = options?.placedProductsToIgnore.includes(placedProduct._id);
    }

    return {
        _id: placedProduct._id,
        row: placedProduct.row,
        index: placedProduct.index,
        number: placedProduct.number,
        customNumber: placedProduct.customNumber,
        column: placedProduct.column,
        gridType: placedProduct.gridType,
        productType: productType?.type,
        color: productType?.color,
        category: productType?.category,
        relatedProduct: placedProduct?.relatedProduct?._id,
        relatedProductType: placedProduct.gridType === "furniture" ? "productsTypes" : "products",
        relatedProductName: placedProduct?.relatedProduct?.name,
        relatedProductPrefix: placedProduct?.relatedProduct?.prefix,
        isHotelReserved: placedProduct?.isHotelReserved,
        inactive,
        transform: placedProduct.transform,
        seasonals: !!options?.seasonals?.length && options?.seasonals?.filter(seasonal => seasonal?.mainItem?._id === placedProduct?.relatedProduct?._id),
        placed: true,
    }
}
