import { lazy } from "react";
import MainLayout from "../layouts/MainLayout/MainLayout";
import NationHotel from "../pages/Places/NationHotel";

// import PlaceList from "../pages/Places/PlaceList";
// import PlaceDetail from "../pages/Places/PlaceDetail";
// import PlaceBeaches from "../pages/Places/PlaceBeaches";
// import PlaceHotels from "../pages/Places/PlaceHotels";
// import PlaceExperience from "../pages/Places/PlaceExperiences";
// import PlaceOffers from "../pages/Places/PlaceOffers";

const PlaceList = lazy(() => import("../pages/Places/PlaceList"));
const PlaceDetail = lazy(() => import("../pages/Places/PlaceDetail"));
const PlaceBeaches = lazy(() => import("../pages/Places/PlaceBeaches"));
const PlaceHotels = lazy(() => import("../pages/Places/PlaceHotels"));
const PlaceExperience = lazy(() => import("../pages/Places/PlaceExperiences"));
const PlaceOffers = lazy(() => import("../pages/Places/PlaceOffers"));
const Nation = lazy(() => import("../pages/Places/Nation"));
const Region = lazy(() => import("../pages/Places/Region"));
const District = lazy(() => import("../pages/Places/District"));

const generalRoutes = [
    {
        path: "/luoghi",
        component: PlaceList,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        exact: true,
        analytics: {
            name: "Luoghi",
        },
    },
    {
        path: "/luoghi/:placeSlug",
        component: PlaceDetail,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/luoghi/:placeSlug/spiagge",
        component: PlaceBeaches,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/luoghi/:placeSlug/spiagge-libere",
        component: PlaceBeaches,
        layout: MainLayout,
        type: "public",
        exact: true,
    },
    {
        path: "/luoghi/:placeSlug/hotel",
        component: PlaceHotels,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/luoghi/:placeSlug/esperienze",
        component: PlaceExperience,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/luoghi/:placeSlug/offerte",
        component: PlaceOffers,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/le-migliori-spiagge-italiane",
        component: Nation,
        layoutOptions: { noPadding: true },
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/le-migliori-spiagge-in-provincia-di-:districtSlug",
        component: District,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/le-migliori-spiagge-in-:regionSlug",
        component: Region,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/i-migliori-hotel-in-provincia-di-:districtSlug",
        component: District,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/i-migliori-hotel-in-:regionSlug",
        component: Region,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/i-migliori-hotel-italiani",
        component: NationHotel,
        layoutOptions: { noPadding: true },
        layout: MainLayout,
        exact: true,
    },
    
    // {
    //     path: "/le-migliori-spiagge-a-:districtSlug",
    //     component: PlaceOffers,
    //     layout: MainLayout,
    //     exact: true,
    // },
];

export default generalRoutes