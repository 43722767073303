import React, { useState, Fragment } from 'react';
import { useLocation } from 'react-router';
import qs from 'query-string';

import { MainContainer, BookingBar, Divider, ColumnContainer, Column, Tile, TitleContainer,
    // SectionTitle, MainForm, TextInput, MainFormContent,
    NormalPopup, Paragraph, ButtonsRow, MainButton } from '@beacharound/beacharound-ui';

export default function BookingBeachPublicPeople({match, step, history}) {

    const [ selectedPeople, setSelectedPeople ] = useState()
    // const [ peopleData, setPeopleData ] = useState()
    const [ errorPopup, setErrorPopup] = useState()
    const { search } = useLocation();
    const parsedSearch = qs.parse(search);

    async function nextStep() {

        if (!selectedPeople) {
            return setErrorPopup(true)
        }

        let newSearch = {
            ...parsedSearch,
            people: selectedPeople,
            // peopleData: peopleData && peopleData !== {} ? peopleData : undefined
        }

        return history.push(`${match.url.replace("persone", "riepilogo")}?${qs.stringify(newSearch)}`)
    }

    return (
        <Fragment>
            <MainContainer marginTop>
                <ColumnContainer lowMargin flex>
                    {Array.from(Array(4).keys()).map((num, index) => {
                        const numText = num + 1
                        return (
                            <Column column={2} key={index}>
                                <Tile
                                    title={`${numText} ${numText > 1 ? "persone" : "persona"}`}
                                    containerAction={() => setSelectedPeople(numText)}
                                    active={selectedPeople === numText} 
                                />
                            </Column>
                        )
                    })}
                </ColumnContainer>

                <Divider/>

                {/* {selectedPeople && 
                    <ColumnContainer>
                        <Column>
                            <TitleContainer>
                                <SectionTitle text="Dati degli accompagnatori"/>
                            </TitleContainer>

                            <MainForm onChangeForm={(data) => setPeopleData(data)}>
                                <MainFormContent flex>
                                    {Array.from(Array(selectedPeople - 1).keys()).map((person, index) => {
                                        return (
                                            <Fragment>
                                                <TextInput
                                                    label={`Nome accompagnatore ${person+1}`}
                                                    name={`firstName${person+1}`}
                                                    half
                                                />
                                                <TextInput
                                                    label={`Cognome accompagnatore ${person+1}`}
                                                    name={`lastName${person+1}`}
                                                    half
                                                />
                                            </Fragment>
                                        )
                                    })}
                                </MainFormContent>
                            </MainForm>
                        </Column>
                    </ColumnContainer>
                } */}

                <BookingBar
                    text={step?.title}
                    action={nextStep}
                    buttonText="CONFERMA"
                    icon="arrow-right"
                />
            </MainContainer>


            {/* ERRORE */}
            <NormalPopup
                visible={errorPopup}
                setVisible={setErrorPopup}
                title="Attenzione"
            >
                <TitleContainer>
                    <Paragraph text="Non hai ancora selezionato il numero di persone"/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill center>
                    <MainButton text="Ok" action={() => setErrorPopup()}/>
                </ButtonsRow>
            </NormalPopup>
        </Fragment>
    )
}