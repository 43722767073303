import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { MainContainer, MainTitle, TitleContainer, ColumnContainer, Column, Divider, Paragraph, SectionTitle, LoadingContainer, CardContainer, ButtonsRow, MainButton, SubTitle, Tile } from '@beacharound/beacharound-ui';
import { formatDate } from '@beacharound/beacharound-ui-helpers';

import { getPublicPayment } from '../../../api/reservations/beaches';
import { getUser } from '../../../api/user';
import { getBeach } from '../../../api/beach';

import { getPlaceholderImage } from '../../../helpers/images/getPlaceholderImage';

import ReservationPaymentComponent from '../../../components/Payment/ReservationPaymentComponent';
import RulesList from '../../../components/Rules/RulesList';


export default function ReservationDetailPayment({ match, history }) {

    const { paymentId } = match.params;

    const { data: user, isLoading: isLoadingUser } = useQuery("user", getUser);

    const { data: payment, isLoading, error: reservationError } = useQuery(["payments", paymentId], () =>
        getPublicPayment({ paymentId }),
        {
            onError: (error) => {
                if (error?.response?.status === 409) {
                    const { customer, subject } = error?.response?.data?.data || {};
                    return history.push(`/prenotazione/${customer._id}/${subject._id}`)
                }
            }
        }
    );

    const { data: beach } = useQuery(["beaches", payment?.activity?.slugCity], () =>
        getBeach({ slug: payment?.activity?.slugCity }),
        {
            enabled: !!payment?.activity?.slugCity
        }
    );

    const isCanceled = reservationError?.response?.status === 410;

    return (

        <LoadingContainer isLoading={isLoading || isLoadingUser}>

            {isCanceled &&
                <MainContainer>
                    <TitleContainer>
                        <MainTitle text="Richiesta di pagamento cancellata" component="h1" />
                        <SectionTitle text={`Ti invitiamo a contattare lo stabilimento per poter procedere con un altro pagamento`} />
                    </TitleContainer>

                    <Divider />

                    <ButtonsRow center>
                        <MainButton text="Torna alla home" component={Link} to="/" />
                    </ButtonsRow>
                </MainContainer>
            }

            {!payment && !isCanceled && user &&
                <MainContainer>
                    <TitleContainer>
                        <MainTitle text="Prenotazione non trovata" component="h1" />
                        <SectionTitle text={`Ci dispiace, la prenotazione che stai cercando non è stata trovata`} />
                    </TitleContainer>

                    <Divider />

                    <ButtonsRow center>
                        <MainButton text="Torna alla home" component={Link} to="/" />
                    </ButtonsRow>
                </MainContainer>
            }

            {payment &&
                <MainContainer>
                    <TitleContainer image={payment?.activity?.cover || getPlaceholderImage('beaches')}>
                        <MainTitle text="Pagamento prenotazione" component="h1" />
                        <SubTitle text={payment?.activity?.name} />
                    </TitleContainer>

                    <Divider />

                    {!user &&
                        <CardContainer>
                            <TitleContainer>
                                <SectionTitle text={`La tua prenotazione a ${payment?.activity?.name} è pronta!`} />
                                <Paragraph text="Non ti resta che accedere per completare il pagamento.<br/> Hai già un account Beacharound o desideri registrarti?" />
                            </TitleContainer>
                            <ButtonsRow fill>
                                <MainButton text="Accedi" component={Link} to={`/user/login?backUrlAfterLogin=${match.url}`} />
                                <MainButton text="Registrati" border component={Link} to={`/user/signup?backUrlAfterLogin=${match.url}`} />
                            </ButtonsRow>
                        </CardContainer>
                    }

                    {user &&
                        <ColumnContainer lowMargin>
                            <Column>
                                <TitleContainer>
                                    <SectionTitle text={payment?.type === "TOTAL" ? "Richiesta di pagamento del totale della prenotazione" : "Richiesta di pagamento di una caparra"} />
                                </TitleContainer>

                                {payment?.amount &&
                                    <ColumnContainer lowMargin>
                                        <Column>
                                            <ReservationPaymentComponent
                                                price={payment?.amount / 100}
                                                paymentId={paymentId}
                                                activityId={payment?.activity?._id}
                                                activitySlug={payment?.activity?.slugCity}
                                                activityName={payment?.activity?.name}
                                            />
                                        </Column>
                                    </ColumnContainer>
                                }

                                {payment?.reservations?.length > 0 &&
                                    <Fragment>
                                        <TitleContainer>
                                            <SectionTitle text="Dettagli della richiesta di pagamento" />
                                        </TitleContainer>
                                        {payment?.reservations?.map(reservation => {
                                            return (
                                                <Tile
                                                    text={`prenotazione da ${formatDate(reservation.checkin, "eeee d MMMM")} a  ${formatDate(reservation.checkout, "eeee d MMMM")}`}
                                                />
                                            )
                                        })}
                                    </Fragment>
                                }

                                {beach?.rules?.length > 0 && (
                                    <MainContainer marginTop>
                                        <Divider />
                                        <RulesList rules={beach.rules} />
                                    </MainContainer>
                                )}
                            </Column>
                        </ColumnContainer>
                    }

                </MainContainer>
            }
        </LoadingContainer>
    );
}
