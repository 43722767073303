import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { ButtonsRow, Column, ColumnContainer, Divider, MainButton, MainContainer, MainForm, MainFormContent, MainTitle, NormalPopup, Paragraph, SectionTitle, TextInput, TitleContainer } from '@beacharound/beacharound-ui';

import { requestUserToken } from "../../api/user";

const constraints = {
    email: { presence: { message: "^Campo obbligatorio" }, email: true }
};

export default function ForgotPassword({ history }) {

    const [confirmPopup, setConfirmPopup] = useState();

    const { mutate: requestToken, status } = useMutation(requestUserToken, {
        onSuccess: () => setConfirmPopup(true)
    });

    async function requestTokenHandler(data) {
        try {
            await requestToken({ data });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <MainContainer small>
            <ColumnContainer>
                <Column>
                    <TitleContainer>
                        <MainTitle text="Recupero password" component="h1" />
                    </TitleContainer>

                    <MainForm
                        onSubmit={requestTokenHandler}
                        constraints={constraints}
                    >
                        <MainFormContent>
                            <TextInput name="email" label="Email" required />
                        </MainFormContent>

                        <Divider />

                        <MainButton
                            fullWidth
                            text="Richiedi modifica password"
                            submit
                            status={status}
                        />
                    </MainForm>
                </Column>
            </ColumnContainer>

            <NormalPopup
                visible={confirmPopup}
                setVisible={setConfirmPopup}
                title="Completato"
            >
                <TitleContainer>
                    <SectionTitle text="Richiesta inviata con successo" />
                    <Paragraph text="Controlla la tua mail, riceverai il link per procedere con il recupero della password. Ti invitiamo a controllare anche la spam." />
                </TitleContainer>
                <ButtonsRow fill>
                    <MainButton
                        text="Ok"
                        action={() => history.push("/user/login")}
                    />
                </ButtonsRow>
            </NormalPopup>
        </MainContainer>
    );
}
