import { lazy } from 'react';
import beachesCategoriesPages from '../../data/pages/beachesCategoriesPages';

import MainLayout from "../../layouts/MainLayout/MainLayout";

const BeachesService = lazy(() => import("../../pages/Beach/BeachesService"));

const beachesCategoriesRoutes = beachesCategoriesPages.map(({slug, title}) => {
    return {
        path: `/${slug}`,
        component: BeachesService,
        layoutOptions: { noPadding: true },
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["seo", "Categorie", title]
        }
    };
})

export default beachesCategoriesRoutes;
