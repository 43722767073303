import React, { Fragment, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { ButtonsRow, CardContainer, CheckboxInput, Divider, MainButton, MainForm, MainFormContent, NormalPopup, Paragraph, SectionTitle, SimpleLink, TextInput, TitleContainer } from '@beacharound/beacharound-ui';
import { trackSignup } from '@beacharound/beacharound-ui-helpers';

import { checks as privacyChecks, title as privacyTitle, condition as privacyCondition } from '../../data/conditions/privacy';
import { checks as termsChecks, title as termsTitle, condition as termsCondition } from '../../data/conditions/terms';
import { createUser } from '../../api/user';

import LoginForm from './LoginForm';

const message = { message: '^Campo obbligatorio' };
let conditionsToSave = [];

let signupConstraints = {
    email: {
        presence: message,
        email: { message: '^Email non valida' }
    },
    password: {
        presence: message,
        length: { minimum: 8 }
    },
    passwordConfirm: {
        presence: message,
        equality: 'password'
    },
    firstName: { presence: message },
    lastName: { presence: message },
    phone: { presence: message },
};

export default function SignUserComponent({ loginCallback, signupCallback, noMargin }) {
    const cache = useQueryClient();

    const [errorPopup, setErrorPopup] = useState();
    const { mutateAsync: createUserHandler, status: signupStatus } = useMutation(createUser, {
        onSuccess: (data) => {
            cache.invalidateQueries('user');
            trackSignup({ user: data });
            if (signupCallback && typeof signupCallback === 'function') {
                signupCallback();
            }
        },
        onError: (error) => {
            if (error?.response?.status === 409) {
                setErrorPopup({ message: "Esiste già un utente con questa mail!" });
            }
            if (error?.response?.data?.statusCode === 409) {
                setErrorPopup({ message: "Esiste già un utente con questa mail!" });
            }
            if (error?.response?.data?.message) {
                setErrorPopup({ message: error?.response?.data?.message });
            }
        }
    });

    if (privacyChecks) {
        signupConstraints = {
            ...signupConstraints,
            ...privacyChecks.reduce((acc, con) => ({ ...acc, [con.name]: { presence: true } }), {})
        }
        conditionsToSave = [
            ...conditionsToSave,
            ...privacyChecks.map(check => ({
                title: privacyTitle,
                content: privacyCondition,
                preview: check.label,
                type: check.name
            }))
        ];
    }
    if (termsChecks) {
        signupConstraints = {
            ...signupConstraints,
            ...termsChecks.reduce((acc, con) => ({ ...acc, [con.name]: { presence: true } }), {})
        }
        conditionsToSave = [
            ...conditionsToSave,
            ...termsChecks.map(check => ({
                title: termsTitle,
                content: termsCondition,
                preview: check.label,
                type: check.name
            }))
        ];
    }

    async function addUser(data) {
        try {
            await createUserHandler({
                email: data.email,
                password: data.password,
                profile: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone
                },
                conditions: conditionsToSave
            });
        } catch (error) {
            throw error;
        }
    }

    return (
        <Fragment>
            <CardContainer noMargin={noMargin}>
                <TitleContainer>
                    <SectionTitle text="Hai già un profilo Beacharound? Accedi ora!" center />
                </TitleContainer>

                <LoginForm
                    loginCallback={() => {
                        cache.invalidateQueries('user');
                        if (loginCallback && typeof loginCallback === "function") {
                            loginCallback();
                        }
                    }}
                />

                <SimpleLink
                    component={Link}
                    to="/user/forgot-password"
                    text="Hai dimenticato la password?"
                />

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Oppure inserisci i tuoi dati" center />
                </TitleContainer>

                <MainForm onSubmit={addUser} constraints={signupConstraints}>
                    <MainFormContent flex>
                        <TextInput
                            name="firstName"
                            label="Nome"
                            half
                        />
                        <TextInput
                            name="lastName"
                            label="Cognome"
                            half
                        />
                        <TextInput
                            name="email"
                            label="La tua email"
                            half
                        />
                        <TextInput
                            name="phone"
                            label="Numero di telefono"
                            half
                        />
                        <TextInput
                            type="password"
                            name="password"
                            autocomplete="new-password"
                            label="Crea una password"
                            half
                        />
                        <TextInput
                            type="password"
                            name="passwordConfirm"
                            autocomplete="new-password"
                            label="Conferma la password"
                            half
                        />
                    </MainFormContent>

                    <MainFormContent>
                        {privacyChecks?.map((check, index) =>
                            <CheckboxInput
                                key={index}
                                name={check.name}
                                label={check.label}
                            />
                        )}
                        {termsChecks?.map((check, index) =>
                            <CheckboxInput
                                key={index}
                                name={check.name}
                                label={check.label}
                            />
                        )}
                    </MainFormContent>

                    <Divider />

                    <MainButton text="Registrati" submit status={signupStatus} fullWidth />
                </MainForm>
            </CardContainer>

            {/* ERRORE */}
            <NormalPopup
                visible={errorPopup}
                setVisible={setErrorPopup}
                title="Errore"
            >
                <TitleContainer>
                    <Paragraph text={errorPopup?.message || "Ops.. si è verificato un errore. Controlla i tuoi dati e riprova."} />
                </TitleContainer>
                <Divider />
                <ButtonsRow fill center>
                    <MainButton text="Ok" action={() => setErrorPopup()} />
                </ButtonsRow>
            </NormalPopup>
        </Fragment>
    );
}