import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { MainContainer, StepsHeader, Calendar, BookingBar } from '@beacharound/beacharound-ui';

import { getHotel } from "../../../../api/hotels";
import { trackAddToCart } from '@beacharound/beacharound-ui-helpers';

export default function BookingHotelDate({match, history}) {

    const { hotelSlug } = match.params;

    const { data: hotel } = useQuery(["hotels", hotelSlug], () =>
        getHotel({ slug: hotelSlug })
    )

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    function nextStep() {
        if (!startDate || !endDate) {
            return console.log('errore')
        }

        trackAddToCart({
            _id: hotel?._id,
            name: "reservation",
            type: "hotel",
            activity: hotel?.name,
        });

        return history.push(`/prenotazione/hotel/${hotelSlug}/camera`)
    }

    return (

        <Fragment>
            <StepsHeader
                title={hotel?.name}
                date=""
                stepsNum={5}
                activeIndex={0}
                subtitle="Scegli una data"
                stepsLink={['date','camera','persone','servizi','pagamento']}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer>
                <Calendar
                    startDate={startDate}
                    onChange={onChange}
                    endDate={endDate}
                    minDate={startDate && endDate ? new Date() : (startDate || new Date())}
                />

                <BookingBar
                    text="Scegli le date per proseguire"
                    action={nextStep}
                    buttonText="AVANTI"
                />
            </MainContainer>
        </Fragment>
    )
}