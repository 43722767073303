import { lazy } from 'react';

import MainLayout from "../layouts/MainLayout/MainLayout";

const BookingRentalsList  = lazy(() => import("../pages/Beach/BookingRentals/BookingRentalsList"));
const BookingRentalsTime  = lazy(() => import("../pages/Beach/BookingRentals/BookingRentalsTime"));
const BookingRentalsConfirm  = lazy(() => import("../pages/Beach/BookingRentals/BookingRentalsConfirm"));
const BookingRentalsPayment  = lazy(() => import("../pages/Beach/BookingRentals/BookingRentalsPayment"));
const BookingRentalsThanks  = lazy(() => import("../pages/Beach/BookingRentals/BookingRentalsThanks"));

const generalRoutes = [
    {
        path: "/prenotazione-servizi/:beachSlug",
        component: BookingRentalsList,
        exact: true,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Rental", "Lista"],
        },
    },
    {
        path: "/prenotazione-servizi/:beachSlug/:reservationId/orario",
        component: BookingRentalsTime,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Rental", "Orario"],
        },
    },
    {
        path: "/prenotazione-servizi/:beachSlug/:reservationId/conferma",
        component: BookingRentalsConfirm,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Rental", "Conferma"],
        },
    },
    {
        path: "/prenotazione-servizi/:beachSlug/:reservationId/pagamento",
        component: BookingRentalsPayment,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Rental", "Pagamento"],
        },
    },
    {
        path: "/prenotazione-servizi/:beachSlug/:reservationId/grazie",
        component: BookingRentalsThanks,
        layout: MainLayout,
        analytics: {
            name: ["Booking", "Rental", "Grazie"],
        },
    }
];

export default generalRoutes