import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { MainContainer, StepsHeader, BookingBar } from '@beacharound/beacharound-ui';

import { getHotel } from '../../../../api/hotels';

export default function BookingHotelPayment({match}) {

    const { hotelSlug } = match.params;
    const { data: hotel } = useQuery(["hotels", hotelSlug], () =>
        getHotel({ slug: hotelSlug })
    )

    function completeBooking() {

    }

    return (

        <Fragment>
            <StepsHeader
                title={hotel?.name}
                stepsNum={5}
                activeIndex={4}
                // date={}
                subtitle="Scegli il metodo di pagamento"
                stepsLink={['date','camera','persone','servizi','pagamento']}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer>

                <BookingBar
                    price={1420}
                    action={completeBooking}
                    buttonText="PAGA ORA"
                />
            </MainContainer>
        </Fragment>
    )
}