const placeholderFolder = `${process.env.REACT_APP_IMAGES_PATH_ROOT}/placeholders`;
const date = new Date();
const number = date.getDay() + 1;

export function getPlaceholderImage(type, element, url) {

    if (url) {
        return `${process.env.REACT_APP_IMAGES_PATH_ROOT}${url}`;
    }

    if (!type || ["beaches", "beach", "city", "cities"].includes(type)) {
        return getBeachImagePlaceholder(element);
    }

    if (["hotels", "hotel"].includes(type)) {
        return getHotelImagePlaceholder(element);
    }

    if (["offers", "offer"].includes(type)) {
        return getHotelOffersImagePlaceholder(element);
    }

    if (["rankings"].includes(type)) {
        return getRankingImagePlaceholder(element);
    }

    if (["accessibility"].includes(type)) {
        return getAccessibilityImagePlaceholder(element);
    }
    
    return getSeoImagePlaceholder(type);
}

function getBeachImagePlaceholder(element) {
    return `${placeholderFolder}/beaches-${element?.sandType || "sand"}.jpg`;
}

function getHotelImagePlaceholder() {
    return `${placeholderFolder}/hotel.jpg`;
}

function getHotelOffersImagePlaceholder() {
    return `${placeholderFolder}/offers.jpg`;
}

function getRankingImagePlaceholder() {
    return `${placeholderFolder}/beach.jpg`;
}

function getAccessibilityImagePlaceholder() {
    return `${placeholderFolder}/accessibility.jpg`;
}

function getSeoImagePlaceholder(type) {

    let string, noNumber;
    let preString = "spiagge-";

    switch (type) {
        case "home":
            string = "home";
            preString = "";
            noNumber = true;
            break;
        case "error":
            string = "error-baby";
            preString = "";
            noNumber = true;
            break;
        case "fun":
            string = "divertimento";
            break;
        case "fitness":
            string = "fitness";
            break;
        case "relax":
            string = "relax";
            break;
        case "baby":
            string = "per-bambini";
            break;
        case "dog":
            string = "per-cani-dog-friendly";
            break;
        case "love":
            string = "per-coppie-love";
            break;
        case "family":
            string = "per-famiglie";
            break;

        default:
            string = "divertimento";
            break;
    }
    
    return noNumber
        ? `${placeholderFolder}/${preString}${string}.jpg`
        : `${placeholderFolder}/${preString}${string}-${number}.jpg`;
}
