import axios from '../_defaults/v2/user';
import anonymous from '../_defaults/v2/anonymous';

export async function getReservations({ activityId, params }) {
    try {
        const response = await axios.get(
            `/reservations/${activityId}`,
            { params }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}

export async function getReservation({ activitySlug, reservationId }) {
    try {
        const response = await axios.get(
            `/reservations/${activitySlug}/${reservationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return {};
        }

        throw error;
    }
}

// export async function updateReservation({ beachSlug, reservationId, data }) {
//     try {
//         const response = await axios.post(`/reservations/${beachSlug}/${reservationId}`, data);

//         if (response?.data) {
//             return response.data;
//         }
//     } catch (error) {
//         if (error?.response?.status === 404) {
//             return undefined;
//         }
//         throw error;
//     }
// }

export async function updateReservationDeliveryTime({ beachSlug, reservationId, data }) {
    try {
        const response = await axios.patch(`/reservations/${beachSlug}/${reservationId}/deliverytime`, data);

        if (response?.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return undefined;
        }
        throw error;
    }
}

export async function cancelReservation({ beachSlug, reservationId }) {
    try {
        const response = await axios.delete(
            `/public-beaches/${beachSlug}/reservations/${reservationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return {};
        }

        throw error;
    }
}

export async function getPublicReservation({ reservationId, customerId }) {
    try {
        const response = await anonymous.get(`/reservations/public/${reservationId}/${customerId}`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}


export async function getPublicPayment({ paymentId, params }) {
    try {
        const response = await anonymous.get(`/beacharound-payments/users/${paymentId}`, {
            params,
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function connectToPublicPayment({ paymentId, params }) {
    try {
        const response = await axios.post(`/beacharound-payments/users/${paymentId}/connect`, {
            params,
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function connectReservation({ beachSlug, reservationId }) {
    try {
        const response = await axios.post(`/reservations/${beachSlug}/${reservationId}/users`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function acceptBooking({ activityId, reservationId }) {
    try {
        const response = await axios.post(`/reservations/${activityId}/quotations/${reservationId}/accept`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function declineBooking({ activityId, reservationId }) {
    try {
        const response = await axios.post(`/reservations/${activityId}/quotations/${reservationId}/decline`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function connectToPublicReservation({ customerId, reservationId }) {
    try {
        const response = await axios.post(`/reservations/public/${customerId}/${reservationId}/connect`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function createReservation({ beachSlug, data }) {
    try {
        const response = await axios.post(
            `/reservations/${beachSlug}`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function createFoodReservation({ beachSlug, data, placedProduct }) {
    try {
        const reservation = await createReservation({ beachSlug, data });
        await addDetailsToReservation({ beachSlug, reservationId: reservation?._id, data: placedProduct });
        return reservation;
    } catch (error) {
        throw error;
    }
}

export async function createPublicReservation({ beachSlug, data }) {
    try {
        const response = await axios.post(
            `/public-beaches/${beachSlug}/reservations`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function createHybridReservation({ beachSlug, data }) {
    try {
        const response = await axios.post(
            `/hybrid-beaches/${beachSlug}/reservations`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function createReservationProduct({ reservationId, data }) {
    try {
        const response = await anonymous.post(
            `/reservations/${reservationId}`,
            { ...data }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error)
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function createReservationAddition({ reservationId, data }) {
    try {
        const response = await anonymous.post(
            `/reservations/beaches/${reservationId}/additions`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return {};
        }

        throw error;
    }
}

export async function deleteReservationAddition({ reservationId, additionId }) {
    try {
        const response = await anonymous.delete(
            `/reservations/beaches/${reservationId}/additions/${additionId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return {};
        }

        throw error;
    }
}

export async function checkReservation({ beachSlug, reservationId }) {
    try {
        const response = await axios.post(
            `/reservations/${beachSlug}/${reservationId}/check`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function confirmReservation({ beachSlug, reservationId, connect }) {
    try {
        const response = await axios.post(
            `/reservations/${beachSlug}/${reservationId}/confirm`
        );

        if (!!connect) {
            await connectReservation({ beachSlug, reservationId });
        }

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function confirmReservationWithLocalPayment({ beachSlug, reservationId, data }) {
    try {

        await addDetailsToReservation({
            beachSlug,
            reservationId,
            data
        });

        const response = await axios.post(
            `/reservations/${beachSlug}/${reservationId}/confirm`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}


export async function addProductToReservation({ beachSlug, reservationId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${beachSlug}/${reservationId}/products`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function addProductAndBundleToReservation({ beachSlug, reservationId, productId, bundleId, productsToRemove }) {
    try {
        if (!!productsToRemove?.length) {
            for (const product of productsToRemove) {
                await removeProductFromReservation({ beachSlug, reservationId, productId: product });
            };
        }
        await addProductToReservation({ beachSlug, reservationId, data: { product: productId } });
        const response = await addProductToReservation({ beachSlug, reservationId, data: { product: bundleId } });
        if (response) {
            return response;
        }
    } catch (error) {
        throw error;
    }
}

export async function setReservationProductQuantity({ activitySlug, reservationId, productId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${activitySlug}/${reservationId}/products/${productId}/quantity`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function removeProductFromReservation({ beachSlug, reservationId, productId }) {
    try {
        const response = await axios.delete(
            `/reservations/${beachSlug}/${reservationId}/products/${productId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function removeProductAndBundleFromReservation({ beachSlug, reservationId, productId, bundleId }) {
    try {
        await removeProductFromReservation({ beachSlug, reservationId, productId });
        const response = await removeProductFromReservation({ beachSlug, reservationId, productId: bundleId });
        if (response) {
            return response;
        }
    } catch (error) {
        throw error;
    }
}

export async function addDetailsToReservation({ beachSlug, reservationId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${beachSlug}/${reservationId}/details`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function removeDetailsToReservation({ beachSlug, reservationId, key }) {
    try {
        const response = await axios.delete(
            `/reservations/${beachSlug}/${reservationId}/details/${key}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getUserReservations({ params }) {
    try {
        const response = await axios.get(
            `/reservations/users`,
            { params }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getUserReservation({ reservationId }) {
    try {
        const response = await axios.get(
            `/reservations/users/${reservationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getReservationSchedule({ beachSlug, reservationId }) {
    try {
        const response = await axios.get(
            `/schedules/${beachSlug}/reservations/${reservationId}`,
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getReservationSchedulePrices({ beachSlug, reservationId }) {
    try {
        const response = await axios.get(
            `/schedules/${beachSlug}/reservations/${reservationId}/prices`,
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function applyCouponToReservation({ beachSlug, reservationId, couponCode }) {
    try {
        const response = await axios.post(
            `/reservations/${beachSlug}/${reservationId}/coupons/${couponCode}`,
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getReservationTickets({ beachSlug, reservationId }) {
    try {
        const response = await axios.get(
            `/reservations/${beachSlug}/${reservationId}/tickets`,
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function updateReservationTicket({ beachSlug, reservationId, ticketId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${beachSlug}/${reservationId}/tickets/${ticketId}`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function updateReservationTickets({ beachSlug, reservationId, tickets }) {
    try {
        const response = await Promise.all(
            tickets.map(async ({ ticket, data }) => await updateReservationTicket({ beachSlug, reservationId, ticketId: ticket, data }))
        );

        if (response) {
            return response;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}