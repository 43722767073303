
import profile from "./profile";

export default [
    ...profile,
].map((route) => {
    return {
        ...route,
        path: `${route.path}`,
        private: true
    };
});
