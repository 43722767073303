import MainLayout from "../layouts/MainLayout/MainLayout";

import BookingStepsPublic from "../pages/Beach/Booking/BookingStepsPublic";
import BookingThanks from "../pages/Beach/Booking/BookingThanks";

import BookingBeachPublicType from "../pages/Beach/Booking/BookingBeachPublicType";
import BookingBeachPublicPeople from "../pages/Beach/Booking/BookingBeachPublicPeople";
import BookingBeachPublicMap from "../pages/Beach/Booking/BookingBeachPublicMap";
import BookingBeachPublicDate from "../pages/Beach/Booking/BookingBeachPublicDate";
import BookingBeachPublicReview from "../pages/Beach/Booking/BookingBeachPublicReview";

const generalRoutes = [{
    path: "/prenotazione/spiaggia-libera/:beachSlug",
    component: BookingStepsPublic,
    layout: MainLayout,
    layoutOptions: { noPadding: true },
    routes: [{
        path: "/prenotazione/spiaggia-libera/:beachSlug/tipologia",
        component: BookingBeachPublicType,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia libera", "Date"]
        }
    },{
        path: "/prenotazione/spiaggia-libera/:beachSlug/data",
        component: BookingBeachPublicDate,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia libera", "Data"]
        }
    },{
        path: "/prenotazione/spiaggia-libera/:beachSlug/posto",
        component: BookingBeachPublicMap,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia libera", "Posto"]
        }
    },{
        path: "/prenotazione/spiaggia-libera/:beachSlug/persone",
        component: BookingBeachPublicPeople,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia libera", "Persone"]
        }
    },{
        path: "/prenotazione/spiaggia-libera/:beachSlug/riepilogo",
        component: BookingBeachPublicReview,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia libera", "Riepilogo"]
        }
    }]
},{
    path: "/prenotazione/spiaggia-libera/:beachSlug/:reservationId",
    component: BookingStepsPublic,
    layout: MainLayout,
    layoutOptions: { noPadding: true },
    routes: [{
        path: "/prenotazione/spiaggia-libera/:beachSlug/:reservationId/persone",
        component: BookingBeachPublicPeople,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia libera", "Persone"]
        }
    }]
},{
    path: "/prenotazione/grazie/:reservationId",
    component: BookingThanks,
    layout: MainLayout,
    analytics: {
        name: ["Booking", "Spiaggia libera", "Grazie"],
    }
}]

export default generalRoutes