import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { MainContainer, StepsHeader, BookingBar, ColumnContainer, Column, SimpleField, LoadingContainer, DatesField } from '@beacharound/beacharound-ui';
import { trackPurchase, formatDate } from '@beacharound/beacharound-ui-helpers';

import { getHotel } from '../../../api/hotels';
import { connectAndSendQuotation, getPublicQuotation } from '../../../api/quotations';
import { getUser } from '../../../api/user';

import SignUserComponent from '../../../components/User/SignUserComponent';

export default function QuotationHotelSummary({match, history}) {

    const { hotelSlug, quotationId } = match.params;

    const { data: user, isLoading: isLoadingUser } = useQuery("user", getUser);

    const { data: hotel } = useQuery(["hotels", hotelSlug], () =>
        getHotel({ slug: hotelSlug })
    )
    const { data: quotation, error, isLoading } = useQuery(["quotations", quotationId], () =>
        getPublicQuotation({ quotationId })
    )

    const {mutate: send, status } = useMutation(connectAndSendQuotation, {
        onSuccess: (data) => {

            trackPurchase({
                _id: hotel?._id,
                name: "quotation",
                type: "hotel",
                activity: hotel?.name,
                price: data?.price,
                transaction: data?._id
            });

            return history.push(`/profilo/preventivi/${quotationId}`);
        }
    });
    
    async function completeBooking() {
        try {
            await send({
                quotationId
            })

        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    if (!quotationId || error || (quotation && quotation.status !== "CHECKINGOUT")) {
        return <Redirect to={`/preventivo/${hotelSlug}/date`} />
    }

    return (
        <Fragment>
            {hotel && (
                <StepsHeader
                    title={`${hotel?.name} - ${quotation?.products?.[0]?.product?.name}`}
                    stepsNum={4}
                    activeIndex={3}
                    date={`${formatDate(new Date(quotation?.checkin), "dd MMMM")} - ${formatDate(new Date(quotation?.checkout), "dd MMMM")}`}
                    subtitle="Riepilogo della richiesta di disponibilità"
                    stepsLink={[
                        "date",
                        "camera",
                        "persone",
                        // "servizi",
                        "riepilogo",
                    ]}
                    linkComponent={Link}
                    marginBottom
                />
            )}


            <LoadingContainer isLoading={isLoading || isLoadingUser}>
                <MainContainer>

                    {!user ?
                        <SignUserComponent
                            match={match}
                        />
                        :
                        <Fragment>
                            <ColumnContainer>
                                <Column>
                                    <DatesField
                                        checkin={quotation?.checkin && {date: formatDate(new Date(quotation.checkin), "dd MMMM yyyy")}}
                                        checkout={quotation?.checkout && {date: formatDate(new Date(quotation.checkout), "dd MMMM yyyy")}}
                                    />
                                    <SimpleField
                                        label="Camera"
                                        value={quotation?.products?.[0]?.product?.name}
                                    />

                                    {quotation?.details?.map((detail, index) => {
                                        let label;
                                        switch (detail.key) {
                                            case "adults":
                                                label = "Adulti";
                                                break;
                                            case "children":
                                                label = "Bambini";
                                                break;
                                            case "babies":
                                                label = "Neonati";
                                                break;
                                            default:
                                                label = "Persone";
                                        }
                                        return (
                                            <SimpleField
                                                key={index}
                                                label={label}
                                                value={detail.value}
                                                half
                                            />
                                        );
                                    })}

                                    {quotation?.additions?.length > 0 && (
                                        <SimpleField
                                            label="Servizi aggiuntivi"
                                            value={quotation?.additions?.map(
                                                (data) => data?.product?.name
                                            )}
                                        />
                                    )}
                                </Column>
                            </ColumnContainer>

                            <BookingBar
                                text="Conferma la richiesta senza impegno. Verrai contattato dalla struttura per disponibilità, prezzi e pagamento."
                                action={completeBooking}
                                status={status}
                                buttonText="Invia richiesta"
                            />
                        </Fragment>
                    }
                </MainContainer>
            </LoadingContainer>
        </Fragment>
    );
}