import React from "react";
import {
    FullImageContainer,
    MainContainer,
    Column,
    ColumnContainer,
    SimpleImage,
    MainTitle,
    Paragraph,
    TitleContainer,
    ButtonsRow,
    MainButton,
} from "@beacharound/beacharound-ui";

import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';

import { getPlaceholderImage } from "../../helpers/images/getPlaceholderImage";

export default function AccessibilityHighlight() {
    return (
        <LazyLoad height={200}>
            <FullImageContainer color="main" lowPadding>
                <MainContainer>
                    <TitleContainer>
                        <MainTitle
                            text="Trova e prenota spiagge attrezzate e accessibili a disabili"
                            color="white"
                        />
                        <Paragraph
                            text="Stabilimenti senza barriere con accesso in sedia a rotelle"
                            color="white"
                        />
                    </TitleContainer>
                    <ColumnContainer noMargin>
                        <Column>
                            <SimpleImage
                                low
                                image={getPlaceholderImage(undefined,undefined,'/placeholders/accessibility.jpg')}
                            />
                            <ButtonsRow>
                                <MainButton
                                    color="white"
                                    text="Scopri le spiagge"
                                    component={Link}
                                    to={`/trova-prenota-spiagge-attrezzate-accessibili-disabili`}
                                />
                            </ButtonsRow>
                        </Column>
                    </ColumnContainer>
                </MainContainer>
            </FullImageContainer>
        </LazyLoad>
    );
}
