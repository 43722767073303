import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { useQuery } from 'react-query';

import { MainContainer, BookingBar, Divider, LoadingContainer, Tile, NormalPopup, TitleContainer, Paragraph, ButtonsRow, MainButton } from '@beacharound/beacharound-ui';

import { getBeach, getBeachProducts } from '../../../api/beach';

export default function BookingBeachPublicType({match, history, step}) {

    const { beachSlug } = match.params;
    const [ selectedType, setSelectedType ] = useState();
    const [ errorPopup, setErrorPopup] = useState()
    const { search } = useLocation();
    const parsedSearch = qs.parse(search);

    const { data: beach } = useQuery(["beaches", beachSlug], () =>
        getBeach({ slug: beachSlug }),
        {enabled: !!beachSlug}
    )
    const { data: availabilities, isLoading } = useQuery(['products', beachSlug, 'public-availabilities'], () =>
        getBeachProducts({ slug: beachSlug, params: {params: {type: "public-availabilities"}}}),
        {enabled: !!beach && !!beachSlug}
    )

    async function nextStep() {
        if(!selectedType) {
            return setErrorPopup(true)
        }

        let newSearch = {
            ...parsedSearch,
            availability: selectedType
        }

        return history.push(`${match.url.replace("tipologia", step.next)}?${qs.stringify(newSearch)}`)
    }

    return (
        <Fragment>
            <MainContainer marginTop>
                <LoadingContainer isLoading={isLoading}>
                    {availabilities?.map((item, index) => {
                        return (
                            <Tile
                                active={selectedType === item._id}
                                key={index}
                                title={`${item?.name}`}
                                containerAction={() => setSelectedType(item?._id)}
                            />
                        )
                    })}
                </LoadingContainer>

                <Divider/>

                <BookingBar
                    text={step?.title}
                    action={nextStep}
                    buttonText="AVANTI"
                    icon="arrow-right"
                />
            </MainContainer>

            {/* ERRORE */}
            <NormalPopup
                visible={errorPopup}
                setVisible={setErrorPopup}
                title="Attenzione"
            >
                <TitleContainer>
                    <Paragraph text="Non hai ancora selezionato una tipologia di ingresso"/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill center>
                    <MainButton text="Ok" action={() => setErrorPopup()}/>
                </ButtonsRow>
            </NormalPopup>

        </Fragment>
    )
}