import React, { useRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import qs from "query-string";

import { MainButton, MainFormContent } from '@beacharound/beacharound-ui';
import { trackLogin } from '@beacharound/beacharound-ui-helpers';
import { decodeUser, loginUser } from "../../api/user";
import { useHistory, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/user';

export default function LoginForm({loginCallback}) {

    const location = useLocation();
    const history = useHistory();
    const {setUser} = useUser();
    const locationQuery = qs.parse(location.search);
    const { backUrlAfterLogin } = locationQuery || {};

    let futureQuery = {
        ...locationQuery,
        backUrlAfterLogin: undefined
    }

    const cache = useQueryClient();
    const { mutate: login, status } = useMutation(loginUser, {
        onSuccess: (data) => {
            cache.invalidateQueries("user");
            trackLogin({user: data});
            setUser(decodeUser());
            if (loginCallback && typeof loginCallback === 'function') {
                return loginCallback()
            }
            return history.push(backUrlAfterLogin ? `${backUrlAfterLogin}?${qs.stringify(futureQuery)}` : `/?${qs.stringify(futureQuery)}`);
        },
    });

    const emailInput = useRef();
    const passwordInput = useRef();

    function loginUserHandler(event) {
        event.preventDefault();
        const email = emailInput.current.value;
        const password = passwordInput.current.value;
        return login({email, password});
    }

    return (
        <form
            className="main-form"
            onSubmit={loginUserHandler}
        >
            <MainFormContent>
                {/* <TextInput
                    name="email"
                    label="Email"
                    required
                /> */}
                {/* <TextInput
                    name="password"
                    label="Password"
                    type="password"
                    required
                /> */}

                <div className='input-main-container'>
                    <label className="input-label" htmlFor="email">
                        Email
                    </label>
                    <input
                        ref={emailInput}
                        id="email"
                        name="email"
                        className="input-field"
                        type="text"
                        placeholder="La tua email"
                        autoComplete
                        required
                    />
                </div>
                <div className='input-main-container'>
                    <label className="input-label" htmlFor="password">
                        Password
                    </label>
                    <input
                        ref={passwordInput}
                        id="password"
                        name="password"
                        className="input-field"
                        type="password"
                        placeholder="La tua password"
                        autoComplete
                        required
                    />
                </div>
                <MainButton
                    fullWidth
                    text="Accedi"
                    submit
                    status={status}
                />
            </MainFormContent>
        </form>
    );
}
