import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { MainContainer, MainForm, MainFormContent, SelectionBoxInput, StepsHeader, BookingBar } from '@beacharound/beacharound-ui';

import { getHotel } from '../../../../api/hotels';

export default function BookingHotelServices({match, history}) {

    const { hotelSlug } = match.params;
    const { data: hotel } = useQuery(["hotels", hotelSlug], () =>
        getHotel({ slug: hotelSlug })
    )

    const [ services, setServices] = useState({});

    const parkingOptions = [{
        title: 'Parcheggio auto',
        value: 'car',
        description: '8€/notte'
    },{
        title: 'Parcheggio moto',
        value: 'motorbike',
        description: '4€/notte'
    }]

    function updateServices(name, value) {
        setServices({...services, [name]: value})
    }

    function nextStep() {

        return history.push(`/prenotazione/hotel/${hotelSlug}/pagamento`)
    }

    return (

        <Fragment>
            <StepsHeader
                title={hotel?.name}
                stepsNum={5}
                activeIndex={3}
                // date={}
                subtitle="Scegli i servizi aggiuntivi"
                stepsLink={['date','camera','persone','servizi','pagamento']}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer>
                <MainForm>
                    <MainFormContent>
                        <SelectionBoxInput
                            options={parkingOptions}
                            name="parking"
                            label="Parcheggio"
                            onChangeInput={updateServices}
                        />
                    </MainFormContent>
                </MainForm>

                <BookingBar
                    price={1420}
                    action={nextStep}
                    buttonText="AVANTI"
                />
            </MainContainer>
        </Fragment>
    )
}