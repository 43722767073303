import React, { Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import qs from "query-string";

import { BookingBar, ButtonsRow, LoadingContainer, MainButton, MainContainer, NormalPopup, Paragraph, TitleContainer, Divider, MainForm, MainFormContent, DateInput, Tile } from '@beacharound/beacharound-ui';
import { MapBuilderGrid, MapConfigurator, MapContainer, MapContext } from '@beacharound/beacharound-map';
import { getProductImage, trackAddToCart, formatDate } from '@beacharound/beacharound-ui-helpers';

import { createReservation, getReservation } from '../../../api/reservations/beaches';
import { addProductToReservation } from '../../../api/reservations/beaches';
import { getBeach, getBeachMap } from '../../../api/beach';
import { cleanQueryPlacedProductsReservation } from '../../../helpers/placedProducts';
import { Redirect } from 'react-router-dom';
import { getProduct, getProducts } from '../../../api/products';
import { getBundleInfoString, getBundleTitle } from '../../../helpers/products';

// const showSponsor = Math.random() < 0.3;

export default function BookingBeachMap({ match, history, step, setIsTooLate }) {

    const { beachSlug, reservationId } = match.params;
    const { search } = useLocation();
    const locationQuery = qs.parse(search);
    const { seasonal, seasonals, cabin, durationType, hybrid, date, externalWebsiteUrl } = locationQuery;

    const [errorPopup, setErrorPopup] = useState()
    const [selectedProduct, setSelectedProduct] = useState()
    const [cabinCheckin, setCabinCheckin] = useState()
    const [cabinDatesPopup, setCabinDatesPopup] = useState()
    const [seasonalAdvice, setSeasonalAdvice] = useState(!!seasonal);
    const [seasonalToChoose, setSeasonalToChoose] = useState();

    const { data: beach, isLoading: isLoadingBeach } = useQuery(["beaches", beachSlug], () =>
        getBeach({ slug: beachSlug })
    )

    const { data: reservation, isLoading: isLoadingReservation } = useQuery(["reservations", reservationId], () =>
        getReservation({ activitySlug: beachSlug, customerId: "anonymous", reservationId }),
        { enabled: !!reservationId && reservationId !== "seasonal" && !!beachSlug }
    )

    const { data: seasonalProduct, isLoading: isLoadingSeasonalProduct } = useQuery(["beaches", beachSlug, "products", "seasonal", seasonal], () =>
        getProduct({ activitySlug: beachSlug, productId: seasonal }),
        {
            enabled: !!seasonal && !!beachSlug,
            refetchOnWindowFocus: false
        }
    );

    const optionsSeasonals = {
        limit: 200,
        params: {
            bundleType: 'seasonal'
        }
    }

    const { data: seasonalBundles, isLoading: isLoadingSeasonalsBundles } = useQuery(["beaches", beachSlug, "products", "seasonal", optionsSeasonals], () =>
        getProducts({activitySlug: beachSlug, ...optionsSeasonals}),
        {
            refetchOnWindowFocus: false,
            enabled: !!beachSlug && !!seasonals
        }
    )

    let options = {
        slug: beachSlug,
        beachMapId: beach?.map,
        params: {
            reservation: reservation?._id,
            seasonal,
            seasonals
        }
    }

    if (hybrid) {
        options = {
            ...options,
            params: {
                fromDate: date,
                toDate: date
            }
        }
    }

    const { data: beachMap, isLoading } = useQuery(["beachMap", options], () =>
        getBeachMap(options),
        { enabled: !!beach && !!(reservation || !!seasonal || !!seasonals || hybrid) }
    )

    const placedProducts = cleanQueryPlacedProductsReservation(beachMap?.placedProducts,
        {
            seasonal: !!seasonal,
            cabin: !!cabin,
            seasonals: !!seasonals && seasonalBundles,
        }
    )

    const cache = useQueryClient();

    const { mutateAsync: addProduct, status: statusAddProduct } = useMutation(addProductToReservation, {
        onSuccess: (data) => {
            cache.invalidateQueries(['reservations', reservationId])
            if (cabin) {
                return history.push(`/prenotazione/spiaggia/${beachSlug}/${reservationId}/servizi${search}`)
            }
            return history.push(`/prenotazione/spiaggia/${beachSlug}/${reservationId}/${step.next}${search}`)
        },
        onError: (error) => {
            if (error?.response?.status === 410) {
                setIsTooLate(true)
            }
        }
    })
    const { mutateAsync: create, status: createStatus } = useMutation(createReservation, {
        onSuccess: (data, variables) => {
            trackAddToCart({
                _id: data?._id,
                name: beach?.name ? `reservations | ${beach?.name}` : "reservation",
                type: "beach",
                activity: beach?.name,
            });
            cache.invalidateQueries(["reservations", data?._id]);

            return history.push(`${match.url.replace('/mappa', '/pagamento').replace('/seasonal', `/${data._id}`).replace(`/${reservationId}`, `/${data._id}`)}${search}`);
        }
    })

    async function nextStep(fromDate, toDate) {

        try {
            if (!selectedProduct) {
                return setErrorPopup(true)
            }
            if (!!seasonals && !selectedProduct?.seasonalProduct) {
                return setErrorPopup(true)
            }

            if (seasonal) {
                return create({
                    beachSlug: beach?.slug,
                    data: {
                        placedProduct: selectedProduct?._id,
                        product: seasonal,
                        from: externalWebsiteUrl && "external"
                    }
                })
            }

            if (seasonals && selectedProduct?.seasonalProduct) {
                return create({
                    beachSlug: beach?.slug,
                    data: {
                        placedProduct: selectedProduct?._id,
                        product: selectedProduct?.seasonalProduct?._id,
                        from: externalWebsiteUrl && "external"
                    }
                })
            }

            if (hybrid) {
                let seasonalQuery = qs.stringify({
                    ...(qs.parse(search)),
                    placedProduct: selectedProduct?._id
                })

                return history.push(`/prenotazione/spiaggia-ibrida/${beachSlug}/persone?${seasonalQuery}`)
            }


            if (cabin) {
                await addProduct({
                    beachSlug,
                    reservationId,
                    data: {
                        product: selectedProduct?.relatedProduct,
                        fromDate: fromDate && formatDate(new Date(fromDate), 'yyyy-MM-dd'),
                        toDate: toDate && formatDate(new Date(toDate), 'yyyy-MM-dd')
                    }
                })
            } else {
                await addProduct({
                    beachSlug,
                    reservationId,
                    data: {
                        placedProduct: selectedProduct?._id,
                        durationType
                    }
                })
            }

        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    // const sponsor = showSponsor ? {
    //     type: 'plane',
    //     image: '/images/ui/sponsor-battistolli.png',
    //     action: () => window.open('https://www.battistolli.it/servizi-integrati/')
    // };

    // {
    //     type: 'plane',
    //     image: '/images/ui/sponsor-unet.png',
    //     action: () => window.open('https://www.unetenergia.it')
    // }

    // const sponsor = {
    //     type: 'kite',
    //     image: '/images/ui/test-sponsor.png',
    //     action: () => console.log('click')
    // }

    function onCellSelectHandler(data) {

        if (cabin) {
            setSelectedProduct(data)
            return setCabinDatesPopup(true)
        }

        if(seasonals) {

            // Se non ci sono prodotti stagionali non facciamo nulla
            if(!data?.seasonals?.length) return

            // Se c'è un solo stagionale, lo seleziono automaticamente
            if(data?.seasonals?.length === 1) {
                return setSelectedProduct({
                    ...data,
                    seasonalProduct: data?.seasonals[0]
                })
            }
            if(data?.seasonals?.length > 1) {
                setSelectedProduct();
                return setSeasonalToChoose(data)
            }
        }

        return setSelectedProduct(data)
    }

    function closeSeasonalToChooseHandler() {
        setSelectedProduct();
        return setSeasonalToChoose();
    }

    function addCabinHandler(data) {
        return nextStep(data.fromDate, data.toDate)
    }

    if (reservation && reservation?.additions?.length > 0 && !cabin) {
        return <Redirect to={`/prenotazione/spiaggia/${beachSlug}/date${search}`} />
    }

    return (
        <Fragment>
            <LoadingContainer isLoading={isLoading || isLoadingBeach || isLoadingReservation || isLoadingSeasonalProduct || isLoadingSeasonalsBundles}>
                <MapContainer marginBottom fullWidth fullHeight low>
                    <MapConfigurator
                        imagesPathRoot={`${process.env.REACT_APP_IMAGES_PATH_ROOT}/products/`}
                        placedProducts={placedProducts}
                        selectedCells={selectedProduct}
                        onCellSelect={(data) => onCellSelectHandler(data)}
                        selectOnClick
                        options={{
                            columns: beachMap?.map?.columns,
                            rows: beachMap?.map?.rows,
                            sort: {
                                vertical: beachMap?.map?.verticalDirection,
                                horizontal: beachMap?.map?.horizontalDirection
                            },
                            alphanumericDirection: beachMap?.map?.alphanumericDirection,
                            filtering: beachMap?.map?.filtering,
                            numbering: beachMap?.map?.numbering,
                            background: beachMap?.map?.background
                        }}
                        scrollToFirst
                        ignoreSort
                        defaultSize="M"
                    >
                        <MapContext.Consumer>
                            {() =>
                                <Fragment>
                                    <MapBuilderGrid noIndex center noMarginTop></MapBuilderGrid>
                                </Fragment>
                            }
                        </MapContext.Consumer>
                    </MapConfigurator>
                </MapContainer>
            </LoadingContainer>

            {!cabin &&
                <MainContainer>
                    <BookingBar
                        text={!selectedProduct?.seasonalProduct?.price && step?.title}
                        price={selectedProduct?.seasonalProduct?.price}
                        action={nextStep}
                        status={(seasonal || seasonals) ? createStatus : statusAddProduct}
                        buttonText="AVANTI"
                        icon="arrow-right"
                        fixed
                    />
                </MainContainer>
            }

            {/* DATE CABINA */}
            <NormalPopup
                visible={cabinDatesPopup}
                setVisible={setCabinDatesPopup}
                title="Date cabina"
                overflow="visible"
            >
                <TitleContainer>
                    <Paragraph text="Seleziona le date in cui vuoi prenotare la cabina" />
                </TitleContainer>

                <MainForm onSubmit={addCabinHandler}>
                    <MainFormContent flex>
                        <DateInput
                            label="Dal"
                            name="fromDate"
                            required
                            half
                            minDate={new Date(reservation?.checkin)}
                            maxDate={new Date(reservation?.checkout)}
                            onChangeInput={(name, value) => setCabinCheckin(value)}
                        />
                        <DateInput
                            label="Fino al"
                            name="toDate"
                            required
                            half
                            minDate={cabinCheckin ? new Date(cabinCheckin) : new Date(reservation?.checkin)}
                            maxDate={new Date(reservation?.checkout)}
                        />
                    </MainFormContent>
                    <Divider />
                    <ButtonsRow fill center>
                        <MainButton text="Annulla" border action={() => setCabinDatesPopup()} />
                        <MainButton text="Aggiungi" submit status={statusAddProduct} />
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>


            {/* ERRORE */}
            <NormalPopup
                visible={errorPopup}
                setVisible={setErrorPopup}
                title="Attenzione"
            >
                <TitleContainer>
                    <Paragraph text="Non hai ancora selezionato un posto valido per la tua prenotazione" />
                </TitleContainer>
                <Divider />
                <ButtonsRow fill center>
                    <MainButton text="Ok" action={() => setErrorPopup()} />
                </ButtonsRow>
            </NormalPopup>

            {/* AVVISO PER GLI STAGIONALI */}
            <NormalPopup
                visible={seasonalAdvice && !isLoadingSeasonalProduct}
                setVisible={setSeasonalAdvice}
                title="Prenotazione stagionale"
            >
                <TitleContainer>
                    <Paragraph text={`Hai selezionato il <strong>pacchetto ${seasonalProduct?.name}</strong>. In mappa vedrai gli ombrelloni disponibili per questo pacchetto stagionale. Se vuoi prenotare un posto diverso ti invitiamo a selezionare un pacchetto stagionale differente.`} />
                </TitleContainer>
                <Divider />
                <ButtonsRow fill center>
                    <MainButton text="Ok" action={() => setSeasonalAdvice()} />
                </ButtonsRow>
            </NormalPopup>

            {/* SCELTA DELLO STAGIONALE */}
            <NormalPopup
                visible={seasonalToChoose}
                setVisible={closeSeasonalToChooseHandler}
                title="Prenotazione stagionale"
            >
                <TitleContainer>
                    <Paragraph text="Sul prodotto selezionato permette di scegliere diversi pacchetti stagionali. Indica qualche pacchetto vuoi prenotare." />
                </TitleContainer>
                <Fragment>
                    {seasonalToChoose?.seasonals?.filter(item => !item.relatedProducts?.some(({reference}) => reference?.type?.type === "cabin")).sort((a, b) => b.price - a.price).map((item) => {

                        const { title, subtitle } = getBundleTitle(item, { main: true });
                        return (
                            <Tile
                                key={item._id}
                                images={item.relatedProducts?.map(prod => getProductImage(prod.reference?.type))}
                                pretitle={getBundleInfoString(item)}
                                title={title}
                                text={subtitle}
                                active={selectedProduct?.seasonalProduct?._id === item._id}
                                containerAction={() => setSelectedProduct({
                                    ...seasonalToChoose,
                                    seasonalProduct: item
                                })}
                            />
                        )
                    })}
                </Fragment>
                <Divider />
                <ButtonsRow fill center>
                    <MainButton text="Annulla" action={() => closeSeasonalToChooseHandler()} border />
                    <MainButton text="Avanti" action={() => nextStep()} status={createStatus} />
                </ButtonsRow>
            </NormalPopup>
        </Fragment>
    )
}