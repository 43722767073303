export const mainSitemaps = [{
    name: "Luoghi",
    slug: "luoghi"
}, {
    name: "Regioni",
    slug: "regioni"
}, {
    name: "Province",
    slug: "province"
}, {
    name: "Spiagge",
    slug: "spiagge"
}, {
    name: "Spiagge libere",
    slug: "spiagge-libere"
}, {
    name: "Hotel",
    slug: "hotel"
}, {
    name: "Servizi",
    slug: "servizi"
}, {
    name: "Categorie",
    slug: "categorie"
}, {
    name: "Accessibilità",
    slug: "accessibilita"
}]
