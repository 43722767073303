import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

import {
    MainContainer,
    MainTitle,
    TitleContainer,
    FullImageContainer,
    ColumnContainer,
    Column,
    Paragraph,
    CardInline,
    MainButton,
    ButtonsRow,
} from "@beacharound/beacharound-ui";

import { getRankings } from '../../api/blog/rankings';
import { useGeolocalization } from "../../contexts/geolocalization";
import { getPlaceholderImage } from "../../helpers/images/getPlaceholderImage";

export default function BeachesRankingsHighlight({coordinates, params, limit, sort, title, subtitle}) {

    const { hasGeolocalization, geoCoordinates } = useGeolocalization();

    let options = {
        limit: limit || 4,
        sort: sort || { updatedAt: "-1" },
        params: {
            ...params,
        },
    };

    if (
        hasGeolocalization &&
        geoCoordinates?.latitude &&
        geoCoordinates?.longitude
    ) {
        options = {
            ...options,
            latitude: hasGeolocalization && geoCoordinates?.latitude,
            longitude: hasGeolocalization && geoCoordinates?.longitude,
        };
    }

    if (coordinates && coordinates.latitude && coordinates.longitude) {
        options = {
            ...options,
            sort: undefined,
            maxDistance: true,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
        };
    }

    const { data } = useQuery(["rankings", options], () =>
        getRankings({params: options}),
        {
            refetchOnWindowFocus: false
        }
    )

    if(!data || data?.length === 0) {
        return <Fragment></Fragment>
    }

    return (
        <FullImageContainer color="main" lowPadding>
            <MainContainer>
                <TitleContainer>
                    <MainTitle text={title || "Le spiagge più belle"} color="white" />
                    <Paragraph
                        text={subtitle || "Non perderti le spiagge più belle e organizza la tua vacanza per visitarle tutte!"}
                        color="white"
                    />
                </TitleContainer>
                <ColumnContainer noMargin>
                    <Column>

                        {data?.map(ranking => {
                            return (
                                <CardInline
                                    key={ranking._id}
                                    image={ranking.cover || getPlaceholderImage("rankings")}
                                    title={ranking.name}
                                    subtitle={ranking.proposedBy?.name ? `${ranking.proposedBy?.name}` : undefined}
                                    component={Link}
                                    to={`/classifiche/${ranking.slug}`}
                                />
                            );
                        })}

                        <ButtonsRow fill>
                            <MainButton
                                color="white"
                                text="Vedi tutte le classifiche"
                                component={Link}
                                to="/classifiche"
                            />
                        </ButtonsRow>
                       
                    </Column>
                </ColumnContainer>
            </MainContainer>
        </FullImageContainer>
    );
}
