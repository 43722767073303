import MainLayout from "../layouts/MainLayout/MainLayout";
import PaymentThanks from "../pages/Beach/Reservations/PaymentThanks";

import ReservationDetailPayment from "../pages/Beach/Reservations/ReservationDetailPayment";

const generalRoutes = [
    {
        path: "/pagamento/:paymentId",
        component: ReservationDetailPayment,
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["Pagamento beacharound", "Pagamento"],
        },
    },
    {
        path: "/pagamento/:paymentId/grazie",
        component: PaymentThanks,
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["Pagamento beacharound", "Grazie"],
        },
    }
];

export default generalRoutes