import axios from '../_defaults/v2/user';
import { addProductToReservation, confirmReservation, connectReservation, createReservation } from "./beaches";

export async function createReseravationsGroup({beachSlug, data}) {
    try {
        const response = await axios.post(
            `/reservations-groups/${beachSlug}`,
            data || {}
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getReservationGroups({beachSlug, reservationId}) {
    try {
        const response = await axios.get(
            `/reservations-groups/${beachSlug}`,
            {params: {reservationId}}
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getReservationsGroup({beachSlug, groupId}) {
    try {
        const response = await axios.get(
            `/reservations-groups/${beachSlug}/${groupId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function addReservationToGroup({beachSlug, groupId, data}) {
    try {
        const response = await axios.post(
            `/reservations-groups/${beachSlug}/${groupId}/reservations`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function removeReservationFromGroup({beachSlug, groupId, reservationId}) {
    try {
        const response = await axios.delete(
            `/reservations-groups/${beachSlug}/${groupId}/reservations/${reservationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function createReservationsWithGroup({beachSlug, checkin, checkout, placedProducts, data}) {
    try {
        const group = await createReseravationsGroup({beachSlug, data});
        await Promise.all(placedProducts.map(async ({_id}) => {
            await createReservation({
                beachSlug,
                data: {
                    group: group._id,
                    checkin,
                    checkout,
                    placedProduct: _id
                }
            })
        }));
        return group;
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function createReservationsAndToGroup({beachSlug, checkin, checkout, placedProducts, durationType, groupId, from}) {
    try {
        const promises = await Promise.all(placedProducts.map(async ({_id}) => {
            const reservation = await createReservation({
                beachSlug,
                data: {
                    group: groupId,
                    checkin,
                    checkout,
                    durationType,
                    from
                }
            })
            await addProductToReservation({
                beachSlug,
                reservationId: reservation._id,
                data: {
                    placedProduct: _id
                }
            })
        }));
        return promises;
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function confirmMultipleReservations({beachSlug, reservations}) {
    try {
        const result = await Promise.all(reservations.map(async ({_id}) => {
            await confirmReservation({
                beachSlug,
                reservationId: _id
            })
        }));
        return result;
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function connectGroupReservations({beachSlug, reservations}) {
    try {
        const result = await Promise.all(reservations.map(async (_id) => {
            await connectReservation({
                beachSlug,
                reservationId: _id
            })
        }));
        return result;
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}