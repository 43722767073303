import axios from "./_defaults/v2/user";

export async function getStripeClientSecret({ data }) {

    try {
        const response = await axios.post("/stripe/intent", data);

        if (response.data) {
            return response.data;
        }

        return response;
    } catch(error) {
        console.log(error);
        throw error;
    }
}

export async function createTransaction({data, reservationId}) {
    try {
        const response = await axios.post(`/transactions/${reservationId}`, data)
        return response?.data
    } catch(error) {
        console.log(error.response)
        throw error;
    }
}