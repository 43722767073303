import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { addDays, differenceInDays, getDay, isAfter, isBefore, isPast } from "date-fns";

import {
    MainContainer,
    StepsHeader,
    Calendar,
    BookingBar,
    LoadingContainer
} from "@beacharound/beacharound-ui";
import {
    formatDate,
    getDayOfTheWeekName,
    getDayOfTheWeekNumber,
    trackAddToCart,
} from "@beacharound/beacharound-ui-helpers";


import { createReservation, updateReservation } from "../../../../api/reservations/offers";
import { getOffer } from "../../../../api/hotels";

export default function QuotationHotelDate({ match, history }) {
    
    const { offerSlug, reservationId } = match.params;

    const { data: offer, isLoading: isLoadingOffer } = useQuery(["offers", offerSlug], () =>
        getOffer({ slug: offerSlug })
    );

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [bookingErrorText, setBookingErrorText] = useState();

    let bookingText = "Scegli le date";

    const {mutate: create, status, error, reset } = useMutation(createReservation, {
        onSuccess: (data) => {
            trackAddToCart({
                _id: offer?.name,
                name: "offer",
                type: "offer",
                activity: offer?.activity?.name,
            });
            
            return history.push(`/prenotazione/offerta/${offerSlug}/${data?._id}/camera`)
        }
    });
    const {mutate: update, status: statusUpdate, error: errorUpdate, reset: resetUpdate } = useMutation(updateReservation);

    const onChange = (dates) => {

        const [start, end] = dates;

        reset();
        resetUpdate();
        setBookingErrorText();

        setStartDate(start);
        setEndDate(end);
    };

    const durationCondition = offer?.conditions?.find(
        (condition) => condition.type === "duration"
    );

    const checkinDayCondition = offer?.conditions?.find(
        (condition) => condition.type === "checkinDay"
    );

    async function nextStep() {
        try {

            if (!startDate || !endDate) {
                return setBookingErrorText("Non hai ancora inserito le date");
            }

            if (isAfter(startDate, endDate)) {
                return setBookingErrorText("La data di checkout non può essere prima di quella di checkin");
            }

            if (durationCondition) {
                const reservationDuration = differenceInDays(endDate, startDate);
                if (durationCondition.min && reservationDuration < durationCondition.min) {
                    return setBookingErrorText(
                        `L'offerta è valida solo per prenotazioni di almeno ${durationCondition.min} notti.`
                    );
                }

                if (durationCondition.max && reservationDuration > durationCondition.max) {
                    return setBookingErrorText(
                        `L'offerta è valida solo per prenotazioni di massimo ${durationCondition.max} notti.`
                    );
                }
            }

            if (checkinDayCondition) {
                const reservationCheckinDay = getDay(startDate);
                const conditionDay = getDayOfTheWeekNumber(
                    checkinDayCondition.checkinDay
                );

                if (reservationCheckinDay !== conditionDay) {
                    return setBookingErrorText(
                        `L'offerta è valida solo per prenotazioni con checkin di ${getDayOfTheWeekName(checkinDayCondition.checkinDay)}.`
                    );
                }
            }

            if (reservationId) {
                await update({
                    reservationId,
                    data: {
                        checkin: formatDate(startDate, "yyyy-MM-dd"),
                        checkout: formatDate(endDate, "yyyy-MM-dd"),
                        offerSlug,
                        hotelSlug: offer?.activity?.slug,
                    },
                });
            } else {
                await create({
                    data: {
                        checkin: formatDate(startDate, "yyyy-MM-dd"),
                        checkout: formatDate(endDate, "yyyy-MM-dd"),
                        offerSlug,
                        hotelSlug: offer?.activity?.slug,
                    },
                });
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    // Troviamo quali sono le date minime e massime per il calendario
    let minDate;
    let maxDate;
    let filterDate;

    if(offer?.availability?.fromDate && offer?.availability?.toDate) {
        minDate = offer?.availability?.fromDate && new Date(offer?.availability?.fromDate);
        maxDate = offer?.availability?.toDate && new Date(offer?.availability?.toDate);
    }

    if (!minDate || isPast(minDate)) {
        minDate = new Date();
    }

    if(durationCondition || checkinDayCondition) {
        bookingText = "Questa offerta è valida"
    }

    if(durationCondition) {
        bookingText = `${bookingText} per pernottamenti di`;

        if(durationCondition.min) {
            bookingText = `${bookingText} almeno ${durationCondition.min} notti`
        }

        if(durationCondition.min && durationCondition.max) {
            bookingText = `${bookingText} e`
        }

        if(durationCondition.max) {
            bookingText = `${bookingText} massimo ${durationCondition.max} notti`;
        }

        if(startDate) {
            filterDate = date => {
                const minEndDate = addDays(startDate, durationCondition.min || 0);
                const maxEndDate =
                    durationCondition.max &&
                    addDays(startDate, durationCondition.max);

                if (minEndDate && maxEndDate) {
                    return (
                        isAfter(date, addDays(minEndDate, -1)) && isBefore(date, addDays(maxEndDate, 1))
                    );
                }

                if (maxEndDate) {
                    return isBefore(date, addDays(maxEndDate, 1));
                }
                
                return isAfter(date, addDays(minEndDate, -1));
            }
        }
    }

    bookingText = bookingText += ".";

    if(checkinDayCondition) {
        bookingText = `${bookingText} Il checkin va effettuato di ${getDayOfTheWeekName(checkinDayCondition.checkinDay)}.`;

        if(!startDate || endDate) {
            filterDate = date => {
                const day = getDay(date);
                const conditionDay = getDayOfTheWeekNumber(
                    checkinDayCondition.checkinDay
                );
                return day === conditionDay;
            }
        }
    }

    return (
        <Fragment>
            <StepsHeader
                title={offer?.name}
                date=""
                stepsNum={4}
                activeIndex={0}
                subtitle="Scegli per quale periodo vuoi richiedere l'offerta"
                stepsLink={[
                    "date",
                    "camera",
                    "persone",
                    "riepilogo",
                ]}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer marginTop>
                <LoadingContainer isLoading={isLoadingOffer}>
                    <Calendar
                        startDate={startDate}
                        onChange={onChange}
                        endDate={endDate}
                        minDate={startDate && endDate ? minDate : (startDate || minDate)}
                        maxDate={maxDate}
                        filterDate={filterDate}
                    />

                    <BookingBar
                        text={
                            bookingErrorText ||
                            error?.response?.data?.message ||
                            errorUpdate?.response?.data?.message ||
                            bookingText
                        }
                        action={nextStep}
                        status={status || statusUpdate}
                        buttonText="AVANTI"
                    />
                </LoadingContainer>
            </MainContainer>
        </Fragment>
    );
}
