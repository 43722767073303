import bookingHotel from "./bookingHotel";
import bookingBeach from "./bookingBeach";
import bookingFood from "./bookingFood";
import bookingEvents from "./bookingEvents";
import bookingRentals from "./bookingRentals";
import bookingBeachPublic from "./bookingBeachPublic";
import bookingBeachHybrid from "./bookingBeachHybrid";
import quotationHotel from "./quotationHotel";
import user from './user';
import general from "./general";
import places from "./places";
import beach from './beach';
import hotel from './hotel';
import experience from './experience';
import blog from './blog';
import payments from './payments';
import rankings from './rankings';
import seo from './seo';
import sitemaps from './sitemaps';

export default [
    ...bookingHotel,
    ...bookingBeach,
    ...bookingFood,
    ...bookingEvents,
    ...bookingRentals,
    ...bookingBeachPublic,
    ...bookingBeachHybrid,
    ...quotationHotel,
    ...user,
    ...general,
    ...places,
    ...beach,
    ...hotel,
    ...experience,
    ...blog,
    ...payments,
    ...rankings,
    ...sitemaps,
    ...seo,
].map((route) => {
    return {
        ...route,
        path: `${route.path}`
    };
});
