import axios from '../_defaults/v2/user';

export async function getPlacesAll({ params }) {
    try {
        const response = await axios.get(`/search/places`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response.status === 404) return [];
        console.log(error.response);
        throw error
    }
}
