const noMapBookingSteps = [
    {key: 'date', title: 'Scegli le date'},
    {key: 'ombrellone', title: 'Scegli un ombrellone'},
    {key: 'servizi', title: 'Scegli il parcheggio o i servizi'},
    {key: 'pagamento', title: 'Conferma e paga la prenotazione'}
];

const publicBeachSteps = [
    {key: 'tipologia', title: 'Scegli il tipo di ingresso'},
    {key: 'data', title: 'Scegli la data'},
    {key: 'posto', title: 'Scegli il tuo posto'},
    {key: 'persone', title: 'Quante persone ci saranno?'},
    {key: 'riepilogo', title: 'Riepilogo accesso'},
];

const publicBeachHybrid = [
    {key: 'data', title: 'Scegli la data'},
    {key: 'posto', title: 'Scegli il tuo posto'},
    {key: 'persone', title: 'Quante persone ci saranno?'},
    {key: 'riepilogo', title: 'Riepilogo accesso'},
];

let mapBookingSteps = [
    {key: 'date', title: 'Scegli le date'},
    {key: 'mappa', title: 'Scegli il tuo posto'},
    // {key: 'seasonal', title: 'Scegli il prodotto'},
    {key: 'lettini', title: 'Scegli i lettini'},
    {key: 'servizi', title: 'Aggiungi servizi, parcheggi o cabine'},
    {key: 'pagamento', title: 'Conferma e paga la prenotazione'}
]

let groupBookingSteps = [
    {key: 'mappa', title: 'Scegli le postazioni da aggiungere'},
    {key: 'lettini', title: 'Scegli i lettini per ogni postazione'},
    {key: 'pagamento', title: 'Conferma e paga la prenotazione'}
]

export function getBookingSteps({hasMap, isOnlyBooking, services, seasonal, onlySeasonal, isPublic, animals, cabins, isHybrid, isGroup}) {

    let stepLinks = [];

    if(hasMap) {
        stepLinks = mapBookingSteps;
    }

    if(isOnlyBooking) {
        stepLinks = noMapBookingSteps;
    }

    if ((!services || services.length <= 0) && !cabins) {
        stepLinks = stepLinks.filter(({key}) => key !== 'servizi');
    }

    if (seasonal) {
        stepLinks = stepLinks.filter(({key}) => !["lettini", "servizi"].includes(key));
    } else {
        stepLinks = stepLinks.filter(({key}) => key !== 'seasonal');
    }

    if (onlySeasonal) {
        stepLinks = stepLinks.filter(({key}) => key !== 'date');
    }

    if (isPublic) {
        stepLinks = publicBeachSteps
    }

    if (isHybrid) {
        stepLinks = publicBeachHybrid
    }

    if (isGroup) {
        stepLinks = groupBookingSteps
    }

    stepLinks = stepLinks.map((step, index) => ({
        ...step,
        next: stepLinks[index + 1]?.key
    }))

    return stepLinks;
}