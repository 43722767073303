import { lazy } from 'react';
import beachesDisables from '../../data/pages/beachesDisables';

import MainLayout from "../../layouts/MainLayout/MainLayout";

const BeachesService = lazy(() => import("../../pages/Beach/BeachesService"));
const BeachesServiceDetail = lazy(() => import("../../pages/Beach/BeachesServiceDetail"));

const beachesDisableRoutes = [...beachesDisables.map(({ slug, title }) => {
    return {
        path: `/${slug}`,
        component: BeachesService,
        layoutOptions: { noPadding: true },
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["seo", "Disabili", title]
        }
    };
}), {
    path: "/:serviceSeoSlug",
    component: BeachesServiceDetail,
    layoutOptions: { noPadding: true },
    layout: MainLayout,
    exact: true
}];

export default beachesDisableRoutes;
