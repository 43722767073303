import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { MainContainer, StepsHeader, RoomTile, BookingBar, LoadingContainer } from '@beacharound/beacharound-ui';
import { getRoomsForTile } from '../../../helpers/hotels/rooms';

import { getHotel, getHotelRooms } from '../../../api/hotels';
import { getPublicQuotation, updateQuotation } from '../../../api/quotations';
import { formatDate } from '@beacharound/beacharound-ui-helpers';

export default function QuotationHotelRoom({match, history}) {

    const { hotelSlug, quotationId } = match.params;
    const { data: hotel, isLoading: isLoadingHotel } = useQuery(["hotels", hotelSlug], () =>
        getHotel({ slug: hotelSlug })
    )

    const [activeRoom, setActiveRoom] = useState();
    const [bookingText, setBookingText] = useState('Scegli la camera');
    
    const options = {
        sort: { createdAt: "-1" },
        hotel: hotelSlug,
        params: {
            slug: { $exists: true },
        }
    }

    const { data: rooms, isLoading: isLoadingRooms } = useQuery(["hotels", hotelSlug, "rooms", options], () =>
        getHotelRooms(options)
    )
    const { data: quotation, isLoading: isLoadingQuotation, error } = useQuery(["quotations", quotationId], () =>
        getPublicQuotation({quotationId})
    )

    const roomsData = getRoomsForTile(rooms);

    useEffect(() => {
        setActiveRoom(quotation?.products?.[0]?.product)
    }, [quotation])

    const {mutate: update, status } = useMutation(updateQuotation, {
        onSuccess: () => history.push(`/preventivo/${hotelSlug}/${quotationId}/persone`)
    });

    async function nextStep() {
        try {
            if (!activeRoom) {
                return setBookingText('Non hai ancora scelto una camera')
            }

            await update({
                quotationId,
                data: {
                    products: [{
                        ...activeRoom,
                        price: 0
                    }],
                },
            });

            
        } catch(error) {
            console.log(error);
        }
    }

    if (!quotationId || error || (quotation && quotation.status !== "CHECKINGOUT")) {
        return <Redirect to={`/preventivo/${hotelSlug}/date`} />
    }

    return (
        <Fragment>
            <StepsHeader
                title={hotel?.name}
                stepsNum={4}
                activeIndex={1}
                date={`${formatDate(new Date(quotation?.checkin), "dd MMMM")} - ${formatDate(new Date(quotation?.checkout), "dd MMMM")}`}
                subtitle="Scegli la camera"
                stepsLink={[
                    "date",
                    "camera",
                    "persone",
                    // "servizi",
                    "riepilogo",
                ]}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer>
                <LoadingContainer isLoading={isLoadingHotel || isLoadingRooms || isLoadingQuotation}>
                    {roomsData?.map((item, index) => {
                        
                        return (
                            <RoomTile
                                key={index}
                                room={item}
                                containerAction={() =>
                                    setActiveRoom({
                                        product: item._id,
                                        variation: item.priceVariation,
                                        productType: "rooms",
                                        price: 0,
                                    })
                                }
                                active={
                                    activeRoom?.variation ? item.priceVariation === activeRoom?.variation : (item._id === activeRoom?.product || item._id === activeRoom?.product?._id)
                                }
                            />
                        );
                    })}

                    <BookingBar
                        text={bookingText}
                        action={nextStep}
                        status={status}
                        buttonText="AVANTI"
                    />
                </LoadingContainer>
            </MainContainer>
        </Fragment>
    );
}