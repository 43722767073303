import React, { Fragment } from 'react';
import qs from 'query-string'
import { useLocation, useParams } from 'react-router';
import { useQuery } from 'react-query';

import { MainContainer, TitleContainer, MainTitle, MainButton, Paragraph, ButtonsRow, SimpleImage, LoadingContainer, CardContainer, ColumnContainer, Column } from '@beacharound/beacharound-ui';

import { getPlaceholderImage } from '../../../helpers/images/getPlaceholderImage';
import { getReservation } from '../../../api/reservations/shared';
import HotelsSlider from '../../../components/Hotels/HotelsSlider';
import OffersSlider from '../../../components/Offers/OffersSlider';

export default function BookingThanks({history}) {

    const { search } = useLocation();
    const { externalWebsiteUrl } = qs.parse(search)
    const { reservationId, beachSlug } = useParams();

    const { data: reservation, isLoading: isLoadingReservation} = useQuery(["reservations", reservationId], () =>
        getReservation({ reservationId, activitySlug: beachSlug }),
        {enabled: !!reservationId && !!beachSlug}
    );

    const activityType = reservation?.activity?.type;

    return (

        <LoadingContainer isLoading={isLoadingReservation}>
            {activityType &&
                <MainContainer>
                    <ColumnContainer lowMargin>
                        <Column>
                            <SimpleImage image={getPlaceholderImage('home')}/>
                        </Column>
                    </ColumnContainer>

                    <CardContainer highPadding marginBottom>
                        {activityType === 'public' ?
                            <Fragment>
                                <TitleContainer>
                                    <MainTitle text="Richiesta inviata con successo!" center/>
                                </TitleContainer>
                                <TitleContainer>
                                    <Paragraph text="Grazie per aver richiesto il tuo posto in spiaggia con Beacharound.<br/> Ora puoi vedere il riepilogo nel tuo profilo." center/>
                                </TitleContainer>

                                <ButtonsRow fill center>
                                    {externalWebsiteUrl ?
                                        <MainButton
                                            text="Torna al sito"
                                            component="a"
                                            to={(externalWebsiteUrl.includes("http") ? externalWebsiteUrl : "https://" + externalWebsiteUrl).replaceAll('"','').replaceAll(' ','').replaceAll('%22','')}
                                            blank
                                        />
                                    :
                                        <MainButton
                                            text="Vedi dettaglio richiesta"
                                            action={() => history.push(`/profilo/prenotazioni/${reservationId}`)}
                                        />
                                    }
                                </ButtonsRow>
                            </Fragment>
                        :
                            <Fragment>
                                <TitleContainer>
                                    <MainTitle text="Grazie per aver prenotato su Beacharound!" center/>
                                </TitleContainer>
                                <TitleContainer>
                                    <Paragraph text="Stiamo verificando il tuo pagamento.<br/> Riceverai una mail di conferma dell'avvenuta prenotazione." center/>
                                </TitleContainer>

                                <ButtonsRow fill center>
                                    {externalWebsiteUrl ?
                                        <MainButton
                                            text="Torna al sito"
                                            component="a"
                                            to={(externalWebsiteUrl.includes("http") ? externalWebsiteUrl : "https://" + externalWebsiteUrl).replaceAll('"','').replaceAll(' ','').replaceAll('%22','')}
                                            blank
                                        />
                                    :
                                        <MainButton
                                            text="Vedi prenotazione"
                                            action={() => history.push(`/profilo/prenotazioni/${reservationId}`)}
                                        />
                                    }
                                </ButtonsRow>
                            </Fragment>
                        }
                    </CardContainer>
                </MainContainer>
            }

            {!externalWebsiteUrl &&
                <Fragment>
                    <OffersSlider
                        title={`Offerte imperdibili vicino a  ${reservation?.activity?.name}`}
                        subtitle={`Scopri le promozioni e le offerte proposte dagli hotel in esclusiva per Beacharound vicino a ${reservation?.activity?.name}`}
                        white
                        noMargin
                        place={reservation?.activity?.slug}
                    />

                    <MainContainer>
                        <HotelsSlider
                            title={`Potrebbero interessarti anche`}
                            subtitle={`Scopri gli hotel più adatti a te vicino a ${reservation?.activity?.name} e prenota la tua camera!`}
                            place={reservation?.activity?.slug}
                        />
                    </MainContainer>
                </Fragment>
            }

        </LoadingContainer>
    )
}