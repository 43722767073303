import React, { Fragment } from 'react';
import {
    MainContainer,
    CardSlider,
    Column,
    ColumnContainer,
    MainButton,
    MainTitle,
    Paragraph,
    TitleContainer,
    ButtonsRow,
    FullImageContainer,
    LoadingContainer
} from "@beacharound/beacharound-ui";
import { getImageCrop } from "@beacharound/beacharound-ui-helpers";

import { useQuery } from "react-query";
import { getOffers, getHotelOffers } from '../../api/hotels';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { getPlaceholderImage } from '../../helpers/images/getPlaceholderImage';

export default function OffersSlider({
    title,
    subtitle,
    params,
    sort,
    limit,
    place,
    more,
    white,
    noMargin,
    noBorder,
    hotel,
    highlight,
    services,
    accessibilities,
    coordinates,
    noBg,
    listName
}) {

    let options = {
        limit: limit || 10,
        sort: sort || { updatedAt: "-1" },
        place,
        params: {
            ...params,
        }
    }

    if (services) {
        options = {
            ...options,
            services,
        }
    }

    if (accessibilities) {
        options = {
            ...options,
            accessibilities,
        }
    }

    if (coordinates && coordinates.latitude && coordinates.longitude) {
        options = {
            ...options,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
        };
    }

    if (highlight) {
        options = {
            ...options,
            highlight: true,
            sort: { "highlight.fromDate": -1 },
        }
    }

    const searchFunction = hotel ? getHotelOffers : getOffers;

    const { data, isLoading, isFetching } = useQuery(["offers", options], () =>
        searchFunction({params: options, hotel}),
        {
            refetchOnWindowFocus: false
        }
    )

    const offers = data?.map((item, index) => {

        const to = `/hotel/${hotel || item.activity?.slug}/offerte/${item.slug}`;
        
        return {
            image: item.cover?.length > 0 ? getImageCrop(item.cover, "m") : getPlaceholderImage("offers"),
            title: item.name,
            subtitle: item.activity?.name,
            noBorder: noBorder || false,
            component: Link,
            to: listName ? `${to}?listName=${listName}&listIndex=${index + 1}` : to,
        };
    })

    const Component = noBg ? 'div' : FullImageContainer;

    if (!offers || offers.length === 0) {
        return <Fragment></Fragment>;
    }

    return (
        <LazyLoad height={200}>
            <LoadingContainer isLoading={isLoading || isFetching}>
                <Component color="main" lowPadding>
                    <MainContainer>
                        <TitleContainer>
                            <MainTitle
                                text={title || "Non perdere le offerte esclusive di Beacharound!"}
                                color={white && "white"}
                            />
                            <Paragraph text={subtitle} color={white && "white"} />
                        </TitleContainer>
                        <ColumnContainer noMargin={noMargin}>
                            <Column>
                                <CardSlider slides={offers} marginBottom={more} />
                                {more && (
                                    <ButtonsRow fill>
                                        <MainButton
                                            text="Scopri altre offerte"
                                            component={Link}
                                            to={more.url ? more.url : `${more}/offerte`}
                                            color={white && "white"}
                                        />
                                    </ButtonsRow>
                                )}
                            </Column>
                        </ColumnContainer>
                    </MainContainer>
                </Component>
            </LoadingContainer>
        </LazyLoad>
    )
}

