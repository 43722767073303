import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from "react-query";
import { MainContainer, MainTitle, TitleContainer, ColumnContainer, Column, SimpleLink } from '@beacharound/beacharound-ui';

import { getDistricts } from '../../api/places';

export default function DistrictsSitemap() {

    let options = {
        params: {},
        skip: 0,
        limit: 2000000,
        sort: {name: 1}
    }

    const { data } = useQuery(["districts", options], () =>
        getDistricts({ params: options })
    );


    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Mappa del sito - Province" component="div" />
            </TitleContainer>

             <ColumnContainer flex>
                {data?.map((sitemap, index) => {
                    return (
                        <Column column={2} key={index}>
                            <SimpleLink index={sitemap.slug} text={`Spiagge in provincia di ${sitemap.name}`} to={`/le-migliori-spiagge-in-provincia-di-${sitemap.slug}`} component={Link} />
                        </Column>
                    )
                })}
                {data?.map((sitemap, index) => {
                    return (
                        <Column column={2} key={index}>
                            <SimpleLink index={sitemap.slug} text={`Hotel in provincia di ${sitemap.name}`} to={`/i-migliori-hotel-in-provincia-di-${sitemap.slug}`} component={Link} />
                        </Column>
                    )
                })}
            </ColumnContainer>
        </MainContainer>
    );
}