export function getRoomsForTile(rooms) {

    if(!rooms) return [];

    return rooms?.reduce((accumulator, room) => {

        if(!room?.activityPrices) return [...accumulator, getRoomObject(room)];

        return accumulator = [
            ...accumulator,
            ...room?.activityPrices?.map(activityPrice => {
                return getRoomObject(room, activityPrice);
            })
        ]
    }, [])
} 

function getRoomObject(room, activityPrice) {

    if (room?.beds?.length > 0) {
        let bedInfo = "";
        let bedsCount = { single: 0, double: 0, queen: 0, king: 0 };
        room.beds.map((bed) => bedsCount[bed.type]++);

        Object.keys(bedsCount).map((key) => {
            if (bedsCount[key] > 0) {
                switch (key) {
                    case "single":
                        bedInfo += `Letto singolo<br/>`;
                        break;
                    case "double":
                        bedInfo += `Letto matrimoniale<br/>`;
                        break;
                    case "queen":
                        bedInfo += `Letto queen-size<br/>`;
                        break;
                    case "king":
                        bedInfo += `Letto king-size<br/>`;
                        break;
                    default:
                        break;
                }
            }

            return bedInfo;
        });

        room["info"] = bedInfo;
    }

    let title = room?.name;
    if(activityPrice?.name) {
        title = `${title} - ${activityPrice.name}`
    }

    return {
        title,
        priceVariation: activityPrice?._id,
        ...room,
    };
}

export function getBedText(bed) {
    if(!bed) return "";
    let bedWord = bed.quantity > 1 ? "letti" : "letto";
    switch (bed.type) {
        case "single": bedWord = bed.quantity > 1 ? "letti singoli" : "letto singolo"; break;
        case "queen": bedWord = bed.quantity > 1 ? "letti a una piazza e mezza" : "letto a una piazza e mezza"; break;
        case "double": bedWord = bed.quantity > 1 ? "letti matrimoniali" : "letto matrimoniale"; break;
        case "king": bedWord = bed.quantity > 1 ? "letti king" : "letto king"; break;
        default: bedWord = '';
    }

    return `${bed.quantity} ${bedWord}`
}