export const title = "BEACHAROUND INTERNATIONAL Sagl<br />Informativa sulla privacy";

export const condition = `
<strong>1. INFORMATIVA SULLA PROTEZIONE DEI DATI: DI COSA SI TRATTA?</strong>
<br />
Nella presente Informativa sulla protezione dei dati le comunichiamo le modalità e i motivi per
cui raccogliamo, trattiamo e utilizziamo i suoi dati personali (di seguito anche «dati»).
<br />
<br />
In questa sede riceverà informazioni, tra le altre cose, sui seguenti aspetti:
<ul>
    <li>▪ quali dati personali raccogliamo e trattiamo;</li>
    <li>▪ per quali finalità utilizziamo i suoi dati personali;</li>
    <li>▪ chi ha accesso ai suoi dati personali;</li>
    <li>▪ quali vantaggi ha per lei il nostro trattamento dei dati;</li>
    <li>▪ per quanto tempo trattiamo i suoi dati personali;</li>
    <li>▪ quali sono i suoi diritti con riferimento ai suoi dati personali e</li>
    <li>▪ come ci può contattare.</li>
</ul
<br />
<br />
Nel trattamento dei vostri dati ci atteniamo sempre alle disposizioni di legge, rispettiamo le disposizioni legali della legge federale sulla protezione dei dati (LPD), le relative disposizioni esecutive 
contenute nell’ordinanza sulla protezione dei dati (OPDa) e nell’ordinanza sulle certificazioni in materia di protezione dei dati (OCPD). Inoltre, rispettiamo la legge sulle telecomunicazioni (LTC) e 
tutte le altre disposizioni applicabili in materia di protezione dei dati del diritto svizzero e se del caso la normativa del Regolamento generale sulla protezione dei dati (GDPR), le
cui disposizioni possono essere applicabili in casi specifici.
<br />
<br />
<strong>2. DI CHI È LA RESPONSABILITÀ PER IL TRATTAMENTO DEI DATI?</strong>
<br />
Ai sensi della legge sulla protezione dei dati, il titolare di un determinato trattamento di dati è
colui che definisce se questo trattamento deve avvenire, per quale finalità e in che modo. Il
responsabile del trattamento è invece colui che tratta dati personali per conto del titolare del
trattamento.
<br />
Titolare o responsabile del trattamento dati nell’ambito delle proprie attività è:
<br />
<br />
BEACHAROUND INTERNATIONAL Sagl
<br />
Via Serafino Balestra 5
<br />
6900 Lugano
<br />
<br />
<strong>3. A CHI E A QUALE UTILIZZO È DESTINATA LA PRESENTE INFORMATIVA SULLA
PROTEZIONE DEI DATI?</strong>
<br />
La presente Informativa sulla protezione dei dati è valida per tutte le persone i cui dati vengono
trattati, indipendentemente dal fatto che BEACHAROUND INTERNATIONAL Sagl abbia il ruolo
di titolare o di responsabile del trattamento come pure indipendentemente dal canale attraverso
il quale le persone entrano in contatto con noi (p. es. per telefono o tramite un sito web).
<br />
<br />
Se per la prenotazione viene utilizzata una piattaforma esterna (ad esempio Booking, Hotel,
Escapio, Expedia, Holidaycheck, Hotel Tonight, HRS, Kayak, Mr. & Mrs. Smith, Splendia, Tablet
Hotels, Tripadvisor, Trivago, Weekend4Two, ecc.), riceveremo dal gestore della piattaforma in
questione diversi dati personali correlati alla prenotazione effettuata. Inoltre, possono esserci
inoltrate richieste relative alla vostra prenotazione. Questi dati vengono elaborati in particolare
per registrare la vostra prenotazione come richiesto e per mettere a disposizione i servizi prenotati.
<br />
<br />
Si applica al trattamento dei dati personali già raccolti e dei dati personali raccolti in futuro. È
valida per il trattamento di tutti i dati personali che gestiamo nell’ambito dell’esecuzione delle
nostre attività.
<br />
<br />
Operiamo nel settore turistico ed in particolare dello sviluppo, la produzione e la
commercializzazione di prodotti o servizi innovativi ad alto valore tecnologico e più
specificatamente: lo sviluppo, la produzione e la commercializzazione di un prodotto/servizio
innovativo realizzato attraverso lo sviluppo e la produzione di un innovativo software di esclusiva
proprietà che consente di digitalizzare la mappa degli stabilimenti balneari, nonché tramite
l'innovativo portale web che consente la prenotazione da parte degli utenti della propria
postazione in spiaggia e dei relativi servizi. L'assunzione di rappresentanze, anche esclusive, la
consulenza, il marketing, l'intermediazione, le promozioni e ogni altra attività similare in ambito
turistico e comunque connessa con lo scopo sociale. La società potrà esplicare ogni altra attività
connessa con lo scopo sociale ed eseguire ogni operazione o transazione giuridica necessaria
o utile per il conseguimento di detto scopo. La società può concedere finanziamenti o anticipi di
qualsiasi tipo ad imprese associate, detenere partecipazioni in Svizzera e all'estero. La società
può anche acquisire beni mobili e immobili.
<br />
<br />
Nell’ambito delle nostre attività trattiamo i suoi dati personali sulla base delle disposizioni di
legge applicabili. Nella presente Informativa sulla protezione dei dati la informiamo su tutto il
nostro ambito di attività e i riferimenti in questa Informativa sulla protezione dei dati si applicano
in ogni caso, a meno che non la informiamo diversamente.
<br />
<br />
<strong>4. QUALI DATI PERSONALI TRATTIAMO?</strong>
<br />
I «dati personali» sono informazioni che possono essere associate a una persona specifica,
rendendola identificabile.
<br />
<br />
In particolare, in funzione del caso e dello scopo specifico (v.sotto) raccogliamo i seguenti dati
personali:
<br />
<br />
<li>-  Appellativo</li>
<li>-  Nome</li>
<li>-  Cognome</li>
<li>-  Indirizzo</li>
<li>-  Numero telefonico</li>
<li>-  E-mail</li>
<li>-  Data di nascita</li>
<li>-  Lingue</li>
<li>-  Genere</li>
<li>-  Modalità di pagamento</li>
<li>-  Informazioni per l’iscrizione alle e-mail di marketing</li>
<li>-  Conferma di presa visione e accettazione delle CGC e delle disposizioni sulla protezione</li>
dei dati
<br />
<br />
Alcuni dati personali ci vengono forniti da lei o da terze persone coinvolte in occasione di contatti
telefonici o tramite posta elettronica con cui richiedete i nostri servizi. Può trattarsi del nome, dei
dati di contatto o di informazioni sul ruolo che svolge la persona coinvolta all'interno dell’azienda
o dell'organizzazione per la quale lei (o la rispettiva persona di contatto) lavora, o per conto della
quale veniamo contattati.
<br />
<br />
Durante l’esecuzione dei nostri contratti, trattiamo inoltre i dati personali che raccogliamo corrispondendo 
con i clienti o con terzi (in particolare controparti, autorità, tribunali, i loro rispettivi
collaboratori o altre persone di contatto). Può trattarsi di nomi, dati di contatto, date di nascita,
informazioni sui rapporti di lavoro, sul reddito, sulla situazione familiare o sullo stato di salute.
Inoltre, raccogliamo noi stessi determinati dati personali rilevandoli da registri pubblici o siti web.
<br />
<br />
Se vi mettete in contatto con noi tramite i nostri recapiti e canali di comunicazione (come ad
esempio e-mail, telefono o il nostro modulo di contatto), i vostri dati personali saranno trattati da
noi. Questo trattamento riguarda le informazioni che ci avete fornito, come il vostro nome, l'indirizzo 
e-mail, il numero di telefono e la natura della vostra richiesta. Inoltre, verrà registrato il
momento in cui abbiamo ricevuto la vostra richiesta. Questo trattamento dei dati è finalizzato a
soddisfare le vostre esigenze (come fornirvi informazioni sul nostro hotel, assistervi nell'esecuzione 
di contratti, ad esempio nel caso di domande sulla vostra prenotazione, raccogliere il vostro 
feedback per migliorare i nostri servizi, eccetera).
<br />
<br />
<strong>5. PER QUALI FINALITÀ TRATTIAMO DATI PERSONALI?</strong>
<br />
Utilizziamo i dati per accertare la vostra identità prima di concludere un contratto. Il vostro indirizzo 
e-mail ci serve per eventuali conferme e per le future comunicazioni con voi necessarie
per l’esecuzione del contratto. I vostri dati vengono memorizzati insieme ai metadati dell’ordine
(ad esempio la descrizione, il prezzo e le caratteristiche dei prodotti), ai dati relativi al pagamento
(ad esempio la modalità di pagamento scelta, la conferma del pagamento e l’ora), nonché ai dati
relativi all’esecuzione e all’adempimento del contratto (ad esempio la ricezione e la gestione dei
reclami nel nostro database), in modo da poter garantire una corretta elaborazione dell’ordine e
l’adempimento del contratto.
<br />
<br />
Elaboriamo questi dati per adattare il più possibile la nostra offerta alle vostre esigenze personali, 
per facilitare l’esecuzione dei contratti, all’occorrenza per contattarvi con un mezzo di comunicazione 
alternativo in riferimento all’adempimento del contratto o per la raccolta e la valutazione statistica 
finalizzate all’ottimizzazione delle nostre offerte. I vostri dati sono impiegati per
fornire un resoconto delle prenotazioni effettuate e dei servizi acquistati. Ciò include la preparazione, 
la definizione del contenuto, l'esecuzione e la modifica dei contratti conclusi con voi tramite tutti i 
canali di comunicazione, come ad esempio per la vostra prenotazione con noi.
<br />
<br />
Possiamo trattare dati personali anche per scopi di comunicazione, esecuzione del contratto,
sicurezza e prevenzione, rispetto di disposizioni giuridiche, salvaguardia dei diritti, così come
per nostre procedure interne di amministrazione, oltre a raccogliere i vostri dati per mezzo del
nostro sito web dove è possibile prenotare un pernottamento.
<br />
<br />
Inoltre, possiamo trattare dati di contatto dei collaboratori o di altre persone per scopi di marketing 
(utilizzando qualsiasi mezzo di comunicazione quale posta elettronica, social media, posta
o telefono), al fine di fornire informazioni su pubblicazioni, eventi, nuovi servizi o prodotti che
potrebbero essere di loro interesse.
<br />
<br />
<strong>6. SU QUALI BASI LEGALI SI FONDA IL NOSTRO TRATTAMENTO DEI DATI PERSONALI?</strong>
<br />
Nell’ambito delle nostre attività il nostro trattamento dei dati è soggetto alle disposizioni svizzere
in materia di protezione dei dati della legge sulla protezione dei dati (LPD) ed in via adesiva alla
normativa prevista dal GDPR.
<br />
<br />
Oltre a quanto sopra trattiamo dati personali per la tutela di nostri legittimi interessi, sulla base
di un consenso esplicito secondo l’articolo 28 cpv.1 lit. b o per adempiere a disposizioni giuridiche.
<br />
<br />
<strong>7. A CHI INOLTRIAMO DATI PERSONALI?</strong>
<br />
Per le nostre attività, potrebbe essere necessario trasmettere i dati personali alle seguenti categorie 
di destinatari: fornitori di servizi esterni, clienti, controparti e loro rappresentanti legali, partner 
commerciali con i quali potremmo avere bisogno di collaborare.
Vogliamo comunque garantirvi che trasmetteremo i vostri dati personali solo quando è richiesto
per legge, quando avete dato il vostro consenso o quando abbiamo un interesse legittimo (ad
esempio, per difendere i nostri diritti). Trasmetteremo i vostri dati a terzi solo se necessario per
fornire i servizi richiesti da voi.
<br />
<br />
<strong>8. COME COMUNICHIAMO DATI PERSONALI ALL’ESTERO?</strong>
<br />
Senza il contributo di altre imprese, non saremmo in grado di erogare i nostri servizi nel modo
desiderato. Per poter usufruire dei servizi offerti da tali imprese, è essenziale che i vostri dati
personali vengano condivisi con esse entro determinati limiti. I dati sono condivisi con fornitori
di servizi terzi selezionati solo nella misura strettamente necessaria per garantire un'ottimale
erogazione dei nostri servizi.
<br />
<br />
Trattiamo i dati personali quasi esclusivamente in Svizzera. Possiamo altresì utilizzare servizi IT
comuni in cui alcuni flussi di dati al di fuori della Svizzera sono inevitabili. Se trasmettiamo i suoi
dati personali verso uno di questi Paesi, assicuriamo la protezione dei suoi dati personali in
maniera adeguata.
<br />
<br />
Resta inteso che siamo autorizzati a trasferire i vostri dati personali anche a terzi all’estero, nella
misura in cui ciò sia necessario per eseguire il trattamento dei dati menzionato nella presente
Informativa sulla privacy. Tali trasferimenti, ovviamente, avvengono nell’osservanza delle disposizioni 
di legge per la divulgazione dei dati personali a terzi. Tra gli Stati verso cui avviene il
trasferimento dei dati ve ne sono alcuni che, ai sensi della decisione sull’adeguatezza emanata
dal Consiglio federale e dalla Commissione UE, garantiscono un livello adeguato di protezione
dei dati (ad esempio gli Stati membri del SEE o, dal punto di vista dell’UE, anche la Svizzera),
ma anche Stati (come gli USA) in cui il livello di protezione dei dati non è considerato adeguato
(vedere l’Allegato 1 dell’Ordinanza sulla protezione dei dati (OPDa) e il sito web della Commissione UE). 
Se il Paese in questione non dispone di un livello di protezione dei dati adeguato, ci
assicuriamo che i vostri dati siano sufficientemente protetti presso queste aziende attraverso
garanzie adeguate, a meno che in casi particolari non sia prevista una deroga per il singolo
trattamento dei dati (cfr. l’articolo 49 del GDPR). Se non vi sono eccezioni, si demanda all’applicazione 
dell’articolo 46, comma 2, lettera c del GDPR, che può essere consultato sul sito web
della Commissione UE.
<br />
Rispettivamente la normativa sulla Legge sulla protezione dati svizzera obbliga al rispetto delle
prescrizioni fornite dell’Incaricato federale della protezione dei dati e della trasparenza (IFPDT).
In caso di domande sulle misure adottate, rivolgetevi al nostro referente per la protezione dei
dati.
<br />
<br />
<strong>9. COME TRATTIAMO I DATI PERSONALI DEGNI DI PARTICOLARE PROTEZIONE?</strong>
<br />
Determinati tipi di dati personali, p. es. dati su opinioni o attività religiose, filosofiche, politiche o
sindacali, sulla salute, la sfera intima, dati genetici e altri sono considerati «dati personali degni
di particolare protezione» ai sensi del diritto in materia di protezione dei dati. Nel corso delle
nostre attività ordinarie, possiamo trattare dati di questa particolare categoria. Lo faremo solo se
necessario con il consenso della persona interessata.
<br />
<br />
<strong>10. COME PROTEGGIAMO I DATI PERSONALI?</strong>
<br />
Adottiamo misure di sicurezza di natura tecnica e organizzativa per preservare la sicurezza dei
suoi dati personali, per proteggerli da trattamenti non autorizzati o illeciti e per contrastare il
pericolo di perdita, alterazione accidentale, divulgazione involontaria o accesso non autorizzato.
Come tutte le imprese, non possiamo tuttavia escludere con certezza assoluta le violazioni della
sicurezza dei dati: determinati rischi residui sono inevitabili.
Fanno parte delle misure di sicurezza di natura tecnica p. es. la registrazione dei dati, le limitazioni
 di accesso e il salvataggio di copie di sicurezza. Le misure di sicurezza di natura organizzativa comprendono p. es. direttive ai nostri collaboratori e collaboratrici, accordi di confidenzialità 
 e controlli. Obblighiamo anche i nostri responsabili del trattamento ad adottare adeguate
misure di sicurezza tecniche e organizzative.
<br />
<br />
<strong>11. PER QUANTO TEMPO TRATTIAMO DATI PERSONALI?</strong>
<br />
Trattiamo e salviamo i suoi dati personali:
<br />
<ul>
<li>▪ fino a quando è necessario per le finalità del trattamento;</li>
<li>▪ fino a quando abbiamo un interesse legittimo alla conservazione. In particolare, ciò si verifica quando i dati personali ci servono per far valere le nostre pretese o per difenderci dalle</li>
<li>pretese altrui, per finalità di archiviazione e per garantire la sicurezza informatica;</li>
<li>▪ fino a quando sono sottoposti a un obbligo legale di conservazione.</li>
</ul>
<br />
<strong>12. QUALI SONO I SUOI DIRITTI NELL’AMBITO DEL TRATTAMENTO DEI SUOI DATI
PERSONALI?</strong>
<br />
Lei ha il diritto di:
<br />
<ul>
<li>▪ richiedere informazioni sui dati personali memorizzati presso di noi;</li>
<li>▪ far rettificare o completare i dati personali non corretti o incompleti;</li>
<li>▪ richiedere la cancellazione o l’anonimizzazione dei suoi dati personali, se non sono (più)</li>
<li>necessari per l’esecuzione delle nostre attività;</li>
<li>▪ richiedere la limitazione del trattamento dei suoi dati personali, qualora il trattamento per</li>
<li>l’esecuzione delle attività non sia (più) necessario;</li>
<li>▪ ricevere i dati personali da lei forniti in un formato strutturato, di uso comune e leggibile da</li>
<li>dispositivo automatico;</li>
<li>▪ revocare il consenso con effetto per il futuro, se trattiamo i suoi dati personali sulla base di</li>
<li>un consenso.</li>
</ul>
<br />
Si prega di notare che questi diritti possono essere limitati o esclusi in singoli casi, p. es. se ci
sono dubbi sull’identità o se ciò è necessario per proteggere altre persone, per salvaguardare
interessi degni di protezione o per rispettare gli obblighi legali.
Può esercitare i principali diritti summenzionati scrivendoci.
<br />
<br />
È inoltre libero di presentare un reclamo all’autorità di vigilanza competente se ha dubbi sul fatto
che il trattamento dei suoi dati personali sia conforme alla legge. L’autorità di vigilanza competente 
in Svizzera è l’Incaricato federale della protezione dei dati e della trasparenza (IFPDT).
<br />
<br />
<strong>13. COME CI PUÒ CONTATTARE?</strong>
<br />
Per domande relative alla presente Informativa sulla protezione dei dati o al trattamento dei suoi
dati personali ci può scrivere su <a href="mailto:info@beacharound.com" target="_blank" rel="noopener">info@beacharound.com</a>.
<br />
<br />
<strong>14. MODIFICHE DELLA PRESENTE INFORMATIVA SULLA PROTEZIONE DEI DATI</strong>
<br />
La presente Informativa in materia di protezione dei dati può essere in ogni tempo unilateralmente 
adattata da BEACHAROUND INTERNATIONAL Sagl, in particolare qualora cambino i
nostri trattamenti di dati oppure entrino in vigore nuove disposizioni legali. In generale, per i
trattamenti di dati si applica la versione aggiornata dell’Informativa in materia di protezione dei
dati di volta in volta in vigore all’inizio del trattamento in questione.
<br />
`;

// export const title = `INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI AI SENSI DEL REG. U.E. 2016/679 E DEL D. LGS. 196/2003`;

// export const condition = `Gentile Utente,
// <br />
// l’accesso al sito www.beacharound.com e la fruizione dei servizi ad esso connessi (prenotazione di hotel, spiagge, ecc.) richiede il trattamento di alcuni dati personali: per questo motivo, come previsto dalla normativa vigente, ti informiamo che:
// <br />
// <br />
// 1. TITOLARE DEL TRATTAMENTO
// <br />
// Il titolare del trattamento dei dati personali è Beacharound s.r.l., con sede legale a Cattolica (RN) in via Renzi n. 7.
// <br />
// Potrai contattare il titolare del trattamento ai seguenti recapiti: mail info@beacharound.com PEC beacharound.srl@pec.it 
// <br />
// <br />
// 2. FINALITA’ E BASE GIURIDICA DEL TRATTAMENTO
// <br />
// 2.1 I tuoi dati personali verranno trattati in ossequio ai principi di liceità e trasparenza, con modalità manuali ed elettroniche, per le seguenti finalità:
// <br />
// Consentirti di accedere al sito e di usufruire dei servizi ad esso correlati tra i quali, ad esempio, la prenotazione di spiagge ed hotel;
// <br />
// Consentirci di adempiere agli obblighi di legge a cui siamo tenuti tra i quali, ad esempio, l’obbligo di fatturazione rispetto ai servizi acquistati;
// <br />
// Consentirci l’eventuale difesa in un qualsiasi giudizio (civile, penale, amministrativo, ecc.), comunque inerente e/o conseguente all’attività esercitata per il tramite del sito www.beacharound.com ;
// <br />
// Inviarti le nostre newsletter promozionali in relazione a offerte, sconti, pacchetti, ecc. dei nostri partners commerciali (hotel, spiagge, ecc.), a condizione che vi sia il tuo consenso espresso a ricevere tali comunicazioni.
// <br />
// La base giuridica del trattamento è costituita dall’adempimento del contratto (punto a), dall’obbligo legale di trattare i dati (punto b), dal legittimo interesse del titolare (punto c) e dal consenso dell’interessato (punto d). 
// <br />
// 2.2 Ti informiamo, inoltre, che si svolge trattamento anche dei tuoi dati di navigazione acquisiti dai sistemi informatici e dalle procedure software di questo sito web per il suo normale esercizio. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento. Inoltre, potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai datti del sito. Tali dati vengono raccolti sulla base del consenso espresso dall’utente attraverso la persistente navigazione sul sito (comportamento concludente).
// <br />
// <br />
// 3. COMUNICAZIONE DEI DATI 
// <br />
// I dati personali relativi ai trattamenti in questione, per le finalità di cui al punto precedente, potranno essere comunicati:
// <br />
// A chi eroga direttamente i servizi richiesti (hotel, gestori di spiagge, ecc.), i quali sono autonomi titolari del trattamento dei dati comunicati;
// <br />
// A coloro i quali, all’interno dell’organizzazione del Titolare, ne abbiano la necessità per effetto della mansione ricoperta: tali soggetti sono le persone autorizzate al trattamento sotto l’Autorità diretta del Titolare ai sensi dell’art. 4 par. 10 Reg. UE 679/16;
// <br />
// A soggetti terzi a cui il Titolare eventualmente esternalizza talune attività e che di conseguenza erogano allo scrivente determinati servizi strumentali, comunque correlati ai trattamenti e alle finalità sopra descritte, quali ad esempio servizi amministrativi, contabili, fiscali, di revisione, di gestione del sistema informatico e del sito web, di riscossione del credito, di archiviazione di massa. Tali soggetti effettuano trattamenti per conto del Titolare e risultano autorizzati a trattare i dati in qualità di Responsabili del trattamento secondo quanto previsto dall’art. 28 Reg. UE 679/16;
// <br />
// Alle Autorità amministrative e/o all’Autorità Giudiziaria, qualora ne sia fatta espressa richiesta in forza di provvedimenti cogenti ovvero in caso di difesa del titolare in un qualsiasi giudizio. 
// <br />
// Qualora se ne ravvisi l’opportunità, il Titolare potrà identificare precisamente tali terzi direttamente all’interessato che ne faccia richiesta. 
// <br />
// I dati raccolti non saranno oggetto di diffusione e/o di trasferimento verso Paesi Terzi.
// <br />
// <br />
// 4. 	CONSERVAZIONE DEI DATI 
// <br />
// I dati raccolti saranno conservati per il periodo necessario all’erogazione dei servizi richiesti e, successivamente, periodo non superiore a dieci anni, in conformità alle vigenti disposizioni di legge.
// <br />
// In caso di contenzioso giudiziale o stragiudiziale, nel quale Beacharound s.r.l. sia a qualsiasi titolo coinvolta, i dati potranno essere conservati ed utilizzati per la difesa sino al termine del contenzioso. 
// <br />
// I dati comunicati al solo fine di ricevere la newsletter commerciale saranno conservati per un periodo non superiore a ventiquattro mesi. 
// <br />
// <br />
// 5. DIRITTI DELL’INTERESSATO
// <br />
// In ogni momento potrai esercitare, ai sensi degli articoli dal 15 al 22 del Regolamento U.E. n. 2016/679, il diritto di:
// <br />
// Accesso ai tuoi dati personali;
// <br />
// Ottenere la rettifica e/o la cancellazione dei dati, ad eccezione dei dati che devono essere obbligatoriamente conservati per legge;
// <br />
// Ottenere la limitazione del trattamento;
// <br />
// Opporti al trattamento dei tuoi dati personali;
// <br />
// Revocare il consenso all’invio di newsletter, senza pregiudicare la liceità del trattamento basato sul consenso prima della revoca;
// <br />
// Proporre Reclamo all’autorità di controllo dello stato in cui risiedi; se residente in Italia, potrai rivolgerti al Garante per la protezione dei dati personali con sede a Roma.
// <br />
// Puoi esercitare i tuoi diritti con richiesta scritta inviata all’indirizzo e mail info@beacharound.com
// <br />
// <br />
// 6. OBBLIGO DI COMUNICARE I DATI
// <br />
// Non c’è alcun obbligo di comunicare i tuoi dati, ma se vuoi acquistare un servizio offerto dal sito (ad esempio, se vuoi prenotare una spiaggia), dovrai necessariamente registrarti al portale e comunicarci i dati personali richiesti, altrimenti non sarà possibile procedere con la prenotazione. 
// <br />
// I dati di navigazione vengono acquisiti automaticamente ed utilizzati esclusivamente per le finalità indicate al punto 2.2.`

export const checks = [{
    name: "privacy",
    label: 'Ho preso visione dell’<a href="/privacy" target="_blank">informativa privacy</a>'
}]