import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { MainContainer, StepsHeader, NumericInput, MainFormContent, BookingBar } from '@beacharound/beacharound-ui';

import { getHotel } from '../../../../api/hotels';

export default function BookingHotelPeople({match, history}) {

    const [ people, setPeople ] = useState({});

    const { hotelSlug } = match.params;
    const { data: hotel } = useQuery(["hotels", hotelSlug], () =>
        getHotel({ slug: hotelSlug })
    )

    function changePeopleHandler(name, value) {
        return setPeople({...people, [name]: value})
    }

    function getPeopleDefaultValue(name) {
        return people[name]
    }

    function nextStep() {
        if (!people.adults) {
            return console.log('errore')
        }

        return history.push(`/prenotazione/hotel/${hotelSlug}/servizi`)
    }

    return (

        <Fragment>
            <StepsHeader
                title={hotel?.name}
                stepsNum={5}
                activeIndex={2}
                // date={}
                subtitle="Indica quante persone"
                stepsLink={['date','camera','persone','servizi','pagamento']}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer>
                <MainFormContent>
                    <NumericInput
                        label="Numero adulti"
                        name="adults"
                        defaultValue={getPeopleDefaultValue}
                        onChangeInput={changePeopleHandler}
                    />
                    <NumericInput
                        label="Numero bambini"
                        sublabel="da 2 a 12 anni"
                        name="children"
                        defaultValue={getPeopleDefaultValue}
                        onChangeInput={changePeopleHandler}
                    />
                    <NumericInput
                        label="Numero neonati"
                        sublabel="da 0 a 2 anni"
                        name="babies"
                        defaultValue={getPeopleDefaultValue}
                        onChangeInput={changePeopleHandler}
                    />
                </MainFormContent>

                <BookingBar
                    price={1420}
                    action={nextStep}
                    buttonText="AVANTI"
                />
            </MainContainer>
        </Fragment>
    )
}