import { lazy } from 'react';

import MainLayout from "../layouts/MainLayout/MainLayout";

const BookingEventsList  = lazy(() => import("../pages/Beach/BookingEvents/BookingEventsList"));
const BookingEventsTickets  = lazy(() => import("../pages/Beach/BookingEvents/BookingEventsTickets"));
const BookingEventsReview  = lazy(() => import("../pages/Beach/BookingEvents/BookingEventsReview"));
const BookingEventsConfirm  = lazy(() => import("../pages/Beach/BookingEvents/BookingEventsConfirm"));
const BookingEventsPayment  = lazy(() => import("../pages/Beach/BookingEvents/BookingEventsPayment"));
const BookingEventsThanks  = lazy(() => import("../pages/Beach/BookingEvents/BookingEventsThanks"));

const generalRoutes = [
    {
        path: "/prenotazione-eventi/:beachSlug",
        component: BookingEventsList,
        exact: true,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Eventi", "Lista"],
        },
    },
    {
        path: "/prenotazione-eventi/:beachSlug/:eventSlug/:reservationId/tickets",
        component: BookingEventsTickets,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Eventi", "Tickets"],
        },
    },
    {
        path: "/prenotazione-eventi/:beachSlug/:eventSlug/:reservationId/riepilogo",
        component: BookingEventsReview,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Eventi", "Riepilogo"],
        },
    },
    {
        path: "/prenotazione-eventi/:beachSlug/:eventSlug/:reservationId/conferma",
        component: BookingEventsConfirm,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Eventi", "Conferma"],
        },
    },
    {
        path: "/prenotazione-eventi/:beachSlug/:eventSlug/:reservationId/pagamento",
        component: BookingEventsPayment,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Eventi", "Pagamento"],
        },
    },
    {
        path: "/prenotazione-eventi/:beachSlug/:eventSlug/:reservationId/grazie",
        component: BookingEventsThanks,
        layout: MainLayout,
        analytics: {
            name: ["Booking", "Eventi", "Grazie"],
        },
    }
];

export default generalRoutes