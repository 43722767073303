import axiosV2 from './_defaults/v2/anonymous';

export async function getServices(params) {
    try {

        let realParams = {
            ...params
        }

        // Trasformiamo l'array di servizi in stringa prima di passarla
        // if(params.type) {
        //     realParams = {
        //         ...params,
        //         type: {
        //             $in: JSON.stringify(params.type)
        //         }
        //     }
        // }

        const response = await axiosV2.get(`/services`, { params: realParams });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getService({ slug, params }) {
    try {
        const response = await axiosV2.get(`/services/${slug}`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}