import { lazy } from 'react';

import MainLayout from "../layouts/MainLayout/MainLayout";

const BookingFoodUmbrella = lazy(() => import("../pages/Beach/BookingFood/BookingFoodUmbrella"));
const BookingFoodMenu = lazy(() => import("../pages/Beach/BookingFood/BookingFoodMenu"));
const BookingFoodServices = lazy(() => import("../pages/Beach/BookingFood/BookingFoodServices"));
const BookingFoodReview = lazy(() => import("../pages/Beach/BookingFood/BookingFoodReview"));
const BookingFoodPayment = lazy(() => import("../pages/Beach/BookingFood/BookingFoodPayment"));
const BookingFoodThanks = lazy(() => import("../pages/Beach/BookingFood/BookingFoodThanks"));
const ProfileFoodOrderDetail = lazy(() => import("../pages/Profile/ProfileFoodOrderDetail"));

const generalRoutes = [
    {
        path: "/ordine-in-spiaggia/:beachSlug/ombrellone",
        component: BookingFoodUmbrella,
        layout: MainLayout,
        analytics: {
            name: ["Booking", "Food", "Ombrellone"],
        },
    },
    {
        path: "/ordine-in-spiaggia/:beachSlug/:orderId/menu",
        component: BookingFoodMenu,
        layout: MainLayout,
        analytics: {
            name: ["Booking", "Food", "Menu"],
        },
    },
    {
        path: "/ordine-in-spiaggia/:beachSlug/:orderId/servizi",
        component: BookingFoodServices,
        layout: MainLayout,
        analytics: {
            name: ["Booking", "Food", "Servizi"],
        },
    },
    {
        path: "/ordine-in-spiaggia/:beachSlug/:orderId/riepilogo",
        component: BookingFoodReview,
        layout: MainLayout,
        analytics: {
            name: ["Booking", "Food", "Riepilogo"],
        },
    },
    {
        path: "/ordine-in-spiaggia/:beachSlug/:orderId/pagamento",
        component: BookingFoodPayment,
        layout: MainLayout,
        analytics: {
            name: ["Booking", "Food", "Pagamento"],
        },
    },
    {
        path: "/ordine-in-spiaggia/:beachSlug/:orderId/dettaglio",
        component: ProfileFoodOrderDetail,
        layout: MainLayout,
        analytics: {
            name: ["Booking", "Food", "Dettaglio"],
        },
    },
    {
        path: "/ordine-in-spiaggia/:beachSlug/:orderId/grazie",
        component: BookingFoodThanks,
        layout: MainLayout,
        analytics: {
            name: ["Booking", "Food", "Grazie"],
        },
    },
];

export default generalRoutes