import { lazy } from 'react';

import MainLayout from "../layouts/MainLayout/MainLayout";

// import SearchHotels from "../pages/Hotel/SearchHotels";
// import HotelDetail from "../pages/Hotel/HotelDetail";
// import HotelRooms from "../pages/Hotel/HotelRooms";
// import HotelRoomDetail from "../pages/Hotel/HotelRoomDetail";
// import HotelOffers from "../pages/Hotel/HotelOffers";
// import HotelOfferDetail from "../pages/Hotel/HotelOfferDetail";

// import NewReview from "../pages/Reviews/NewReview";
// import ReviewsListPage from "../pages/Reviews/ReviewsListPage";
// import ReviewThanks from "../pages/Reviews/ReviewThanks";

const SearchHotels = lazy(() => import("../pages/Hotel/SearchHotels"));
const HotelDetail = lazy(() => import("../pages/Hotel/HotelDetail"));
const HotelRooms = lazy(() => import("../pages/Hotel/HotelRooms"));
const HotelRoomDetail = lazy(() => import("../pages/Hotel/HotelRoomDetail"));
const HotelOffers = lazy(() => import("../pages/Hotel/HotelOffers"));
const HotelOfferDetail = lazy(() => import("../pages/Hotel/HotelOfferDetail"));

const NewReview = lazy(() => import("../pages/Reviews/NewReview"));
const ReviewsListPage = lazy(() => import("../pages/Reviews/ReviewsListPage"));
const ReviewThanks = lazy(() => import("../pages/Reviews/ReviewThanks"));

const generalRoutes = [
    {
        path: "/hotel",
        component: SearchHotels,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        exact: true,
        analytics: {
            name: "Hotel",
        },
    },
    {
        path: "/hotel/:hotelSlug",
        component: HotelDetail,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/hotel/:hotelSlug/camere",
        component: HotelRooms,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/hotel/:hotelSlug/camere/:roomSlug",
        component: HotelRoomDetail,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/hotel/:hotelSlug/offerte",
        component: HotelOffers,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/hotel/:hotelSlug/offerte/:offerSlug",
        component: HotelOfferDetail,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/hotel/:hotelSlug/recensioni",
        component: ReviewsListPage,
        layout: MainLayout,
        exact: true,
    },
    {
        path: "/hotel/:hotelSlug/recensioni/nuova",
        component: NewReview,
        layout: MainLayout,
        exact: true,
        private: true
    },
    {
        path: "/hotel/:hotelSlug/recensioni/grazie",
        component: ReviewThanks,
        layout: MainLayout,
        exact: true,
        private: true
    },
];

export default generalRoutes