import { lazy } from 'react';
import MainLayout from "../layouts/MainLayout/MainLayout";
import QuotationDetailExternal from '../pages/Beach/Reservations/QuotationDetailExternal';

const BeachDetail = lazy(() => import("../pages/Beach/BeachDetail"));
const BeachEvents = lazy(() => import("../pages/Beach/BeachEvents"));
const BeachPriceList = lazy(() => import("../pages/Beach/BeachPriceList"));
const BeachPriceListSeasonal = lazy(() => import("../pages/Beach/BeachPriceListSeasonal"));
const BeachQRcode = lazy(() => import("../pages/Beach/BeachQRcode"));
const SearchBeaches = lazy(() => import("../pages/Beach/SearchBeaches"));
const BeachesService = lazy(() => import("../pages/Beach/BeachesService"));

const BeachesListPromo = lazy(() => import("../pages/Beach/BeachesListPromo"));

const NewReview = lazy(() => import("../pages/Reviews/NewReview"));
const ReviewThanks = lazy(() => import("../pages/Reviews/ReviewThanks"));
const ReviewsListPage = lazy(() => import("../pages/Reviews/ReviewsListPage"));
const ReservationDetailExternal = lazy(() => import("../pages/Beach/Reservations/ReservationDetailExternal"));

const generalRoutes = [{
    path: "/spiagge",
    component: SearchBeaches,
    layout: MainLayout,
    layoutOptions: { noPadding: true },
    exact: true,
    analytics: {
        name: "Spiagge",
    }
}, {
    path: "/spiagge/servizi/:serviceSlug",
    component: BeachesService,
    layoutOptions: { noPadding: true },
    layout: MainLayout,
    exact: true
}, {
    path: "/spiagge/:beachSlug",
    component: BeachDetail,
    layout: MainLayout,
    exact: true,
}, {
    path: "/spiagge/:beachSlug/recensioni",
    component: ReviewsListPage,
    layout: MainLayout,
    exact: true,
}, {
    path: "/spiagge/:beachSlug/recensioni/nuova",
    component: NewReview,
    layout: MainLayout,
    exact: true,
    // private: true
}, {
    path: "/spiagge/:beachSlug/recensioni/grazie",
    component: ReviewThanks,
    layout: MainLayout,
    exact: true,
    // private: true
}, {
    path: "/spiagge/:beachSlug/eventi-e-feste-in-spiaggia",
    component: BeachEvents,
    layout: MainLayout,
    exact: true,
}, {
    path: "/spiagge/:beachSlug/prezzi-ombrellone-lettino-sdraio",
    component: BeachPriceList,
    layout: MainLayout,
    exact: true,
}, {
    path: "/spiagge/:beachSlug/prezzi-abbontamenti-stagionali-ombrellone-lettino-sdraio",
    component: BeachPriceListSeasonal,
    layout: MainLayout,
    exact: true,
}, {
    path: "/prenotazione/:customerId/:reservationId",
    component: ReservationDetailExternal,
    layout: MainLayout,
    exact: true,
}, {
    path: "/preventivo/:customerId/:reservationId",
    component: QuotationDetailExternal,
    layout: MainLayout,
    exact: true,
}, {
    path: "/spiagge/:beachSlug/qrcode",
    component: BeachQRcode,
    layout: MainLayout,
    exact: true,
}, {
    path: "/spiagge-promo",
    component: BeachesListPromo,
    layout: MainLayout,
    exact: true,
}];

export default generalRoutes;