import React, { Fragment } from 'react';
import LazyLoad from 'react-lazyload';
import { useQuery } from "react-query";
import { Link } from 'react-router-dom';

import {
    CardSlider,
    Column,
    ColumnContainer,
    MainButton,
    SectionTitle,
    Paragraph,
    TitleContainer,
    ButtonsRow,
    LoadingContainer
} from "@beacharound/beacharound-ui";
import { getImageCrop } from '@beacharound/beacharound-ui-helpers';

import {getHotelNameWithStars} from '../../helpers/hotels/stars';

import { getHotels } from '../../api/hotels';
import { useGeolocalization } from '../../contexts/geolocalization';
import { getPlaceholderImage } from '../../helpers/images/getPlaceholderImage';

export default function HotelsSlider({
    title,
    subtitle,
    params,
    sort,
    limit,
    place,
    more,
    nearest,
    highlight,
    services,
    accessibilities,
    coordinates,
    maxDistance,
    noMargin,
    listName,
}) {
    const { hasGeolocalization, geoCoordinates } = useGeolocalization();

    let options = {
        limit: limit || 10,
        sort: sort,
        place,
        maxDistance: place ? true : maxDistance,
        params: {
            ...params,
        },
    };

    if (
        nearest &&
        hasGeolocalization &&
        geoCoordinates?.latitude &&
        geoCoordinates?.longitude
    ) {
        options = {
            ...options,
            latitude: hasGeolocalization && geoCoordinates?.latitude,
            longitude: hasGeolocalization && geoCoordinates?.longitude,
        };
    }

    if (services) {
        options = {
            ...options,
            services,
        };
    }

    if (accessibilities) {
        options = {
            ...options,
            accessibilities,
        };
    }

    if (coordinates && coordinates.latitude && coordinates.longitude) {
        options = {
            ...options,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
            maxDistance: true,
        };
    }

    if (highlight) {
        options = {
            ...options,
            highlight: true,
            sort: { "highlight.fromDate": -1 },
        };
    }

    const { data, isLoading } = useQuery(["hotels", options], () =>
        getHotels({ params: options }),
        {
            refetchOnWindowFocus: false
        }
    );

    const hotels = data?.map((item, index) => {
        const to = `/hotel/${item.slugCity || item.slug}`;
        return {
            image:
                item.cover?.length > 0 ? getImageCrop(item.cover, "m") : getPlaceholderImage("hotels"),
            title: item.name && getHotelNameWithStars(item.name, item.stars),
            description: `${item.address?.city}, ${item.address?.district}`,
            rating: item.rating,
            ratingsLength: item.ratingsLength,
            noBorder: true,
            component: Link,
            to: listName ? `${to}?listName=${listName}&listIndex=${index + 1}` : to,
        };
    });

    /* NON MOSTRIAMO IL CONTENUTO SE
        1 - non troviamo elementi
        2 - chiediamo nearest ma non abbiamo la geolocalizzazione utente
    */

    if (!hotels || hotels?.length === 0) {
        return <Fragment></Fragment>;
    }

    return (
        <LazyLoad heaight={200}>
            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text={nearest && !hasGeolocalization ? "Hotel che potrebbero interessarti" : title}/>
                    <Paragraph text={subtitle} />
                </TitleContainer>
                <ColumnContainer noMargin={noMargin}>
                    <Column>
                        <CardSlider slides={hotels} marginBottom={more} />
                        {more && (
                            <ButtonsRow fill>
                                <MainButton
                                    border
                                    text="Scopri altri hotel"
                                    component={Link}
                                    to={more.url ? more.url : `${more}/hotel`}
                                />
                            </ButtonsRow>
                        )}
                    </Column>
                </ColumnContainer>
            </LoadingContainer>
        </LazyLoad>
    );
}