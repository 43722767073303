import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import {
    MainContainer,
    StepsHeader,
    NumericInput,
    MainFormContent,
    BookingBar,
    LoadingContainer
} from "@beacharound/beacharound-ui";

import { getOffer } from "../../../../api/hotels";
import { updateReservation } from "../../../../api/reservations/offers";
import { formatDate } from '@beacharound/beacharound-ui-helpers';
import { getPublicReservation } from "../../../../api/reservations/shared";

export default function ReservationHotelPeople({ match, history }) {
    const [people, setPeople] = useState({});

    let bookingText = "Scegli il numero di persone.";
    const [bookingErrorText, setBookingErrorText] = useState();

    const { offerSlug, reservationId } = match.params;
    const { data: offer, isLoading: isLoadingOffer } = useQuery(["offers", offerSlug], () =>
        getOffer({ slug: offerSlug })
    );

    const { data: reservation, isLoading: isLoadingReservation, error: resError } = useQuery(["reservations", reservationId],
        () => getPublicReservation({ reservationId })
    );

    // Se l'offerta prevede un numero minimo o massimo di persone prenotabili allora lo impostiamo per gli adulti
    let minPeople, maxPeople;
    const peopleCondition = offer?.conditions?.find(
        ({ type }) => type === "people"
    );

    if (peopleCondition) {
        minPeople = peopleCondition.min;
        maxPeople = peopleCondition.max;

        bookingText = "Questa offerta è valida per";

        if (minPeople) {
            bookingText = `${bookingText} almeno ${minPeople} adulti`;
        }
        if (minPeople && maxPeople) {
            bookingText = `${bookingText} e`;
        }

        if (maxPeople) {
            bookingText = `${bookingText} massimo ${maxPeople} adulti`;
        }
    }

    function getPeopleDefaultValue(name) {
        if (minPeople && name === "adults" && !people[name]) {
            return minPeople;
        }
        return people[name];
    }

    const {mutate: update, status, error, reset } = useMutation(updateReservation, {
        onSuccess: () => history.push(`/prenotazione/offerta/${offerSlug}/${reservation?._id}/riepilogo`)
    });

    function changePeopleHandler(name, value) {
        reset();
        setBookingErrorText();

        return setPeople({ ...people, [name]: value });
    }

    async function nextStep() {
        try {
            let peoplePayload = people;

            if (!people.adults) {
                peoplePayload = {
                    ...peoplePayload,
                    adults: minPeople,
                };
            }

            if (!people.adults && !minPeople) {
                return setBookingErrorText('Inserisci il numero di persone')
            }

            await update({
                reservationId,
                data: {
                    details: Object.keys(peoplePayload).map((key) => {
                        return {
                            key,
                            value: Number(peoplePayload[key]),
                        };
                    }),
                },
            });

            
        } catch (error) {
            console.log(error);
        }
    }

    let title = offer?.name;
    let room = reservation?.additions?.find(
        (addition) => addition.productType === "rooms"
    );

    if (room?.product) {
        title = `${title} - ${room?.product?.name}`;
    }

    if (!reservationId || resError || (reservation && reservation.status !== "CHECKINGOUT")) {
        return <Redirect to={`/prenotazione/offerta/${offerSlug}/date`}/>
    }

    return (
        <Fragment>
            <StepsHeader
                title={title}
                stepsNum={4}
                activeIndex={2}
                date={`${formatDate(new Date(reservation?.checkin), "dd MMMM")} - ${formatDate(new Date(reservation?.checkout), "dd MMMM")}`}
                subtitle="Indica quante persone"
                stepsLink={[
                    "date",
                    "camera",
                    "persone",
                    "riepilogo",
                ]}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer>
                <LoadingContainer isLoading={isLoadingOffer || isLoadingReservation}>
                    <MainFormContent>
                        <NumericInput
                            label="Numero adulti"
                            name="adults"
                            defaultValue={getPeopleDefaultValue}
                            onChangeInput={changePeopleHandler}
                            min={minPeople}
                            max={maxPeople}
                        />
                        <NumericInput
                            label="Numero bambini"
                            sublabel="da 2 a 12 anni"
                            name="children"
                            defaultValue={getPeopleDefaultValue}
                            onChangeInput={changePeopleHandler}
                        />
                        <NumericInput
                            label="Numero neonati"
                            sublabel="da 0 a 2 anni"
                            name="babies"
                            defaultValue={getPeopleDefaultValue}
                            onChangeInput={changePeopleHandler}
                        />
                    </MainFormContent>

                    <BookingBar
                        text={
                            bookingErrorText ||
                            error?.response?.data?.message ||
                            bookingText
                        }
                        action={nextStep}
                        status={status}
                        buttonText="AVANTI"
                    />
                </LoadingContainer>
            </MainContainer>
        </Fragment>
    );
}
