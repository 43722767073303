import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';

import "react-datepicker/dist/react-datepicker.css";
// import '@beacharound/beacharound-ui/dist/css/index.css';

// import 'moment/locale/it';

import routes from './routes';
import NotFoundPage from './pages/NotFoundPage';

import MainRoute from './components/Routes/MainRoute';

import BeacharoundPackage from "../package.json";
import BeacharoundUiPackage from "@beacharound/beacharound-ui/package.json";
import BeacharoundUiHelpersPackage from "@beacharound/beacharound-ui-helpers/package.json";
import { GeoContext } from './contexts/geolocalization';
import { UserContext } from './contexts/user';
import oldRoutes from './routes/oldRoutes';
import RedirectOldRoute from './components/Routes/RedirectOldRoute';
import { LoadingContainer } from '@beacharound/beacharound-ui';
import { FoodContext } from './contexts/food';

const queryCache = new QueryClient({
  defaultConfig: { retry: true }
})

function App() {

    if (process.env.NODE_ENV === 'development') {
      import('@beacharound/beacharound-ui/dist/css/index.css').then(() => console.log('stile caricato', process.env.NODE_ENV))
      import('@beacharound/beacharound-map/dist/css/index.css').then(() => console.log('stile mappa caricato', process.env.NODE_ENV))
    }

    console.log(
      `%c proudly made by Opificio Lamantini Anonimi`,
      "background-color:#3A80B4; font-weight:bold; color:white;"
    );
    console.log(
      `%c beacharound: ${BeacharoundPackage?.version}`,
      "background-color:#3A80B4; font-weight:bold; color:white;"
    );
    console.log(
      `%c beacharound-ui: ${BeacharoundUiPackage?.version}`,
      "background-color:#3A80B4; font-weight:bold; color:white;"
    );
    console.log(
      `%c beacharound-ui-helpers: ${BeacharoundUiHelpersPackage?.version}`,
      "background-color:#3A80B4; font-weight:bold; color:white;"
    );

    // STATE USATI PER LA GEOLOCALIZZAZIONE
    const [ hasGeolocalization, setHasGeolocalization] = useState(false);
    const [ geoCoordinates, setGeoCoordinates ] = useState(false);


    // Al caricamneto di Beacharound chiediamo la possibilità di utilizzare le coordinate
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (
                { coords },
                error
            ) {
                if (error || !coords) {
                    setHasGeolocalization(false);
                    return setGeoCoordinates(false);
                }
                setHasGeolocalization(true);
                return setGeoCoordinates(coords);
            });
        } else {
            setHasGeolocalization(false);
            return setGeoCoordinates(false);
        }
    }, [])

    // STATI PER IL CONTEXT USER
    const [user, setUser] = useState();
    // const location = useLocation();

    // useEffect(() => {
    //     const decodedUser = decodeUser();
    //     setUser(decodedUser);
    // }, [])

    const [order, setOrder] = useState({products: []});

    function addProduct(data, selectedProduct) {
        const price = Number(selectedProduct?.price) * Number(data?.quantity);

        setOrder((prevState) => ({
            ...prevState,
            products: [
                ...prevState.products,
                {
                    product: selectedProduct?._id,
                    name: selectedProduct?.name,
                    quantity: Number(data?.quantity),
                    price,
                },
            ],
        }));
    }

    function removeProduct(id) {
        setOrder((prevState) => ({
            ...prevState,
            products: prevState.products?.filter((item) => item.product !== id)
        }));
    }

    function setUmbrellaOrder(data, beach) {
        return setOrder({
            products: [],
            beachName: beach?.name,
            beachSlug: beach?.slug,
            beachCover: beach?.cover?.url,
            umbrella: data
        })
    }

    return (
        <QueryClientProvider client={queryCache}>
            <GeoContext.Provider
                value={{
                    hasGeolocalization,
                    setHasGeolocalization,
                    geoCoordinates,
                    setGeoCoordinates,
                }}
            >
                <UserContext.Provider value={{ setUser, user }}>
                    <FoodContext.Provider
                        value={{
                            order,
                            addProduct,
                            removeProduct,
                            setUmbrellaOrder,
                        }}
                    >
                        <Suspense fallback={<LoadingContainer isLoading />}>
                            <Router basename="/it">
                                <Switch>
                                    {oldRoutes?.map((route, index) => (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact
                                            render={() => (
                                                <RedirectOldRoute {...route} />
                                            )}
                                        />
                                    ))}
                                    {routes?.map((route, i) => (
                                        <MainRoute key={i} {...route} />
                                    ))}

                                    <Route
                                        path="*"
                                        render={() => <NotFoundPage />}
                                    />
                                </Switch>
                            </Router>
                        </Suspense>
                        {process.env.NODE_ENV === "development" && (
                            <ReactQueryDevtools initialIsOpen={false} />
                        )}
                    </FoodContext.Provider>
                </UserContext.Provider>
            </GeoContext.Provider>
        </QueryClientProvider>
    );
}

export default App;
