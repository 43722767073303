import React from 'react';
import qs from "query-string";
import { useLocation } from 'react-router-dom';
import { Footer } from '@beacharound/beacharound-ui';


export default function MainFooter() {

    const footerString = `<a href="/it/mappa-del-sito">Mappa del sito</a>`;

    const { search } = useLocation();
    const { widget } = qs.parse(search);

    return widget !== "true" && (
        <Footer
            noPadding
            footerString={footerString}
            // privacyLink={`<a href="https://www.iubenda.com/privacy-policy/66513587" class="iubenda-nostyle iubenda-embed" title="Privacy Policy" target="_blank">Privacy Policy</a><script data-hide-prerender="true" type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>`}
            cookieLink={`<a href="https://www.iubenda.com/privacy-policy/66513587/cookie-policy" class="iubenda-nostyle iubenda-embed" title="Cookie Policy" target="_blank">Cookie Policy</a><script data-hide-prerender="true" type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>`}
            // termsLink={`<a href="https://www.iubenda.com/termini-e-condizioni/66513587" class="iubenda-nostyle iubenda-embed" title="Termini e Condizioni" target="_blank">Termini e Condizioni</a><script data-hide-prerender="true" type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>`}
            privacyLink={`<a href="/privacy" title="Privacy Policy" target="_blank">Privacy Policy</a>`}
            termsLink={`<a href="/terms" title="Termini e condizioni" target="_blank">Termini e Condizioni</a>`}
        />
    );
}
