import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { MainContainer, StepsHeader, RoomTile, BookingBar } from '@beacharound/beacharound-ui';
import { getRoomsForTile } from '../../../../helpers/hotels/rooms';

import rooms from '../../../data/rooms';
import { getHotel } from '../../../../api/hotels';

export default function BookingHotelRoom({match, history}) {

    const { hotelSlug } = match.params;
    const { data: hotel } = useQuery(["hotels", hotelSlug], () =>
        getHotel({ slug: hotelSlug })
    )

    const [bookingText, setBookingText] = useState("Scegli la camera");
    const [ activeRoom, setActiveRoom ] = useState();

    const roomsData = rooms && getRoomsForTile(rooms)

    function nextStep() {
        if (!activeRoom) {
            return setBookingText("Non hai ancora scelto la camera");
        }

        return history.push(`/prenotazione/hotel/${hotelSlug}/persone`)
    }

    console.log('bb')

    return (

        <Fragment>
            <StepsHeader
                title={hotel?.name}
                stepsNum={5}
                activeIndex={1}
                // date={}
                subtitle="Scegli la camera"
                stepsLink={['date','camera','persone','servizi','pagamento']}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer>
                {roomsData?.map((item, index) => {
                    return (
                        <RoomTile
                            key={index}
                            room={item}
                            containerAction={() => setActiveRoom(item._id)}
                            active={item._id === activeRoom}
                        />
                    )
                })}

                <BookingBar
                    text={bookingText}
                    action={nextStep}
                    buttonText="AVANTI"
                />
            </MainContainer>
        </Fragment>
    )
}