import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from "react-query";
import { MainContainer, MainTitle, TitleContainer, ColumnContainer, Column, SimpleLink } from '@beacharound/beacharound-ui';

import { getPlacesAll } from "../../api/search/places";

export default function PlacesSitemap() {

    let options = {
        params: {},
        skip: 0,
        limit: 2000000,
        sort: {name: 1}
    }

    const { data } = useQuery(["places", options], () =>
        getPlacesAll({params: options})
    )

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Mappa del sito - Luoghi" component="div" />
            </TitleContainer>

            <ColumnContainer flex>
                {data?.map((sitemap, index) => {
                    return (
                        <Fragment key={index}>
                            <Column column={2}>
                                <SimpleLink index={sitemap.slug} text={sitemap.name} to={`/luoghi/${sitemap.slug}`} component={Link} />
                            </Column>
                            <Column column={2}>
                                <SimpleLink index={sitemap.slug} text={`Spiagge a ${sitemap.name}`} to={`/luoghi/${sitemap.slug}/spiagge`} component={Link} />
                            </Column>
                            <Column column={2}>
                                <SimpleLink index={sitemap.slug} text={`Hotel a ${sitemap.name}`} to={`/luoghi/${sitemap.slug}/hotel`} component={Link} />
                            </Column>
                            <Column column={2}>
                                <SimpleLink index={sitemap.slug} text={`Spiagge libere a ${sitemap.name}`} to={`/luoghi/${sitemap.slug}/spiagge-libere`} component={Link} />
                            </Column>
                        </Fragment>
                    )
                })}
            </ColumnContainer>
        </MainContainer>
    );
}
