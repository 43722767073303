import React, { Fragment, useState } from 'react';
// import { useQuery } from 'react-query';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { isSameDay } from 'date-fns';

import { MainContainer, BookingBar, Divider, LoadingContainer, Tile, Column, ColumnContainer, NormalPopup, TitleContainer, Paragraph, ButtonsRow, MainButton } from '@beacharound/beacharound-ui';
import { formatDate } from '@beacharound/beacharound-ui-helpers';

import { getBeach, getHybridBeachDates } from '../../../api/beach';
import { useQuery } from 'react-query';

export default function BookingBeachHybridDate({match, history, step}) {

    const { beachSlug } = match.params;
    const [ selectedDate, setSelectedDate ] = useState();
    const [ errorPopup, setErrorPopup] = useState()
    const { search } = useLocation();
    const parsedSearch = qs.parse(search);

    const { data: beach, isLoading } = useQuery(["beaches", beachSlug], () =>
        getBeach({ slug: beachSlug })
    )
    const { data: dates, isLoading: isLoadingDates } = useQuery(['dates', beachSlug, 'hybrid'], () =>
        getHybridBeachDates({ slug: beachSlug, params: {product: parsedSearch?.availability, hour: formatDate(new Date(), 'HH:mm')}}),
        {enabled: !!beach && !!beachSlug}
    )

    async function nextStep() {
        if(!selectedDate) {
            return setErrorPopup(true)
        }

        let newSearch = {
            ...parsedSearch,
            date: formatDate(new Date(selectedDate), 'yyyy-MM-dd'),
            hybrid: true
        }
        return history.push(`${match.url.replace("data", step.next)}?${qs.stringify(newSearch)}`)
    }

    return (
        <Fragment>
            <MainContainer marginTop>
                <LoadingContainer isLoading={isLoading || isLoadingDates}>
                    <ColumnContainer flex>

                    {dates?.map((item, index) => {

                        // Se è presente tra le additions
                        let isActive = isSameDay(new Date(selectedDate), new Date(item?.date))
                        
                        let additionString = isActive ? "Selezionato" : "Posti disponibili";
                        let isSoldout = item?.soldout;
                        const isLimit = item?.available <= (item.used * 5);

                        if(!isActive && isLimit) {
                            additionString = "Questa data sta per esaurirsi!";
                        }

                        if(!isActive && isSoldout) {
                            additionString = "Data esaurita!"
                        }

                        const dateString = formatDate(new Date(item?.date), "iiii dd MMMM")

                        return (
                            <Column column={2} key={index}>
                                <Tile
                                    active={isActive}
                                    title={dateString}
                                    text={additionString}
                                    containerAction={() => !isSoldout && setSelectedDate(item?.date)}
                                />
                            </Column>
                        )
                    })}

                    </ColumnContainer>
                </LoadingContainer>

                <Divider/>

                <BookingBar
                    text={step?.title}
                    action={nextStep}
                    buttonText="AVANTI"
                    icon="arrow-right"
                />
            </MainContainer>

            {/* ERRORE */}
            <NormalPopup
                visible={errorPopup}
                setVisible={setErrorPopup}
                title="Attenzione"
            >
                <TitleContainer>
                    <Paragraph text="Non hai ancora selezionato una data per l'ingresso"/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill center>
                    <MainButton text="Ok" action={() => setErrorPopup()}/>
                </ButtonsRow>
            </NormalPopup>
        </Fragment>
    )
}