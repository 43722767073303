import React, { Fragment, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { MainContainer, MainTitle, TitleContainer, ColumnContainer, Column, SimpleImage, SubTitle, SimpleField, Divider, SectionTitle, ButtonsRow, MainButton, LoadingContainer, CardContainer, Paragraph, NormalPopup } from '@beacharound/beacharound-ui';
import { getAddressString } from '@beacharound/beacharound-ui-helpers';

import { acceptBooking, declineBooking, getPublicReservation } from '../../../api/reservations/beaches';
import { getUser } from '../../../api/user';
import { getBeach } from '../../../api/beach';

import { getPlaceholderImage } from '../../../helpers/images/getPlaceholderImage';

import SchedulePricesSection from '../../../components/Beaches/Reservation/SchedulePricesSection';
import PlacesInfoAddress from '../../../components/PlacesInfo/PlacesInfoAddress';
import ReservationDates from '../../../components/Beaches/Reservation/ReservationDates';
import RulesList from '../../../components/Rules/RulesList';

export default function QuotationDetailExternal({ match, history }) {

    const [declinePopup, setDeclinePopup] = useState();

    const { customerId, reservationId } = match.params

    const { data: user, isLoading: isLoadingUser } = useQuery("user", getUser);

    const cache = useQueryClient();

    const { data: reservation, isLoading } = useQuery(["reservations", "external", reservationId], () =>
        getPublicReservation({ customerId, reservationId })
    );

    const { data: beach } = useQuery(["beaches", reservation?.activity?.slugCity], () =>
        getBeach({ slug: reservation?.activity?.slugCity }),
        {
            enabled: !!reservation?.activity?.slugCity
        }
    )

    const { mutate, status } = useMutation(acceptBooking, {
        onSuccess: (data) => {
            return cache.invalidateQueries(["reservations", "external", reservationId])
        }
    })

    const { mutate: mutateDecline, status: declineStatus } = useMutation(declineBooking, {
        onSuccess: (data) => {
            setDeclinePopup();
            return cache.invalidateQueries(["reservations", "external", reservationId])
        }
    })

    async function acceptHandler() {
        await mutate({
            reservationId: reservation?._id,
            activityId: reservation?.activity?._id
        })
    }

    async function declineHandler() {
        await mutateDecline({
            reservationId: reservation?._id,
            activityId: reservation?.activity?._id
        })
    }

    function openDeclinePopup() {
        return setDeclinePopup(true)
    }

    const isAccepted = reservation?.confirmations?.length;
    const isDeclined = reservation?.declines?.length;

    return (
        <LoadingContainer isLoading={isLoading || isLoadingUser}>
            {!reservation &&
                <MainContainer>
                    <TitleContainer>
                        <MainTitle text="Preventivo non trovato" component="h1" />
                        <SectionTitle text={`Ci dispiace, il preventivo che stai cercando non è stato trovato`} />
                    </TitleContainer>

                    <Divider />

                    <ButtonsRow center>
                        <MainButton text="Torna alla home" component={Link} to="/" />
                    </ButtonsRow>
                </MainContainer>
            }

            {reservation &&
                <MainContainer>
                    <TitleContainer>
                        <MainTitle
                            text={`Il tuo preventivo da <strong>${reservation?.activity?.name}</strong>`}
                            component="h1"
                        />
                        <SubTitle
                            color="grey"
                            text={getAddressString(reservation?.activity?.address)}
                        />
                    </TitleContainer>

                    <Divider />

                    {!user &&
                        <CardContainer>
                            <TitleContainer>
                                <SectionTitle text={`Il tuo preventivo da ${reservation?.activity?.name} è pronto!`} />
                                <Paragraph text="Non ti resta che accedere per confermarlo.<br/> Hai già un account Beacharound o desideri registrarti?" />
                            </TitleContainer>
                            <ButtonsRow fill>
                                <MainButton text="Accedi" component={Link} to={`/user/login?backUrlAfterLogin=${match.url}`} />
                                <MainButton text="Registrati" border component={Link} to={`/user/signup?backUrlAfterLogin=${match.url}`} />
                            </ButtonsRow>
                        </CardContainer>
                    }

                    {user &&
                        <Fragment>

                            <SimpleImage image={reservation?.activity?.cover || getPlaceholderImage(reservation?.type)} low />

                            {reservation?.status === "CHECKINGOUT" && !isAccepted && !isDeclined &&
                                <ColumnContainer lowMargin>
                                    <Column>
                                        <ButtonsRow fill>
                                            <MainButton
                                                text="Rifiuta preventivo"
                                                action={() => openDeclinePopup()}
                                                border
                                            />
                                            <MainButton
                                                text="Accetta preventivo"
                                                action={() => acceptHandler()}
                                                status={status}
                                            />
                                        </ButtonsRow>
                                    </Column>
                                </ColumnContainer>
                            }

                            <ColumnContainer lowMargin>
                                <Column>
                                    <Paragraph text="<strong>Attenzione:</strong> accettare il preventivo non comporta la conferma della prenotazione. Quest'ultima verrà comunicata dal gestore." />
                                </Column>
                            </ColumnContainer>

                            {!!isAccepted &&
                                <ColumnContainer>
                                    <Column>
                                        <CardContainer>
                                            <TitleContainer noMargin>
                                                <SectionTitle text="Hai accettato il preventivo!" color="green" />
                                                <Paragraph text="Abbiamo comunicato alla spiaggia che hai accettato il preventivo!" />
                                            </TitleContainer>
                                        </CardContainer>
                                    </Column>
                                </ColumnContainer>
                            }

                            {!!isDeclined &&
                                <ColumnContainer>
                                    <Column>
                                        <CardContainer>
                                            <TitleContainer noMargin>
                                                <SectionTitle text="Hai rifiutato il preventivo!" color="red" />
                                                <Paragraph text="Abbiamo comunicato alla spiaggia che hai rifiutato il preventivo!" />
                                            </TitleContainer>
                                        </CardContainer>
                                    </Column>
                                </ColumnContainer>
                            }

                            <ReservationDates
                                checkin={reservation?.checkin}
                                checkout={reservation?.checkout}
                                durationType={reservation?.durationType}
                            />

                            <ColumnContainer lowMargin>
                                {reservation?.price > 0 &&
                                    <Column column={2}>
                                        <SimpleField
                                            label={reservation?.reply?.price ? "Prezzo originale del preventivo" : "Prezzo totale"}
                                            value={`${reservation?.price} €`}
                                            noMargin
                                            half
                                        />
                                    </Column>
                                }
                            </ColumnContainer>

                            <ColumnContainer lowMargin>
                                <Column>
                                    <Divider noMargin />

                                    <PlacesInfoAddress address={reservation?.activity?.address} />

                                </Column>
                            </ColumnContainer>

                            {reservation?.type === 'beaches' &&
                                <Fragment>
                                    <ColumnContainer lowMargin>
                                        <Column>
                                            <SchedulePricesSection beachSlug={reservation?.activity?.slugCity} reservationId={reservation._id} reservation={reservation} />
                                        </Column>
                                    </ColumnContainer>
                                    <Divider />
                                </Fragment>
                            }

                            {beach?.rules?.length > 0 && (
                                <MainContainer marginTop>
                                    <RulesList rules={beach.rules} />
                                </MainContainer>
                            )}

                            <NormalPopup
                                visible={declinePopup}
                                setVisible={setDeclinePopup}
                                title="Vuoi rifiutare il preventivo?"
                            >
                                <TitleContainer>
                                    <Paragraph text="Stai per rifiutare il preventivo. Il gestore verrà notificato della tua scelta. Vuoi procedere?" />
                                </TitleContainer>

                                <ButtonsRow fill>
                                    <MainButton border text="Annulla" action={() => setDeclinePopup()} />
                                    <MainButton border text="Rifiuta preventivo" action={() => declineHandler()} status={declineStatus} />
                                </ButtonsRow>
                            </NormalPopup>
                        </Fragment>
                    }

                </MainContainer>
            }
        </LoadingContainer>
    );
}
