import React from 'react';

import { EmptyLayoutContainer, EmptyLayoutContent } from '@beacharound/beacharound-ui';
import MainHeader from '../../components/MainHeader';
import MainFooter from '../../components/MainFooter';

export default function MainLayout({ children }) {

    return (

        <EmptyLayoutContainer>
            <MainHeader empty/>

            <EmptyLayoutContent>
                {children}
            </EmptyLayoutContent>

            <MainFooter/>
        </EmptyLayoutContainer>
    )
}
