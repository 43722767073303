import MainLayout from "../layouts/MainLayout/MainLayout";
import SitemapsGeneral from "../pages/Sitemaps/General";
import PlacesSitemap from "../pages/Sitemaps/PlacesSitemap";
import RegionsSitemap from "../pages/Sitemaps/RegionsSitemap";
import DistrictsSitemap from "../pages/Sitemaps/DistrictsSitemap";
import HotelsSitemap from "../pages/Sitemaps/HotelsSitemap";
import BeachesSitemap from "../pages/Sitemaps/BeachesSitemap";
import PublicBeachesSitemap from "../pages/Sitemaps/PublicBeachesSitemap";
import ServicesSitemap from "../pages/Sitemaps/ServicesSitemap";
import CategoriesSitemap from "../pages/Sitemaps/CategoriesSitemap";
import AccessibilitiesSitemap from "../pages/Sitemaps/AccessibilitiesSitemap";

const generalRoutes = [{
    path: "/mappa-del-sito",
    component: SitemapsGeneral,
    layout: MainLayout,
    exact: true,
    analytics: {
        name: "Sitemap",
    },
},{
    path: "/mappa-del-sito/luoghi",
    component: PlacesSitemap,
    layout: MainLayout,
    exact: true,
    analytics: {
        name: "Sitemap | luoghi",
    },
},{
    path: "/mappa-del-sito/regioni",
    component: RegionsSitemap,
    layout: MainLayout,
    exact: true,
    analytics: {
        name: "Sitemap | regioni",
    },
},{
    path: "/mappa-del-sito/province",
    component: DistrictsSitemap,
    layout: MainLayout,
    exact: true,
    analytics: {
        name: "Sitemap | province",
    },
},{
    path: "/mappa-del-sito/hotel",
    component: HotelsSitemap,
    layout: MainLayout,
    exact: true,
    analytics: {
        name: "Sitemap | hotel",
    },
},{
    path: "/mappa-del-sito/spiagge",
    component: BeachesSitemap,
    layout: MainLayout,
    exact: true,
    analytics: {
        name: "Sitemap | spiagge",
    },
},{
    path: "/mappa-del-sito/spiagge-libere",
    component: PublicBeachesSitemap,
    layout: MainLayout,
    exact: true,
    analytics: {
        name: "Sitemap | spiagge libere",
    },
},{
    path: "/mappa-del-sito/servizi",
    component: ServicesSitemap,
    layout: MainLayout,
    exact: true,
    analytics: {
        name: "Sitemap | servizi",
    },
},{
    path: "/mappa-del-sito/categorie",
    component: CategoriesSitemap,
    layout: MainLayout,
    exact: true,
    analytics: {
        name: "Sitemap | categorie",
    },
},{
    path: "/mappa-del-sito/accessibilita",
    component: AccessibilitiesSitemap,
    layout: MainLayout,
    exact: true,
    analytics: {
        name: "Sitemap | accessibilità",
    },
}];

export default generalRoutes