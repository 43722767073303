import React, { Fragment } from 'react';

import { Column, SimpleField, ColumnContainer, Divider } from '@beacharound/beacharound-ui';
import { formatDate } from '@beacharound/beacharound-ui-helpers';
import { isSameDay } from 'date-fns';

export default function ReservationDates({ checkin, checkout, durationType, placedIndex, isPublicBeach, reservation }) {

    const reservationName = isPublicBeach ? 'richiesta' : 'prenotazione';
    const isOneDay = checkin && checkout && isSameDay(new Date(checkin), new Date(checkout));
    let durationTypeText = 'Intera giornata';
    if (["morning", "afternoon"].includes(durationType)) {
        durationTypeText = durationType === 'morning' ? 'Mattina' : 'Pomeriggio';
    }

    if (reservation?.type === 'rental') {
        durationTypeText = undefined;
    }

    return (
        <ColumnContainer noMargin>
            {isOneDay &&
                <Fragment>
                    <Column column={placedIndex ? 3 : 2}>
                        <SimpleField
                            label={`Data ${reservationName}`}
                            value={formatDate(new Date(checkin), "dd MMMM yyyy")}
                        />
                    </Column>

                    {!!durationTypeText &&
                        <Column column={placedIndex ? 3 : 2}>
                            <SimpleField
                                label="Fascia scelta"
                                value={durationTypeText}
                            />
                        </Column>
                    }

                    {placedIndex &&
                        <Column column={3}>
                            <SimpleField
                                label="Posto scelto"
                                value={placedIndex}
                            />
                        </Column>
                    }
                </Fragment>
            }
            {!isOneDay &&
                <Fragment>
                    <Column column={["morning", "afternoon"].includes(durationType) ? (placedIndex ? 4 : 3) : 2}>
                        <SimpleField
                            label="Checkin"
                            value={formatDate(new Date(checkin), "dd MMMM yyyy")}
                        />
                    </Column>
                    <Column column={["morning", "afternoon"].includes(durationType) ? (placedIndex ? 4 : 3) : 2}>
                        <SimpleField
                            label="Checkout"
                            value={formatDate(new Date(checkout), "dd MMMM yyyy")}
                        />
                    </Column>
                    {["morning", "afternoon"].includes(durationType) &&
                        <Column column={placedIndex ? 4 : 3}>
                            <SimpleField
                                label="Fascia scelta"
                                value={durationType === 'morning' ? 'Mattina' : 'Pomeriggio'}
                            />
                        </Column>
                    }
                    {placedIndex &&
                        <Column column={durationType !== 'daily' ? 4 : 3}>
                            <SimpleField
                                label="Posto scelto"
                                value={placedIndex}
                            />
                        </Column>
                    }
                </Fragment>
            }
            <Column>
                <Divider />
            </Column>
        </ColumnContainer>
    );
}