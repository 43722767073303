import axiosV2 from "./_defaults/v2/anonymous";

export async function getPlaces({params}) {
    try {
        const response = await axiosV2.get(`/places`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getPlace({ slug, params }) {
    try {
        const response = await axiosV2.get(`/places/${slug}`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getRegions({ params }) {
    try {
        const response = await axiosV2.get(`/locations/regions/`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getRegion({ slug, params }) {
    try {
        const response = await axiosV2.get(`/locations/regions/${slug}`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getDistricts({ params }) {
    try {
        const response = await axiosV2.get(`/locations/districts`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getDistrict({ slug }) {
    try {
        const response = await axiosV2.get(`/locations/districts/${slug}`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getCities({ params }) {
    try {
        const response = await axiosV2.get(`/locations/cities`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}
