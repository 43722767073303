const initialSlug = "prenota-un-ombrellone-nelle-migliori-spiagge-";
const initialTitle = "Prenota il tuo ombrellone nelle spiagge";

const categories = [{
    slug: 'per-famiglie',
    name: 'per famiglie',
    image: 'family',
    listName: 'Spiagge per famiglie',
    listSubtitle: "Vivi una giornata indimenticabile con chi ti è caro",
    icon: 'beach',
    categories: ['family']
},{
    slug: 'dog-friendly',
    name: 'dog friendly',
    image: 'dog',
    listName: 'Spiagge dog-friendly',
    listSubtitle: "Divertiti in spiaggia con il tuo amico a quattro zampe",
    icon: 'beach',
    services: ['dog-friendly']
},{
    slug: 'per-coppie',
    name: 'per coppie',
    image: 'love',
    listName: 'Spiagge per coppie',
    listSubtitle: "Sole, mare... trova l'ombrellone per la tua giornata romantica",
    icon: 'beach',
    categories: ['love', 'young']
}]

function getSubtitle(text) {
    return `Prenota il tuo ombrellone nelle spiagge ${text}. Sdraio o lettino, stabilimento balneare o lido?`
}

function getDescription(text) {
    return `Cosa scegliere: sdraio o lettino? stabilimento balneare o lido? l'importante è avere un posto riservato al miglior prezzo online! Prenota subito il tuo ombrellone nelle spiagge ${text}. Basta code e stressanti attese sotto il sole ma solo pace e relax.  Sdraio, lettino e tutto ciò che ti serve è già in stabilimento ad attenderti. Se preferisci puoi vivere il tuo meritato riposso anche nella pace di una spiaggia libera. Non ti dovrai più preoccupare di nulla e potrai dedicarti unicamente a divertirti e rilassarti. Dai un occhio qui di seguito e scopri qual'è il luogo più adatto alle tue esigenze; a tutto il resto ci pensa Beacharound!`
}

function getMetaDescription(text) {
    return `Prenota il tuo ombrellone nelle spiagge ${text}. Scegli tra sdraio o lettino, stabilimento balneare o lido al miglior prezzo online`
}

function getServicePage({
    slug,
    name,
    image,
    listName,
    listSubtitle,
    icon,
    categories,
    services,
}) {

    let params = {};

    if(categories) {
        params = {
            ...params,
            categories: {
                $in: categories
            }
        }
    }

    return {
        slug: `${initialSlug}${slug}`,
        title: `${initialTitle} ${name}`,
        subtitle: getSubtitle(name),
        description: getDescription(name),
        // cover: `${initialCoverImage},${image}`,
        image: image,
        listName: listName,
        listSubtitle: listSubtitle,
        icon: icon,
        meta: { description: getMetaDescription(name) },
        searchParams: params,
        nearest: {
            title: `Le spiagge ${name} più vicine a te`,
            subtitle: getSubtitle(name),
            nearest: true,
            services: services,
            params,
        },
        highlight: {
            title: `Le spiagge ${name} consigliate`,
            subtitle: getSubtitle(name),
            highlight: true,
            services: services,
            sort: { highlight: 1 },
            params,
        },
        list: {
            title: `Tutte le spiagge ${name}`,
            subtitle: getSubtitle(name),
            services: services,
            sort: { name: 1 },
            params,
        },
    };
}

const beachesCategoriesPages = categories.map((service) => {
    return getServicePage(service);
})

export default beachesCategoriesPages;

