import MainLayout from "../layouts/MainLayout/MainLayout";

import BookingHotelDate from "../pages/Hotel/Booking/Hotel/BookingHotelDate";
import BookingHotelRoom from "../pages/Hotel/Booking/Hotel/BookingHotelRoom";
import BookingHotelPeople from "../pages/Hotel/Booking/Hotel/BookingHotelPeople";
import BookingHotelServices from "../pages/Hotel/Booking/Hotel/BookingHotelServices";
import BookingHotelPayment from "../pages/Hotel/Booking/Hotel/BookingHotelPayment";

import BookingOfferDate from "../pages/Hotel/Booking/Offers/BookingOfferDate";
import BookingOfferRoom from "../pages/Hotel/Booking/Offers/BookingOfferRoom";
import BookingOfferPeople from "../pages/Hotel/Booking/Offers/BookingOfferPeople";
import BookingOfferSummary from "../pages/Hotel/Booking/Offers/BookingOfferSummary";

const generalRoutes = [
    {
        path: "/prenotazione/hotel/:hotelSlug/date",
        component: BookingHotelDate,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/prenotazione/hotel/:hotelSlug/camera",
        component: BookingHotelRoom,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/prenotazione/hotel/:hotelSlug/persone",
        component: BookingHotelPeople,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/prenotazione/hotel/:hotelSlug/servizi",
        component: BookingHotelServices,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/prenotazione/hotel/:hotelSlug/pagamento",
        component: BookingHotelPayment,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    // OFFERTE
    {
        path: "/prenotazione/offerta/:offerSlug/date",
        component: BookingOfferDate,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/prenotazione/offerta/:offerSlug/:reservationId/date",
        component: BookingOfferDate,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/prenotazione/offerta/:offerSlug/:reservationId/camera",
        component: BookingOfferRoom,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/prenotazione/offerta/:offerSlug/:reservationId/persone",
        component: BookingOfferPeople,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/prenotazione/offerta/:offerSlug/:reservationId/riepilogo",
        component: BookingOfferSummary,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
];

export default generalRoutes