import { formatDate } from "@beacharound/beacharound-ui-helpers";
import { differenceInDays } from "date-fns";

export function getDatesStringWithGaps(dates) {

    let array = [];

    let orderdDates = dates?.sort((a, b) => {
        var dateA = new Date(a.date);
        var dateB = new Date(b.date);
        return dateA - dateB;
    }).map(({date}) => new Date(date));
    
    orderdDates.map((date, index) => {
        const nextDate = orderdDates[index + 1];
        const prevDate = orderdDates[index - 1];
        
        const existingNext = differenceInDays(new Date(nextDate), new Date(date));
        const existingPrev = differenceInDays(new Date(date), new Date(prevDate));
        
        if((!existingPrev && existingNext > 1)) {
            array.push(date);
            return array.push("-")
        }
        if((!existingNext && existingPrev > 1)) {
            array.push("-")
            return array.push(date);
        }
        
        if(!existingNext || !existingPrev || existingNext > 1 || existingPrev > 1) {
            return array.push(date);
        }

        return date;
    })
    
    let datesCouples = [];
    while (array.length) {
        datesCouples = [...datesCouples, array.splice(0, 2)]
    }

    const totalString = datesCouples.reduce((acc, couple) => {
        let string = "";
        
        if (couple.some((gap) => gap === "-") || couple.length === 1) {
            const date = couple.find((gap) => gap !== "-");
            string = `il ${formatDate(new Date(date), "dd MMMM")}`;
        } else {
            string = `dal ${formatDate(couple[0], "dd MMM")} al ${formatDate(
                couple[1],
                "dd MMM"
            )}`;
        }

        if(acc.length > 0) {
            return `${acc} - ${string}`
        }

        return string;
    }, "")

    return totalString
}