import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import qs from 'query-string';
import { useLocation } from 'react-router';

import { MainContainer, BookingBar, Divider, LoadingContainer, Tile, Column, ColumnContainer, NormalPopup, TitleContainer, Paragraph, ButtonsRow, MainButton } from '@beacharound/beacharound-ui';
import { formatDate } from '@beacharound/beacharound-ui-helpers';

import { getBeach, getPublicBeachDates } from '../../../api/beach';

export default function BookingBeachPublicDate({match, history, step}) {

    const { beachSlug } = match.params;
    const [ selectedDate, setSelectedDate ] = useState();
    const [ errorPopup, setErrorPopup] = useState()
    const { search } = useLocation();
    const parsedSearch = qs.parse(search);

    const { data: beach, isLoading } = useQuery(["beaches", beachSlug], () =>
        getBeach({ slug: beachSlug })
    )
    const { data: dates, isLoading: isLoadingDates } = useQuery(['products', beachSlug, 'dates', parsedSearch?.availability], () =>
        getPublicBeachDates({ slug: beachSlug, params: {product: parsedSearch?.availability, hour: formatDate(new Date(), 'HH:mm')}}),
        {enabled: !!beach && !!beachSlug && !!parsedSearch?.availability}
    )

    async function nextStep() {
        if(!selectedDate?.date || !selectedDate?.type) {
            return setErrorPopup(true)
        }

        let newSearch = {
            ...parsedSearch,
            date: selectedDate?.date,
            type: selectedDate?.type
        }
        return history.push(`${match.url.replace("data", step.next)}?${qs.stringify(newSearch)}`)
    }

    return (
        <Fragment>
            <MainContainer marginTop>
                <LoadingContainer isLoading={isLoading || isLoadingDates}>
                    <ColumnContainer flex>

                    {dates?.map((item, index) => {

                        // Se è presente tra le additions
                        let isActive = selectedDate?.date === item.date && selectedDate?.type === item.type;
                        
                        let additionString = isActive ? "Selezionato" : "Posti disponibili";
                        let isSoldout = item?.soldout;
                        const isLimit = item?.available <= (item.used * 5);

                        if(!isActive && isLimit) {
                            additionString = "Questa data sta per esaurirsi!";
                        }

                        if(!isActive && isSoldout) {
                            additionString = "Data esaurita!"
                        }

                        const dateString = formatDate(item.date, "iiii dd MMMM")

                        return (
                            <Column column={2} key={index}>
                                <Tile
                                    active={isActive}
                                    pretitleColor="main"
                                    pretitle={dateString.charAt(0).toUpperCase() + dateString.slice(1)}
                                    title={item.type === "morning" ? "Mattina" : "Pomeriggio"}
                                    text={additionString}
                                    containerAction={() => !isSoldout && setSelectedDate(item)}
                                />
                            </Column>
                        )
                    })}

                    </ColumnContainer>
                </LoadingContainer>

                <Divider/>

                <BookingBar
                    text={step?.title}
                    action={nextStep}
                    buttonText="AVANTI"
                    icon="arrow-right"
                />
            </MainContainer>

            {/* ERRORE */}
            <NormalPopup
                visible={errorPopup}
                setVisible={setErrorPopup}
                title="Attenzione"
            >
                <TitleContainer>
                    <Paragraph text="Non hai ancora selezionato una data per l'ingresso"/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill center>
                    <MainButton text="Ok" action={() => setErrorPopup()}/>
                </ButtonsRow>
            </NormalPopup>
        </Fragment>
    )
}