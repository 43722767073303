import React from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from "react-query";
import qs from "query-string";

import { Divider, MainButton, MainContainer, MainForm, MainFormContent, MainTitle, Paragraph, TextInput, TitleContainer } from '@beacharound/beacharound-ui';

import { changeUserPassword } from "../../api/user";

const constraints = {
    email: { presence: true, email: true },
    password: { presence: true },
    confirmPassword: { presence: true, equality: "password" }
}

export default function ChangePassword({location, match, history}) {

    const { email } = qs.parse(location.search);
    const { token } = match.params;

    const {mutate: changePassword, status } = useMutation(changeUserPassword, {
        onSuccess: () => history.push("/user/login")
    });

    async function changePasswordHandler(data) {
        try {
            await changePassword({ data: {...data, token} });
        } catch (error) {
            console.log(error);
        }
    }

    if(!token) {
        return <Redirect to="/user/login" />;
    }

    return (

        <MainContainer>

            <TitleContainer>
                <MainTitle text="Crea una nuova password" component="h1"/>
                <Paragraph text="Hai inviato una richiesta di modifica della password del tuo account" />
            </TitleContainer>

            <MainForm onSubmit={changePasswordHandler} constraints={constraints} data={{email, token}}>
            
                <MainFormContent flex>
                    <TextInput name="email" label="Email" required/>
                    <TextInput name="token" label="Codice di verifica" sublabel="Inserisci qui il codice di verifica ricevuto via mail" required/>
                    <TextInput name="password" type="password" label="Password" placeholder="Nuova password" half/>
                    <TextInput name="confirmPassword" type="password" label="Conferma password" placeholder="Conferma password" half/>
                </MainFormContent>

                <Divider />

                <MainButton
                    text="Cambia password"
                    submit
                    status={status}
                />
            </MainForm>

        </MainContainer>
    )
}
