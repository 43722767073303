import React, { Fragment, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import {
    MainContainer,
    StepsHeader,
    RoomTile,
    BookingBar,
    LoadingContainer
} from "@beacharound/beacharound-ui";
import { getRoomsForTile } from "../../../../helpers/hotels/rooms";

import { getOffer } from "../../../../api/hotels";
import { updateReservation } from "../../../../api/reservations/offers";
import { formatDate } from '@beacharound/beacharound-ui-helpers';
import { getPublicReservation } from "../../../../api/reservations/shared";

export default function BookingOfferRoom({ match, history }) {
    const { offerSlug, reservationId } = match.params;
    const { data: offer, isLoading: isLoadingOffer } = useQuery(["offers", offerSlug], () =>
        getOffer({ slug: offerSlug })
    );

    const [activeRoom, setActiveRoom] = useState();
    const [bookingText, setBookingText] = useState("Scegli la camera");

    const { data: reservation, isLoading: isLoadingReservation, error } = useQuery(["reservation", reservationId], () =>
        getPublicReservation({ reservationId })
    );

    const roomsData = getRoomsForTile(offer?.relatedProducts);

    useEffect(() => {
        setActiveRoom(reservation?.additions?.find(addition => addition.type === "rooms"));
    }, [reservation]);

    const {mutate: update, status } = useMutation(updateReservation, {
        onSuccess: () => history.push(`/prenotazione/offerta/${offerSlug}/${reservation?._id}/persone`)
    });

    async function nextStep() {
        try {
            if (!activeRoom) {
                return setBookingText("Non hai ancora scelto la camera");
            }

            await update({
                reservationId,
                data: {
                    additions: [
                        {
                            ...activeRoom,
                            price: 0,
                        },
                    ],
                },
            });

        } catch (error) {
            console.log(error);
        }
    }

    if (!reservationId || error || (reservation && reservation.status !== "CHECKINGOUT")) {
        return <Redirect to={`/prenotazione/offerta/${offerSlug}/date`}/>
    }

    return (
        <Fragment>
            <StepsHeader
                title={offer?.name}
                stepsNum={4}
                activeIndex={1}
                date={`${formatDate(new Date(reservation?.checkin), "dd MMMM")} - ${formatDate(new Date(reservation?.checkout), "dd MMMM")}`}
                subtitle="Scegli la camera"
                stepsLink={[
                    "date",
                    "camera",
                    "persone",
                    "riepilogo",
                ]}
                linkComponent={Link}
                marginBottom
            />

            <MainContainer>
                <LoadingContainer isLoading={isLoadingOffer || isLoadingReservation}>
                    {roomsData?.map((item, index) => {
                        return (
                            <RoomTile
                                key={index}
                                room={item}
                                containerAction={() =>
                                    setActiveRoom({
                                        product: item._id,
                                        variation: item.priceVariation,
                                        productType: "rooms",
                                        price: 0,
                                    })
                                }
                                active={
                                    item._id === activeRoom?.product ||
                                    item._id === activeRoom?.product?._id
                                }
                            />
                        );
                    })}

                    <BookingBar
                        text={bookingText}
                        action={nextStep}
                        status={status}
                        buttonText="AVANTI"
                    />
                </LoadingContainer>
            </MainContainer>
        </Fragment>
    );
}
