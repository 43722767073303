import user from './user';
import privateRoutes from './private';
import publicRoutes from './public';

export default [
    ...user,
    ...privateRoutes,
    ...publicRoutes
].map((route) => {
    return {
        ...route,
        path: `${route.path}`,
    };
});