import { lazy } from 'react';
import MainLayout from "../layouts/MainLayout/MainLayout";

// import HomePage from "../pages/General/Home";

// import NotFound from "../pages/NotFound";
// import Results from "../pages/General/Results";
// import ResultsAll from "../pages/General/Results/ResultsAll";
// import ResultsBeach from "../pages/General/Results/ResultsBeach";
// import ResultsHotel from "../pages/General/Results/ResultsHotel";

// import NearResults from "../pages/General/NearResults";
// import NearBeaches from "../pages/General/NearBeaches";
// import NearHotels from "../pages/General/NearHotels";
// import NearExperiences from "../pages/General/NearExperiences";
// import Offers from "../pages/General/Offers";
// import Privacy from "../pages/General/Privacy";
// import Terms from "../pages/General/Terms";
// import BeacharoundBusiness from "../pages/General/BeacharoundBusiness";

const HomePage = lazy(() => import("../pages/General/Home"));

const NotFound = lazy(() => import("../pages/NotFound"));
const Results = lazy(() => import("../pages/General/Results"));
const ResultsAll = lazy(() => import("../pages/General/Results/ResultsAll"));
const ResultsBeach = lazy(() => import("../pages/General/Results/ResultsBeach"));
const ResultsHotel = lazy(() => import("../pages/General/Results/ResultsHotel"));

const NearResults = lazy(() => import("../pages/General/NearResults"));
const NearBeaches = lazy(() => import("../pages/General/NearBeaches"));
const NearHotels = lazy(() => import("../pages/General/NearHotels"));
const NearExperiences = lazy(() => import("../pages/General/NearExperiences"));
const Offers = lazy(() => import("../pages/General/Offers"));
const Privacy = lazy(() => import("../pages/General/Privacy"));
const Terms = lazy(() => import("../pages/General/Terms"));
const BeacharoundBusiness = lazy(() => import("../pages/General/BeacharoundBusiness"));
const External = lazy(() => import("../pages/General/External"));

const generalRoutes = [
    {
        path: "/404",
        component: NotFound,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        exact: true,
        analytics: {
            name: "404",
        },
    },
    {
        path: "/",
        component: HomePage,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        exact: true,
        analytics: {
            name: "Home",
        },
    },
    {
        path: "/beacharound-business",
        component: BeacharoundBusiness,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        exact: true,
        analytics: {
            name: "Beacharound Business",
        },
    },
    {
        path: "/vicino-a-te",
        component: NearResults,
        layout: MainLayout,
        exact: true,
        analytics: {
            name: "Vicino a te",
        },
    },
    {
        path: "/vicino-a-te/spiagge",
        component: NearBeaches,
        layout: MainLayout,
        analytics: {
            name: "Vicino a te spiagge",
        },
    },
    {
        path: "/vicino-a-te/hotel",
        component: NearHotels,
        layout: MainLayout,
        analytics: {
            name: "Vicino a te hotel",
        },
    },
    {
        path: "/vicino-a-te/esperienze",
        component: NearExperiences,
        layout: MainLayout,
        analytics: {
            name: "Vicino a te esperienze",
        },
    },
    {
        path: "/offerte-pacchetti-hotel-alberghi-miglior-prezzo",
        component: Offers,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: "Offerte",
        },
    },
    {
        path: '/terms',
        component: Terms,
        layout: MainLayout,
        exact: true,
        analytics: { name: "Termini e condizioni" }
    },
    {
        path: '/privacy',
        component: Privacy,
        layout: MainLayout,
        exact: true,
        analytics: { name: "Privacy policy" }
    },
    {
        path: '/external',
        component: External,
        layout: MainLayout,
        exact: true,
        analytics: { name: "Link esterno" }
    },
    {
        path: "/ricerca",
        component: Results,
        layout: MainLayout,
        routes: [
            {
                path: ``,
                component: ResultsAll,
                exact: true,
                analytics: {
                    name: "Ricerca",
                },
            },
            {
                path: `/spiagge`,
                component: ResultsBeach,
                analytics: {
                    name: "Ricerca spiagge",
                },
            },
            {
                path: `/hotel`,
                component: ResultsHotel,
                analytics: {
                    name: "Ricerca hotel",
                },
            },
        ].map((route) => {
            return {
                ...route,
                path: `/ricerca${route.path}`,
                private: true,
            };
        }),
    },
];

export default generalRoutes