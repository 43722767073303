import React, { Fragment, useState } from 'react';
import { Link, Switch } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { StepsHeader, NormalPopup, TitleContainer, ButtonsRow, MainButton, SubTitle, CardInline, MainContainer } from '@beacharound/beacharound-ui';
import { formatDate } from '@beacharound/beacharound-ui-helpers';

import SubRoute from '../../../components/Routes/SubRoute';
import { getBookingSteps } from '../../../helpers/booking/getBookingSteps';
import { getPlaceholderImage } from '../../../helpers/images/getPlaceholderImage';
import { getPublicReservation } from '../../../api/reservations/shared';
import { getBeach, getBeachProductsByDates } from '../../../api/beach';
import RulesList from '../../../components/Rules/RulesList';

export default function BookingStepsPublic({match, routes, location, history}) {

    const [ isTooLate, setIsTooLate ] = useState();
    const { beachSlug, reservationId } = match.params;

    const cache = useQueryClient();

    const { data: beach } = useQuery(["beaches", beachSlug], () =>
        getBeach({ slug: beachSlug })
    )
    const { data: reservation } = useQuery(["reservations", reservationId], () =>
        getPublicReservation({ reservationId }),
        {enabled: !!reservationId}
    );
    
    const productOptions = {
        fromDate: reservation?.checkin && formatDate(new Date(reservation.checkin), "yyyy-MM-dd"), 
        toDate: reservation?.checkout && formatDate(new Date(reservation.checkout), "yyyy-MM-dd")
    }

    useQuery(["products", beachSlug, productOptions], () =>
        getBeachProductsByDates({slug: beachSlug, params: productOptions}),
        { enabled: !!reservation && !!reservation?.checkin && !!reservation?.checkout }
    )

    let steps = getBookingSteps({isPublic: true, isHybrid: true});
    const activeStepIndex = steps.findIndex(({key}) => location.pathname?.split('/')?.pop()?.includes(key));

    function confirmIsTooLate() {
        cache.invalidateQueries(["beaches", beachSlug, "dates"], ["reservations", reservationId], ["products", beachSlug])
        setIsTooLate();
        return history.push(`/prenotazione/spiaggia/${beachSlug}/date`)
    }

    return (

        <Fragment>
            <StepsHeader
                title={beach?.name}
                stepsNum={steps.length}
                activeIndex={activeStepIndex + 1}
                date={steps[activeStepIndex]?.title}
                type="small"
                stepsLink={steps.map(({key}) => key)}
                linkComponent={Link}
                marginBottom={steps[activeStepIndex]?.key !== "posto"}
            />

            <Switch>
                {routes?.map((page, index) => {
                    return (
                        <SubRoute
                            key={index}
                            {...page}
                            step={steps[activeStepIndex]}
                            hasMap={beach?.hasMap}
                            setIsTooLate={setIsTooLate}
                        />
                    )
                })}
            </Switch>

            {beach?.rules?.length > 0 && (
                <MainContainer>
                    <RulesList rules={beach.rules}/>
                </MainContainer>
            )}

            <NormalPopup
                visible={isTooLate}
                title="Ops.. qualcuno è stato più veloce di te!"
            >
                <TitleContainer>
                    <SubTitle color="black" text="Ci dispiace, sembra che il posto selezionato non sia più disponibile" />
                </TitleContainer>

                <CardInline
                    noBorder
                    image={getPlaceholderImage('error')}
                    subtitle={`Ti consigliamo di scegliere un posto diverso.`}
                    description={`Ci sono molti utenti che prenotano su Beacharound: qualcuno ha completato una prenotazione più velocemente.`}/>

                <ButtonsRow center>
                    <MainButton text="Avanti" action={() => confirmIsTooLate()}/>
                </ButtonsRow>
            </NormalPopup>
        </Fragment>
    )
}