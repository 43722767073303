import React, { Fragment } from 'react';
import { IconField } from "@beacharound/beacharound-ui";
import {
    getAddressString,
    getAddressUrl,
} from "@beacharound/beacharound-ui-helpers";

export default function PlacesInfoAddress({address}) {

    if(!address) {
        return <Fragment></Fragment>
    }

    let addressString = getAddressString(address);

    return (
        <IconField
            label="Come raggiungere"
            value={addressString}
            icon="spot"
            href={getAddressUrl(address)}
            linkText="Apri in mappe"
            noMargin
            highPadding
        />
    );
}