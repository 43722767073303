import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Redirect, Route, useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";
import { trackPage } from '@beacharound/beacharound-ui-helpers';

import MainLayout from '../../layouts/MainLayout/MainLayout';
import { decodeUser, removeLocalUser } from '../../api/user';
import { useUser } from '../../contexts/user';

export default function MainRoute(route) {

    const LayoutComponent = route.layout || MainLayout
    const cache = useQueryClient();
    const { setUser } = useUser();

    const location = useLocation();

    useEffect(() => {
        if(!route?.analytics) {
            return;
        }
        
        return trackPage(route.analytics);
    }, [location, route.analytics]);

    useEffect(() => {
        const decodedUser = decodeUser();
        setUser(decodedUser);
    }, [location, setUser]);

    return (
        <LayoutComponent options={route.layoutOptions}>
            <Route
                exact={route.exact}
                path={route.path}
                render={(props) => {

                    if (route.private) {
                        const token = Cookies.get('user-authorization');
                        const refreshToken = Cookies.get('user-refreshToken');
                        const userId = Cookies.get('user-id');

                        if (!token && !refreshToken && !userId) {
                            cache.invalidateQueries();
                            removeLocalUser();
                            return <Redirect to={`/user/signup?backUrlAfterLogin=${route.location?.pathname}`} />
                        }
                    }

                    return <route.component {...props} {...route} />
                }}
            />
        </LayoutComponent>

    )
}