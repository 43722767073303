import axios from './_defaults/v2/user';

export async function getBeaches({params}) {

    try {

        let realParams = {
            ...params
        }

        // Trasformiamo l'array di servizi in stringa prima di passarla
        if(params.services) {
            realParams = {
                ...params,
                services: JSON.stringify(params.services)
            }
        }

        const response = await axios.get(`/beaches`, { params: realParams });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getBeach({ slug, params }) {
    try {
        const response = await axios.get(`/beaches/${slug}`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getBeachContacts({ slug, params }) {
    try {
        const response = await axios.get(`/beaches/${slug}/publicContacts`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getBeachMap({ slug, params }) {
    try {
        const response = await axios.get(`/maps/${slug}/active`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}
// export async function getBeachMap({ slug, params, beachMapId }) {
//     try {
//         const response = await axios.get(`/maps/${slug}/${beachMapId}`, { params });

//         if (response.data) {
//             return response.data;
//         }
//     } catch (error) {
//         throw error;
//     }
// }

export async function getBeachProducts({ slug, params }) {
    try {
        const response = await axios.get(`/products/${slug}`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getBeachProductsByDates({ slug, params }) {
    try {
        const response = await axios.get(`/beaches/${slug}/products/dates`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        throw error;
    }
}

export async function getBeachSeasonalProducts({ slug, params }) {
    try {
        const response = await axios.get(`/beaches/${slug}/products/seasonal`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getBeachAdditionsAvailabilities({ slug, params }) {
    try {
        const response = await axios.get(`/beaches/${slug}/products/availabilities`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getBeachDates({ slug, params }) {
    try {
        const response = await axios.get(`/beaches/${slug}/dates`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getPublicBeachDates({ slug, params }) {
    try {
        const response = await axios.get(`/public-beaches/${slug}/dates`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getHybridBeachDates({ slug, params }) {
    try {
        const response = await axios.get(`/hybrid-beaches/${slug}/dates`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}
