import React, { useEffect } from 'react';
import { Route, useLocation } from "react-router-dom";
import { trackPage } from "@beacharound/beacharound-ui-helpers";

export default function SubRoute(route) {

    const location = useLocation();

    useEffect(() => {
        if(!route?.analytics) {
            return;
        }
        return trackPage(route.analytics);
    }, [location, route.analytics])

    return (
        <Route
            exact={route.exact}
            path={route.path}
            render={(props) => {
                return <route.component {...props} {...route} routes={route.routes} />
            }}
        />

    )
}