import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { useQuery } from "react-query";

import { getUser } from '../../../api/user'

import { Divider, ButtonsRow, MainButton, MainContainer, MainTitle, Paragraph, SubTitle, TitleContainer } from '@beacharound/beacharound-ui';
import { setTrackUserData } from '@beacharound/beacharound-ui-helpers';

export default function SignupThanks({ location }) {

    const locationQuery = qs.parse(location.search);
    const { backUrl } = locationQuery || {};

    let futureQuery = {
        ...locationQuery,
        backUrl: undefined,
        backUrlAfterLogin: undefined
    }

    const { data: user, status } = useQuery("user", getUser);

    useEffect(() => {
        if(!user) {
            return;
        }

        return setTrackUserData({user});
    }, [user])

    return (

        <MainContainer>

            <TitleContainer>
                <MainTitle text="Registrazione completata!" component="h1"/>
                <SubTitle text="Grazie per esserti iscritto a Beacharound" />
                <Paragraph text="Riceverai una mail per verificare il tuo account, nel frattempo puoi iniziare ad utilizzare Beacharound!"/>
            </TitleContainer>

            <Divider/>

            <ButtonsRow spaceBetween>
                <MainButton text="Scopri Beacharound" component={Link} to={backUrl ? `${backUrl}?${qs.stringify(futureQuery)}` : `/?${qs.stringify(futureQuery)}`} status={status}/>
            </ButtonsRow>

        </MainContainer>
    )
}
