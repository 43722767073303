import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { MainContainer, MainTitle, TitleContainer, ColumnContainer, Column, SimpleLink } from '@beacharound/beacharound-ui';

import beachesServicesPages from '../../data/pages/beachesServicesPages';
import { getServices } from '../../api/services';

export default function ServicesSitemap() {

    const localServices = beachesServicesPages?.map((item) => {
        return {
            name: item.title,
            slug: item.slug
        }
    });

    const { data: services } = useQuery(["servizi", "spiagge"], () => getServices({type: {$in: ["beaches"]}}))

    const data = [
        ...localServices,
        ...(services?.map(service => {
            return {
                name: `Prenota il tuo ombrellone nelle spiagge con ${service.name}`,
                slug: `prenota-un-ombrellone-nelle-migliori-spiagge-${service.slug}`,
            };
        }) || [])
    ]

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Mappa del sito - Servizi" component="div" />
            </TitleContainer>

             <ColumnContainer flex>
                {data?.map((sitemap, index) => {
                    return (
                        <Column column={2} key={index}>
                            <SimpleLink index={sitemap.slug} text={sitemap.name} to={`/${sitemap.slug}`} component={Link} />
                        </Column>
                    )
                })}
            </ColumnContainer>
        </MainContainer>
    );
}