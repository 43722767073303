import { lazy } from 'react';

import MainLayout from "../layouts/MainLayout/MainLayout";

const BookingBeachDate = lazy(() => import("../pages/Beach/Booking/BookingBeachDate"));
const BookingBeachUmbrella = lazy(() => import("../pages/Beach/Booking/BookingBeachUmbrella"));
const BookingBeachMap = lazy(() => import("../pages/Beach/Booking/BookingBeachMap"));
const BookingBeachDeckchair = lazy(() => import("../pages/Beach/Booking/BookingBeachDeckchair"));
const BookingBeachServices = lazy(() => import("../pages/Beach/Booking/BookingBeachServices"));
const BookingBeachPayment = lazy(() => import("../pages/Beach/Booking/BookingBeachPayment"));
const BookingThanks = lazy(() => import("../pages/Beach/Booking/BookingThanks"));
const BookingSteps = lazy(() => import("../pages/Beach/Booking/BookingSteps"));
const BookingBeachSeasonal = lazy(() => import("../pages/Beach/Booking/BookingBeachSeasonal"));
const BookingBeachGroupSteps = lazy(() => import("../pages/Beach/Booking/BookingBeachGroupSteps"));
const BookingBeachGroupMap = lazy(() => import("../pages/Beach/Booking/BookingBeachGroupMap"));
const BookingBeachGroupDeckchair = lazy(() => import("../pages/Beach/Booking/BookingBeachGroupDeckchair"));
const BookingBeachGroupPayment = lazy(() => import("../pages/Beach/Booking/BookingBeachGroupPayment"));
const BookingBeachGroupThanks = lazy(() => import("../pages/Beach/Booking/BookingBeachGroupThanks"));
const BookingBeachBundles = lazy(() => import("../pages/Beach/Booking/BookingBeachBundles"));

const generalRoutes = [{
    path: "/prenotazione/spiaggia/:beachSlug/date",
    component: BookingSteps,
    layout: MainLayout,
    layoutOptions: { noPadding: true },
    routes: [{
        path: "/prenotazione/spiaggia/:beachSlug/date",
        component: BookingBeachDate,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Date"],
        },
    }]
},{
//     path: "/prenotazione/spiaggia-libera/:beachSlug/date",
//     component: BookingStepsPublic,
//     layout: MainLayout,
//     layoutOptions: { noPadding: true },
//     routes: [{
//         path: "/prenotazione/spiaggia-libera/:beachSlug/date",
//         component: BookingBeachHalfDay,
//         layout: MainLayout,
//         exact: true,
//         layoutOptions: { noPadding: true },
//         analytics: {
//             name: ["Booking", "Date"],
//         },
//     }]
// },{
    path: "/prenotazione/spiaggia/:beachSlug/mappa",
    component: BookingSteps,
    layout: MainLayout,
    layoutOptions: { noPadding: true },
    routes: [{
        path: "/prenotazione/spiaggia/:beachSlug/mappa",
        component: BookingBeachMap,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Stagionale"],
        },
    }]
},{
    path: "/prenotazione/spiaggia/:beachSlug/:reservationId/grazie",
    // path: "/prenotazione/grazie/:reservationId",
    component: BookingThanks,
    layout: MainLayout,
    analytics: {
        name: ["Booking", "Grazie"],
    }
},{
    path: "/prenotazione/spiaggia/:beachSlug/gruppi/:groupId/grazie",
    // path: "/prenotazione/grazie/:reservationId",
    component: BookingBeachGroupThanks,
    layout: MainLayout,
    analytics: {
        name: ["Booking", "Grazie"],
    }
},{
    path: "/prenotazione/spiaggia/:beachSlug/gruppi/:groupId",
    component: BookingBeachGroupSteps,
    layout: MainLayout,
    layoutOptions: { noPadding: true },
    routes: [{
        path: "/prenotazione/spiaggia/:beachSlug/gruppi/:groupId/mappa",
        component: BookingBeachGroupMap,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Gruppi", "Mappa"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/gruppi/:groupId/lettini",
        component: BookingBeachGroupDeckchair,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Gruppi", "Lettini"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/gruppi/:groupId/servizi",
        component: BookingBeachServices,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Gruppi", "Servizi"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/gruppi/:groupId/pagamento",
        component: BookingBeachGroupPayment,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Gruppi", "Pagamento"],
        },
    }]
},{
    path: "/prenotazione/spiaggia/:beachSlug/:reservationId",
    component: BookingSteps,
    layout: MainLayout,
    layoutOptions: { noPadding: true },
    routes: [{
        path: "/prenotazione/spiaggia/:beachSlug/:reservationId/date",
        component: BookingBeachDate,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Date"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/:reservationId/ombrellone",
        component: BookingBeachUmbrella,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Ombrellone"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/:reservationId/seasonal",
        component: BookingBeachSeasonal,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Stagionale"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/:reservationId/mappa",
        component: BookingBeachMap,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Mappa"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/:reservationId/lettini",
        component: BookingBeachDeckchair,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Lettini"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/:reservationId/bundles",
        component: BookingBeachBundles,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Bundles"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/:reservationId/servizi",
        component: BookingBeachServices,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Servizi"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/:reservationId/cabine",
        component: BookingBeachMap,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Cabine"],
        },
    },{
        path: "/prenotazione/spiaggia/:beachSlug/:reservationId/pagamento",
        component: BookingBeachPayment,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Pagamento"],
        },
    }]
}]

export default generalRoutes