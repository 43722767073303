import React, { useEffect } from 'react';
import { useLocation, Route } from 'react-router-dom';
import { useQuery } from "react-query";

import { MainLayoutContainer, MainLayoutContent } from '@beacharound/beacharound-ui';
import MainHeader from '../../components/MainHeader';
import MainFooter from '../../components/MainFooter';

import { getUser } from "../../api/user";

export default function MainLayout({ children, options }) {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    const { data: user } = useQuery("user", getUser);

    return (
        <Route>
            <MainLayoutContainer>
                <MainHeader user={user}/>

                <MainLayoutContent noPadding={options?.noPadding} noMenu>
                    {children}
                </MainLayoutContent>

                <MainFooter/>
            </MainLayoutContainer>
        </Route>
    )
}
