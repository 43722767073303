import axiosV2 from "../_defaults/v2/anonymous";

export async function getRankings({ params }) {
    const response = await axiosV2.get("/rankings", {
        params,
    });

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function getRanking({ slug }) {
    const response = await axiosV2.get(`/rankings/${slug}`);

    if (response.data) {
        return response.data;
    }

    return response;
}
