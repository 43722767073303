import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MainContainer, MainTitle, TitleContainer, Paragraph, ColumnContainer, Column, MainButton, ButtonsRow, SectionTitle, HeadSeo, Breadcrumbs, Divider, Tile, LoadingContainer, FullImageContainer } from '@beacharound/beacharound-ui';

import { getRegions } from '../../api/places';
import { getPlaceholderImage } from '../../helpers/images/getPlaceholderImage';
import { useQuery } from 'react-query';
import BeachesRankingsHighlight from '../../components/Beaches/BeachesRankingsHighlight';
import AccessibilityHighlight from '../../components/Accessibilities/AccessibilityHighlight';
import HotelsSlider from '../../components/Hotels/HotelsSlider';
import OffersSlider from '../../components/Offers/OffersSlider';

export default function NationHotel({match}) {

    const { data: regions, isLoading: isLoadingRegions } = useQuery(["regions"], () =>
        getRegions({params: {}})
    );

    return (
        <Fragment>
            <HeadSeo
                title={[`Hotel in Italia`]}
                name={'Hotel in Italia'}
                description={`Scopri le offerte e pacchetti per hotel e alberghi al miglior prezzo. Cerca e prenota con Beacharound la tua camera al mare con la famiglia o in coppia`}
                image={getPlaceholderImage('beaches')}
                type="Country"
            />

            <FullImageContainer image={getPlaceholderImage(undefined, undefined, '/placeholders/hotel-header.jpg')} overlay>
                <MainContainer>
                    <TitleContainer>
                        <MainTitle
                            text={`Prenota un hotel in Italia`}
                            color="white"
                            center
                            component="h1"
                        />
                    </TitleContainer>
                </MainContainer>
            </FullImageContainer>

            <MainContainer>
                <TitleContainer>
                    <Breadcrumbs
                        fullUrl={match.url}
                        names={["Italia"]}
                        linkComponent={Link}
                    />
                </TitleContainer>
            </MainContainer>


            {/* Spiagge in evidenza? */}         
            <MainContainer>
                <HotelsSlider title="Hotel in evidenza" highlight />
            </MainContainer>


            <OffersSlider white more={{ url: "/offerte-pacchetti-hotel-alberghi-miglior-prezzo" }} noMargin />


            <MainContainer>
                <HotelsSlider
                    title="Hotel vicino a te"
                    subtitle={`Scopri gli hotel proposti nella zona del tuo soggiorno`}
                    nearest
                />
            </MainContainer>


            <BeachesRankingsHighlight
                title={"I best of di Beacharound"}
                subtitle={"Beacharound collabora con diversi enti per ricercare sempre gli hotel di miglior qualità da offrire ai propri utenti. Scopri quali sono!"}
                params={{
                    category: "hotels",
                }}
            />


            <MainContainer>
                <LoadingContainer isLoading={isLoadingRegions}>
                    <TitleContainer>
                        <SectionTitle text={"Le regioni"} />
                    </TitleContainer>

                    <ColumnContainer flex lowMargin>
                        {regions?.map((reg, index) => {
                            return (
                                <Column column={2} key={index}>
                                    <Tile
                                        title={reg.name}
                                        containerComponent={Link}
                                        containerLink={`/i-migliori-hotel-in-${reg.slug}`}
                                    />
                                </Column>
                            )
                        })}
                    </ColumnContainer>
                    <Divider/>
                </LoadingContainer>
            </MainContainer>

            <AccessibilityHighlight />

            <MainContainer>
                <TitleContainer>
                    <MainTitle text="Sei il gestore di una spiaggia, un hotel o offri esperienze?" />
                    <Paragraph text="Beacharound è sempre alla ricerca di nuovi partner. Inserisci la tua attività!" />
                </TitleContainer>
                <ColumnContainer>
                    <Column>
                        <ButtonsRow>
                            <MainButton
                                border
                                text="Inserisci la tua attività"
                                component="a"
                                to={process.env.REACT_APP_BUSINESS_URL}
                                blank
                            />
                        </ButtonsRow>
                    </Column>
                </ColumnContainer>

                <TitleContainer>
                    <MainTitle text="Sei il rappresentate di un comune o di un ente pubblico?" />
                    <Paragraph text="Beacharound mette a disposizione alle pubbliche amministrazioni gli strumenti necessari per promuovere e gestire le spiagge libere!" />
                </TitleContainer>
                <ButtonsRow>
                    <MainButton
                        border
                        text="Collabora con noi"
                        component="a"
                        to={process.env.REACT_APP_BUSINESS_URL}
                        blank
                    />
                </ButtonsRow>

                <Divider/>

                <ColumnContainer>
                    <Column>
                        <TitleContainer>
                            <SectionTitle
                                component="h2"
                                text={`Prenota il tuo posto nelle spiagge Italiane. Ombrellone, sdraio, lettino, stabilimento balneare, spiaggia libera. al resto pensiamo noi`}
                            />
                        </TitleContainer>
                    </Column>
                </ColumnContainer>
            </MainContainer>
        </Fragment>
    );
}