import MainLayout from "../layouts/MainLayout/MainLayout";

import QuotationHotelDate from "../pages/Hotel/Quotation/QuotationHotelDate";
import QuotationHotelRoom from "../pages/Hotel/Quotation/QuotationHotelRoom";
import QuotationHotelPeople from "../pages/Hotel/Quotation/QuotationHotelPeople";
import QuotationHotelServices from "../pages/Hotel/Quotation/QuotationHotelServices";
import QuotationHotelSummary from "../pages/Hotel/Quotation/QuotationHotelSummary";

const generalRoutes = [
    {
        path: "/preventivo/:hotelSlug/date",
        component: QuotationHotelDate,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/preventivo/:hotelSlug/:quotationId/date",
        component: QuotationHotelDate,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/preventivo/:hotelSlug/:quotationId/camera",
        component: QuotationHotelRoom,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/preventivo/:hotelSlug/:quotationId/persone",
        component: QuotationHotelPeople,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/preventivo/:hotelSlug/:quotationId/servizi",
        component: QuotationHotelServices,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
    {
        path: "/preventivo/:hotelSlug/:quotationId/riepilogo",
        component: QuotationHotelSummary,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
    },
].map(route => {
    return {
        ...route
    }
})

export default generalRoutes