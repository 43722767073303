import { getProductImage, formatDate } from '@beacharound/beacharound-ui-helpers'
import { eachDayOfInterval } from 'date-fns'
import isSameDay from 'date-fns/isSameDay'

export function getProductsList(products) {
    if(!Array.isArray(products)) { return [] }
    return products?.map(getProductsElement) || []
}

export function getProductsElement(product) {
    if(!product) { return {} }

    const priceDefault = product.activityPrices?.find(price => price.type === "DEFAULT")
    const priceMorning = product.activityPrices?.find(price => price.type === "MORNING")
    const priceAfternoon = product.activityPrices?.find(price => price.type === "AFTERNOON")

    return {
        ...product,
        name: product.name || product.type?.name,
        image: getProductImage(product?.type || {}),
        _id: product._id,
        halfDay: priceMorning && priceAfternoon ? true : false,
        daily: priceDefault?.value,
        morning: priceMorning?.value,
        afternoon: priceAfternoon?.value
    }
}

export function cleanProductBeforeUpdate(data) {

    let payload = {
        ...data
    }

    if (data.priceType && data.price) {

        let activityPrices = [{
            value: data.price,
            type: data.priceType
        }]

        payload = {
            ...payload,
            activityPrices
        }
    }

    if (data.daily || data.morning || data.afternoon) {

        let activityPrices = [{
            value: data.daily,
            type: "DEFAULT"
        }]
        
        if (data.halfDay && data.morning && data.afternoon) {
            activityPrices = [
                ...activityPrices,
                { value: data.morning, type: "MORNING" },
                { value: data.afternoon, type: "AFTERNOON" }
            ]
        }

        payload = {
            ...payload,
            activityPrices
        }
    }

    return payload;
}

export function getProductsOptions(products, options) {
    if (!Array.isArray(products)) { return [] }

    let availableProducts = products;
    
    // Options skip deve contenere elements come array di elementi da non considerare ed eventualmente una chiave utilizzata per filtrare gli elementi

    if(options?.skip?.elements) {
        availableProducts = availableProducts.filter((product) =>
            !options.skip.elements.some((element) =>
                product[options.skip.key || "_id"] === element || product[options.skip.key || "_id"] === element?.[options.skip.key || "_id"]
            )
        )
    }

    return availableProducts?.map(({ _id, name, productType }) => {
        return {
            value: _id,
            label: name || productType?.name,
            image: getProductImage(productType || {}),
        }
    }) || [];
}

export function getServicesOptions(services, reservation) {
    if(!services) {
        return []
    };

    return services?.map(service => {
        // const prices = service?.activityPrices?.[0];

        let excludedDates = service?.dates?.filter(({bookable}) => !bookable).map(({date}) => new Date(date)) || [];
        let bookable = !service.soldout;

        if(reservation?.additions) {
            const thisServiceSelected = reservation?.additions?.filter(({product}) => product?._id === service?.id);
            const thisServiceSelectedDays = thisServiceSelected?.map(({fromDate, toDate}) => eachDayOfInterval({start: new Date(fromDate), end: new Date(toDate)}));
            const listOfDays = thisServiceSelectedDays.reduce((acc, list) => {
                let datesToAdd = [];
                list.map(listDate => {
                    if(!excludedDates.some(({date}) => isSameDay(new Date(date), listDate)) && !datesToAdd.some(date => isSameDay(new Date(date), listDate))) {
                        datesToAdd = [...datesToAdd, listDate]
                    }
                    return listDate;
                });
                return [...acc, ...datesToAdd]
            }, []);
            excludedDates = [
                ...excludedDates,
                ...listOfDays
            ];
            if(excludedDates.length >= reservation?.duration) {
                bookable = false
            }
        }

        return {
            ...service,
            excludedDates: excludedDates,
            bookable,
            name: service.name,
            type: service.priceType,
            price: service?.price,
            id: service._id,
            _id: undefined
        }
    }) || [];
}


export function getBundleTitle(bundle) {

    const { relatedProducts, mainItem} = bundle || {};

    let title = bundle?.name;
    let subtitle;

    if(!!mainItem?.name) {
        title = `${mainItem.name} - ${title}`;
    }

    if(relatedProducts?.length > 0) {
        const relatedesText = relatedProducts.reduce((acc, {reference, quantity}) => {
            let payload = `${quantity}`;
            if(reference.name) {
                payload = `${payload} ${reference.name}`
            };
            return acc?.length > 0 ? `${acc}, ${payload}` : payload
        }, "");
        subtitle = relatedesText?.length > 0 && `con ${relatedesText}`
    }

    return {
        title,
        subtitle
    }
}

export function getBundleInfoString(product) {
    if(!product) {
        return ""
    };

    let priceString = product.price ? `Prezzo: ${product.price} €` : "";
    const priceComparison = product.priceComparison &&  Math.floor(product.priceComparison).toFixed(2);

    if(priceComparison && product.price) {
        priceString = `Prezzo: <strike>${priceComparison} €</strike> ${product.price} €`
    }

    if(product.fromDate && product.toDate) {
        priceString = `${priceString} - dal ${formatDate(product.fromDate, "dd MMM")} al  ${formatDate(product.toDate, "dd MMM")}`
    } else {
        priceString = `${priceString} - intera periodo di apertura`
    };
    return priceString;
}

export function getBundleDateString(product) {
    if(!product) {
        return ""
    };

    if(product.fromDate && product.toDate) {
        return `${formatDate(product.fromDate, "dd MMM")} al  ${formatDate(product.toDate, "dd MMM")}`
    }

    return `intera periodo di apertura`
}