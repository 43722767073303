import { lazy } from 'react';
import MainLayout from "../layouts/MainLayout/MainLayout";

// import Profile from "../pages/Profile/Profile";
// import ProfileActivities from "../pages/Profile/Profile/ProfileActivities";
// import ProfileReservations from "../pages/Profile/Profile/ProfileReservations";
// import ProfileQuotes from "../pages/Profile/Profile/ProfileQuotes";
// import ProfileFavourites from "../pages/Profile/Profile/ProfileFavourites";
// import ProfileReviews from "../pages/Profile/Profile/ProfileReviews";
// import ProfileReports from "../pages/Profile/Profile/ProfileReports";
// import ProfileReservationDetail from "../pages/Profile/ProfileReservationDetail";
// import ProfileQuoteDetail from "../pages/Profile/ProfileQuoteDetail";
// import ProfileReportDetail from "../pages/Profile/ProfileReportDetail";
// import ProfileEdit from "../pages/Profile/ProfileEdit";
// import NewReport from "../pages/Reports/NewReport";
// import ReportThanks from "../pages/Reports/ReportThanks";

const Profile  = lazy(() => import("../pages/Profile/Profile"));
const ProfileActivities  = lazy(() => import("../pages/Profile/Profile/ProfileActivities"));
const ProfileReservations  = lazy(() => import("../pages/Profile/Profile/ProfileReservations"));
const ProfileOrders  = lazy(() => import("../pages/Profile/Profile/ProfileOrders"));
const ProfileEvents  = lazy(() => import("../pages/Profile/Profile/ProfileEvents"));
const ProfileQuotes  = lazy(() => import("../pages/Profile/Profile/ProfileQuotes"));
const ProfileFavourites  = lazy(() => import("../pages/Profile/Profile/ProfileFavourites"));
const ProfileReviews  = lazy(() => import("../pages/Profile/Profile/ProfileReviews"));
const ProfileRentals  = lazy(() => import("../pages/Profile/Profile/ProfileRentals"));
const ProfileReports  = lazy(() => import("../pages/Profile/Profile/ProfileReports"));
const ProfileReservationDetail  = lazy(() => import("../pages/Profile/ProfileReservationDetail"));
const ProfileFoodOrderDetail  = lazy(() => import("../pages/Profile/ProfileFoodOrderDetail"));
const ProfileEventsDetail  = lazy(() => import("../pages/Profile/ProfileEventsDetail"));
const ProfileRentalsDetail  = lazy(() => import("../pages/Profile/ProfileRentalsDetail"));
const ProfileQuoteDetail  = lazy(() => import("../pages/Profile/ProfileQuoteDetail"));
const ProfileReportDetail  = lazy(() => import("../pages/Profile/ProfileReportDetail"));
const ProfileEdit  = lazy(() => import("../pages/Profile/ProfileEdit"));
const NewReport  = lazy(() => import("../pages/Reports/NewReport"));
const ReportThanks  = lazy(() => import("../pages/Reports/ReportThanks"));

const generalRoutes = [
    {
        path: "/profilo/prenotazioni/:reservationId",
        component: ProfileReservationDetail,
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["Profilo", "Prenotazioni", "Dettaglio"],
        },
    },
    {
        path: "/profilo/ordini/:reservationId",
        component: ProfileFoodOrderDetail,
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["Profilo", "Ordini", "Dettaglio"],
        },
    },
    {
        path: "/profilo/eventi/:reservationId",
        component: ProfileEventsDetail,
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["Profilo", "Eventi", "Dettaglio"],
        },
    },
    {
        path: "/profilo/servizi/:reservationId",
        component: ProfileRentalsDetail,
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["Profilo", "Rental", "Dettaglio"],
        },
    },
    {
        path: "/profilo/preventivi/:quotationId",
        component: ProfileQuoteDetail,
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["Profilo", "Preventivi", "Dettaglio"],
        },
    },
    {
        path: "/profilo/segnalazioni/:reportId",
        component: ProfileReportDetail,
        layout: MainLayout,
        exact: true,
        analytics: {
            name: ["Profilo", "Segnalazioni", "Dettaglio"],
        },
    },
    {
        path: "/profilo/modifica",
        component: ProfileEdit,
        layout: MainLayout,
        analytics: {
            name: ["Profilo", "Modifica"],
        },
    },
    {
        path: "/profilo/prenotazioni/:reservationId/segnalazioni/nuova",
        component: NewReport,
        layout: MainLayout,
        analytics: {
            name: ["Profilo", "Segnalazioni", "Prenotazioni", "Nuova"],
        },
    },
    {
        path: "/profilo/prenotazioni/:reservationId/segnalazioni/grazie",
        component: ReportThanks,
        layout: MainLayout,
        analytics: {
            name: ["Profilo", "Segnalazioni", "Prenotazioni", "Grazie"],
        },
    },
    {
        path: "/profilo/preventivi/:quotationId/segnalazioni/nuova",
        component: NewReport,
        layout: MainLayout,
        analytics: {
            name: ["Profilo", "Segnalazioni", "Preventivi", "Nuova"],
        },
    },
    {
        path: "/profilo/preventivi/:quotationId/segnalazioni/grazie",
        component: ReportThanks,
        layout: MainLayout,
        analytics: {
            name: ["Profilo", "Segnalazioni", "Preventivi", "Grazie"],
        },
    },
    {
        path: "/profilo",
        component: Profile,
        layout: MainLayout,
        routes: [
            {
                path: `/attivita`,
                component: ProfileActivities,
                analytics: {
                    name: ["Profilo", "Attività"],
                },
            },
            {
                path: `/prenotazioni`,
                component: ProfileReservations,
                analytics: {
                    name: ["Profilo", "Prenotazioni"],
                },
            },
            {
                path: `/ordini`,
                component: ProfileOrders,
                analytics: {
                    name: ["Profilo", "Ordini"],
                },
            },
            {
                path: `/eventi`,
                component: ProfileEvents,
                analytics: {
                    name: ["Profilo", "Eventi"],
                },
            },
            {
                path: `/servizi`,
                component: ProfileRentals,
                analytics: {
                    name: ["Profilo", "Rental"],
                },
            },
            {
                path: `/preventivi`,
                component: ProfileQuotes,
                analytics: {
                    name: ["Profilo", "Preventivi"],
                },
            },
            {
                path: `/preferiti`,
                component: ProfileFavourites,
                analytics: {
                    name: ["Profilo", "Preferiti"],
                },
            },
            {
                path: `/segnalazioni`,
                component: ProfileReports,
                analytics: {
                    name: ["Profilo", "Segnalazioni"],
                },
            },
            {
                path: `/recensioni`,
                component: ProfileReviews,
                analytics: {
                    name: ["Profilo", "Recensioni"],
                },
            },
        ].map((route) => {
            return {
                ...route,
                path: `/profilo${route.path}`,
                private: true,
            };
        }),
    },
];

export default generalRoutes