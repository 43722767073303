import { lazy } from 'react';
import MainLayout from "../layouts/MainLayout/MainLayout";

// import RankingsPage from "../pages/Rankings/Rankings";
// import RankingDetail from "../pages/Rankings/RankingDetail";
// import RankingDetailItem from "../pages/Rankings/RankingDetailItem";

const RankingsPage = lazy(() => import("../pages/Rankings/Rankings"));
const RankingDetail = lazy(() => import("../pages/Rankings/RankingDetail"));
const RankingDetailItem = lazy(() => import("../pages/Rankings/RankingDetailItem"));

const generalRoutes = [
    {
        path: "/classifiche",
        component: RankingsPage,
        layout: MainLayout,
        layoutOptions: {
            noPadding: true,
        },
        exact: true,
        analytics: {
            name: "Classifiche",
        },
    },
    {
        path: "/classifiche/:rankingSlug",
        component: RankingDetail,
        layout: MainLayout,
        layoutOptions: {
            noPadding: true,
        },
        exact: true,
    },
    {
        path: "/classifiche/:rankingSlug/:placeSlug",
        component: RankingDetailItem,
        layout: MainLayout,
        layoutOptions: {
            noPadding: true,
        },
    },
];

export default generalRoutes