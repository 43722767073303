import React from 'react';
import { Link } from 'react-router-dom';
import { MainContainer, MainTitle, TitleContainer, ColumnContainer, Column, SimpleLink } from '@beacharound/beacharound-ui';

import { mainSitemaps } from '../../data/pages/sitemap';
export default function SitemapsGeneral({match}) {

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Mappa del sito" component="div" />
            </TitleContainer>

            <ColumnContainer flex>
                {mainSitemaps?.map((sitemap, index) => {
                    return (
                        <Column column={2} key={index}>
                            <SimpleLink index={sitemap.slug} text={sitemap.name} to={`${match.url}/${sitemap.slug}`} component={Link} />
                        </Column>
                    )
                })}
            </ColumnContainer>
        </MainContainer>
    )
}