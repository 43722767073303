import { lazy } from 'react';
import MainLayout from "../layouts/MainLayout/MainLayout";

const BlogPage = lazy(() => import("../pages/Blog/Blog"));
const BlogTypePage = lazy(() => import("../pages/Blog/BlogType"));
const BlogDetailPage = lazy(() => import("../pages/Blog/BlogDetail"));

const generalRoutes = [
    {
        path: "/blog",
        component: BlogPage,
        layout: MainLayout,
        layoutOptions: {noPadding: true},
        exact: true,
        analytics: {
            name: "Blog",
        },
    }, {
        path: "/blog/:blogType",
        component: BlogTypePage,
        layout: MainLayout,
        layoutOptions: {noPadding: true},
        exact: true
    }, {
        path: "/blog/:blogType/:newsSlug",
        component: BlogDetailPage,
        layout: MainLayout
    }
]

export default generalRoutes