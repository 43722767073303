import React from 'react';
import { Link } from 'react-router-dom';

import { Column, ColumnContainer, Divider, MainButton, LoginContainer, MainTitle, SimpleLink, TitleContainer } from '@beacharound/beacharound-ui';
import LoginForm from '../../components/User/LoginForm';

export default function Login({ history, location }) {


    return (

        <LoginContainer
            image="/images/login.jpg"
            title="Beacharound"
            subtitle="Prenota la spiaggia<br/> giusta per te"
        >
            <TitleContainer>
                <MainTitle text="Accedi a Beacharound" component="h1" center />
            </TitleContainer>

            <ColumnContainer lowMargin>
                <Column>
                    <LoginForm />

                    <SimpleLink
                        component={Link}
                        to="/user/forgot-password"
                        text="Hai dimenticato la password?"
                    />
                </Column>
            </ColumnContainer>

            <Divider />

            <MainButton
                border
                fullWidth
                text="Registrati ora"
                component={Link}
                to={`/user/signup${location.search}`}
            />
        </LoginContainer>
    );
}
