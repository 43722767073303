export const title = `INFORMATIVA PRECONTRATTUALE E CONDIZIONI GENERALI D’USO E DI VENDITA DEI SERVIZI OFFERTI`;

export const condition = `
La Società Beacharound International Sagl con sede in Via Serafino Balestra 5, 6900, Lugano(CH), P.Iva CHE - 275.896.712, e-mail info@beacharound.com , PEC beacharound.srl@pec.it, nel prosieguo denominata "OFFERENTE", 
<br />
Premesso:
<br />
- che l’Offerente gestisce il sito www.beacharound.com; 
<br />
- che il sito è destinato all’offerta di servizi al consumatore, con possibilità di prenotare direttamente postazioni nelle spiagge convenzionate e di richiedere preventivi per il pernottamento nelle strutture alberghiere e similari convenzionate;
<br />
- che la presente informativa contiene le condizioni generali relative ai servizi offerti ed è stata predisposta unilateralmente dall’ “Offerente”, il quale potrà in ogni momento modificarne il contenuto, dandone avviso all’interno del sito e con efficacia delle modifiche a decorrere dalla data di pubblicazione;
<br />
- che la presente informativa è messa a disposizione dell’ “utente” in ogni pagina del sito, affinché sia accessibile e scaricabile in ogni momento,
<br />
<br />
Ciò premesso, si conviene e si stipula quanto segue:
<br />
<br />
1. Definizioni
<br />
Nella presente informativa, i seguenti termini hanno il significato qui di seguito specificato:
<br />
- OFFERENTE: Beacharound International Sagl
<br />
- UTENTE: Chiunque navighi all’interno del sito, al solo fine di visionare i servizi pubblicizzati ovvero per acquistarli o per mettersi in contatto con i nostri partner commerciali;
<br />
- PARTNER COMMERCIALE CONVENZIONATI: Società convenzionate con Beacharound International Sagl che si avvalgono di Beacharound.com per pubblicizzare i loro servizi e con i quali è possibile entrare in contatto attraverso il sito da parte dell’utente;
<br />
- SERVIZI: Offerte, pacchetti, abbonamenti, acquistabili direttamente attraverso il sito ovvero per i quali è possibile, per il tramite del sito, essere messi in contatto con il partner commerciale convenzionato;
<br />
- SITO: www.beacharound.com
<br />
- INFORMATIVA: Le presenti condizioni generali, che sono predisposte unilateralmente dall’offerente e regolano sia la navigazione all’interno del sito, sia l’eventuale compravendita dei servizi offerti, sia la messa in contatto con i nostri partner commerciali.
<br />
<br />
2. Ambito di applicazione
<br />
La presente informativa contiene le condizioni generali riferibili alla navigazione all’interno del sito, alla registrazione dell’utente e all’acquisto dei servizi offerti dai nostri partner commerciali. 
<br />
<br />
3. Navigazione all’interno del sito 
<br />
L’utente, con la navigazione all’interno del sito, può visionare i servizi offerti (pacchetti, abbonamenti, offerte, ecc. relativi a spiagge, hotel, ecc.), senza alcun impegno e/o obbligo di registrazione.
<br />
L’utente può liberamente aderire, attraverso il servizio di newsletter, all’invio al proprio indirizzo di posta elettronica di offerte commerciali relative ai servizi pubblicizzati da Beacharound e offerti dai partner commerciali convenzionati.
<br />
<br />
4. Registrazione 
<br />
L’utente può decidere liberamente di registrarsi al sito, seguendo la procedura guidata e indicando i propri dati personali richiesti ai fini del completamento della procedura.
<br />
Con la registrazione, l’utente può liberamente scegliere se aderire o meno all’invio di newsletter commerciali, attraverso l’apposito flag presente al termine della procedura di registrazione.
<br />
Con la registrazione al sito, all’utente viene assegnata una password associata alla mail con la quale viene effettuata la registrazione; la password può essere in ogni momento modificata dall’utente accedendo all’area riservata del sito denominata “profilo”. 
<br />
Solo gli utenti registrati possono accedere alla procedura di all’acquisto dei servizi direttamente su Beacharound e/o formulare richieste di preventivi ai partner commerciali convenzionati. 
<br />
L’utente registrato può accedere in ogni momento all’area “assistenza” presente all’interno della sezione “profilo” al fine di richiedere informazioni a Beacharound International Sagl in merito ai servizi offerti. 
<br />
<br />
5. Recensioni e pubblicazione di immagini
<br />
L’utente registrato può recensire e caricare immagini relative alle strutture convenzionate.
<br />
In caso di pubblicazione di contenuti contrari alla normativa vigente e/o che presentino profili di rilevanza penale, Beacharound.com si riserva il diritto di cancellare tali contenuti e di segnalare l’utente alle competenti Autorità. 
<br />
<br />
6. Acquisto dei servizi su Beacharound International Sagl
<br />
Beacharound.com offre la possibilità, all’utente registrato, di acquistare alcuni servizi, tra i quali, esemplificativamente, l’accesso ad una spiaggia convenzionata con prenotazione di ombrellone, lettini e servizi connessi. 
<br />
La procedura di acquisto del servizio è guidata e l’utente può completare la procedura seguendo le istruzioni che compaiono a monitor. 
<br />
L’utente ha la possibilità di selezionare la spiaggia prescelta, tra quelle indicate all’interno del sito e di:
<br />
a) selezionare in tempo reale la postazione preferita tra quelle disponibili, per la data prescelta e l’eventuale orario (se messo a disposizione dal partner convenzionato), qualora tale opzione sia resa possibile nell’apposita procedura guidata;
<br />
b) selezionare il/i servizio/i prescelti, con indicazione del relativo quantitativo, attraverso la procedura guidata, compatibilmente con la disponibilità del/i servizi/i.
<br />
La disponibilità dei servizi offerti dipende esclusivamente dal partner commerciale convenzionato.
<br />
Il pagamento dei servizi prescelti avviene esclusivamente tramite carta di credito.
<br />
La prenotazione della postazione prescelta e/o dei servizi prescelti è confermata solo a seguito dell’esito positivo del pagamento, che viene comunicato al termine della procedura guidata di acquisto.
<br />
Qualora la postazione prescelta e/o i servizi selezionati nel corso della procedura guidata di acquisto non fossero più disponibili al momento del pagamento, la prenotazione non è confermata e l’utente dovrà ripetere la procedura di acquisto dall’inizio, salvo disponibilità dei servizi offerti. 
<br />
In caso di esito positivo del pagamento, l’utente riceverà una mail di conferma della prenotazione, che gli consentirà di presentarsi alla spiaggia prescelta e di accedere al servizio richiesto, esibendo al personale la mail di conferma della prenotazione, anche direttamente dallo smartphone.  
<br />
L’utente si impegna a rispettare le regole di accesso alla spiaggia prescelta, nonché gli eventuali regolamenti d’uso e le norme di comportamento.
<br />
Al momento dell’effettiva fruizione del servizio prenotato, la struttura prenotata rilascerà il relativo documento fiscale relativo al pagamento della prestazione effettuato tramite Beacharound.com. 
<br />
<br />
7. Garanzie
<br />
Beacharound International Sagl è un semplice intermediario che consente, attraverso la piattaforma Beacharound.com, di accedere alla prenotazione di servizi offerti da strutture convenzionate ai sensi del punto 6.
<br />
Pertanto, Beacharound è responsabile esclusivamente del corretto funzionamento della piattaforma e declina ogni responsabilità connessa alla sia non veridicità delle informazioni messe a disposizione degli utenti dai partner commerciali convenzionati, sia all’eventuale inadempimento della struttura convenzionata al momento dell’esecuzione della prestazione acquistata. 
<br />
Inoltre, Beacharound risponderà alle richieste di informazioni relative al funzionamento della piattaforma e non prenderà in considerazione eventuali reclami e/o eventuali richieste riferibili alle singole strutture convenzionate e ai rapporti dell’utente con tali strutture. 
<br />
<br />
8. Cessione del contratto
<br />
La mail di prenotazione costituisce valido titolo per usufruire della prenotazione da parte di chiunque ne risulti in possesso e la esibisca, con le modalità indicate al punto 6, al personale addetto della singola struttura.
<br />
<br />
9. Rimborsi
<br />
Beacharound International Sagl non è in alcun modo responsabile di eventuali rimborsi per mancata fruizione dei servizi prenotati, dovuta a qualsiasi causa (maltempo, ecc.).
<br />
Eventuali rimborsi e/o cambi di prenotazione potranno essere concordati esclusivamente con la singola struttura prenotata, senza possibilità di coinvolgere Beacharound International Sagl -
<br />
<br />
10. Richiesta di preventivi
<br />
L’utente registrato ha la possibilità di richiedere un preventivo in relazione ai servizi offerti dai nostri partner commerciali convenzionati (a titolo esemplificativo, hotel, bed and breakfast, ecc.), pubblicizzati all’interno del sito e per i quali non è consentita la prenotazione diretta per il tramite di Beacharound.com ai sensi del punto 6. 
<br />
La richiesta di preventivo potrà essere effettuata attraverso l’apposita procedura guidata, accessibile dall’area “profilo” del sito.
<br />
La procedura guidata si conclude con l’inoltro della richiesta alla struttura convenzionata; la richiesta non costituisce in alcun modo una proposta contrattuale vincolante e giungerà direttamente al partner commerciale convenzionato, il quale avrà la possibilità di riscontrarla o meno all’indirizzo mail fornito dall’utente al momento della registrazione o al momento della richiesta di preventivo. 
<br />
Solo a seguito di eventuali e successivi contatti tra l’utente e la struttura convenzionata, potrà eventualmente concludersi un contratto vincolante per l’utente, con le modalità e nei termini liberamente e direttamente concordati dall’utente con la singola struttura. 
<br />
Beacharound.com è esente da ogni responsabilità in merito ai successivi contatti tra l’utente e il partner commerciale convenzionato e non è in alcun modo vincolata da eventuali accordi intercorsi tra l’utente e la struttura.
<br />
<br />
11. Diritto di recesso
<br />
Con riferimento ai servizi di cui al punto 6, acquistati direttamente su Beacharound.com, ai sensi dell’art. 59, comma 1 lett. n) del D. lgs. 206/2005 deve ritenersi esclusa la possibilità di attivare il recesso, trattandosi di servizi riguardanti le attività del tempo libero, con previsione di data di esecuzione specifica. 
<br />
Con riferimento ai servizi eventualmente acquistati direttamente dai nostri partner commerciali, successivamente alla richiesta di preventivo, il diritto di recesso è regolato dagli artt. 52 e ss. del D. lgs. 206/2005 e potrà essere esercitato compatibilmente con la disciplina applicabile ai singoli contratti eventualmente conclusi, direttamente nei confronti del partner commerciale convenzionato, con esonero da ogni responsabilità di Beacharound.com. 
<br />
<br />
12. Giurisdizione e Foro competente
<br />
Ogni controversia relativa all’applicazione, esecuzione, interpretazione e violazione della presente informativa è sottoposta alla giurisdizione italiana, e la stessa sarà portata alla cognizione esclusiva del Foro nel cui circondario ha il proprio domicilio il Consumatore secondo quanto previsto dal D.Lgs. 206/05. 
<br />
<br />
13. Contatti
<br />
L’utente può formulare eventuali richieste di assistenza attraverso l’apposita sezione “assistenza” ovvero inviando una mail con la richiesta di informazioni a info@beacharound.com. `

export const checks = [{
    name: "terms",
    label: 'Ho preso visione e dichiaro di accettare <a href="/terms" target="_blank">l\'informativa precontrattuale e le condizioni generali d\'uso e di vendita dei servizi offerti</a>'
}];