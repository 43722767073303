import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { ButtonsRow, CheckboxInput, Divider, MainButton, LoginContainer, MainForm, MainFormContent, MainTitle, TextInput, TitleContainer } from '@beacharound/beacharound-ui';

import qs from "query-string";
import { trackSignup } from '@beacharound/beacharound-ui-helpers';
import { createUser } from "../../../api/user";

import { checks as privacyChecks, title as privacyTitle, condition as privacyCondition } from '../../../data/conditions/privacy';
import { checks as termsChecks, title as termsTitle, condition as termsCondition } from '../../../data/conditions/terms';

const message = { message: '^Campo obbligatorio' };

const marketingText = {
    default: "Prenota la spiaggia<br/>giusta per te",
    hotels: "Cerca l'hotel<br />che fa per te",
    offers: "Richiedi subito<br />offerte imbattibili",
};

export default function SignupAccount({ history, location }) {

    const { fb_campaign_type, cta_subject_type } = qs.parse(location.search);

    let sideText = marketingText.default;

    if (fb_campaign_type === "hotels" || cta_subject_type === "hotels") { sideText = marketingText.hotels }
    if (fb_campaign_type === "offers" || cta_subject_type === "offers") { sideText = marketingText.offers }

    const cache = useQueryClient()
    const { mutate, status } = useMutation(createUser, {
        onSuccess: (data) => {
            cache.invalidateQueries('user');
            trackSignup({ user: data });

            if (!!location.search) {
                return history.push(`/user/signup/thanks${location.search}`);
            } else {
                return history.push("/user/signup/thanks");
            }
        }
    });

    let constraints = {
        email: {
            presence: message,
            email: { message: '^Email non valida' }
        },
        password: {
            presence: message,
            length: { minimum: 8 }
        },
        passwordConfirm: {
            presence: message,
            equality: 'password'
        },
        firstName: { presence: message },
        lastName: { presence: message },
        phone: { presence: message }
    };

    let conditionsToSave = [];

    if (privacyChecks) {
        constraints = {
            ...constraints,
            ...privacyChecks.reduce((acc, con) => ({ ...acc, [con.name]: { presence: true } }), {})
        };
        conditionsToSave = [
            ...conditionsToSave,
            ...privacyChecks.map(check => ({
                title: privacyTitle,
                content: privacyCondition,
                preview: check.label,
                type: check.name
            }))
        ];
    }
    if (termsChecks) {
        constraints = {
            ...constraints,
            ...termsChecks.reduce((acc, con) => ({ ...acc, [con.name]: { presence: true } }), {})
        };
        conditionsToSave = [
            ...conditionsToSave,
            ...termsChecks.map(check => ({
                title: termsTitle,
                content: termsCondition,
                preview: check.label,
                type: check.name
            }))
        ];
    }

    function addUser(data) {
        return mutate({
            email: data.email,
            password: data.password,
            profile: {
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone
            },
            conditions: conditionsToSave
        });
    }

    return (
        <LoginContainer image="/images/login.jpg" title="Beacharound" subtitle={sideText}>
            <TitleContainer>
                <MainTitle text="Registrati gratuitamente" component="h1" center />
            </TitleContainer>

            <MainForm onSubmit={addUser} constraints={constraints}>
                <MainFormContent flex>
                    <TextInput
                        name="firstName"
                        label="Nome"
                        placeholder="Il tuo nome"
                        half
                    />
                    <TextInput
                        name="lastName"
                        label="Cognome"
                        placeholder="Il tuo cognome"
                        half
                    />
                    <TextInput name="email" label="Email" placeholder="La tua email" />
                    <TextInput name="phone" label="Numero di telefono" placeholder="Il tuo numero" />
                    <TextInput type="password" name="password" autocomplete="new-password" label="Password" placeholder="Inserisci la tua password" />
                    <TextInput type="password" name="passwordConfirm" autocomplete="new-password" label="Conferma la password" placeholder="Per sicurezza scrivila di nuovo" />

                    <div style={{ height: "15px", width: "100%" }}></div>

                    {privacyChecks.map((check, index) => <CheckboxInput key={index} name={check.name} label={check.label} />)}
                    {termsChecks.map((check, index) => <CheckboxInput key={index} name={check.name} label={check.label} />)}
                </MainFormContent>

                <Divider />

                <ButtonsRow fill>
                    <MainButton border text="Torna al login" component={Link} to={`/user/login${location.search}`} />
                    <MainButton text="Prosegui" submit status={status} />
                </ButtonsRow>
            </MainForm>
        </LoginContainer>
    );
}
