import React from 'react';
import { Redirect, generatePath, useParams } from "react-router-dom";

export default function RedirectOldRoute(route) {

    const params = useParams();

    if(!route) return <Redirect to="/" />

    return <Redirect to={generatePath(route.redirectPath, params)} />;
}