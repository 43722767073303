import React, { useState } from 'react';
import { NavLink, useLocation, useHistory, Link } from 'react-router-dom';
import { Header, MobileMenu } from '@beacharound/beacharound-ui';
import { logoutUser } from "../api/user";
import { useUser } from "../contexts/user";
import { useQueryClient, useMutation } from "react-query";
import qs from 'query-string';

export default function MainHeader({ empty, activities }) {

    const [menuOpen, setMenuOpen] = useState(false)

    const history = useHistory();
    const { pathname, search } = useLocation();
    const { externalWebsiteUrl, externalSocialLink, widget } = qs.parse(search);

    const { user } = useUser();
    const profile = user?.profile;

    const cache = useQueryClient();
    const { mutate: logout } = useMutation(logoutUser, {
        onSuccess: () => {
            cache.setQueryData("user", undefined)
            return history.push(`/user/login?backUrlAfterLogin=${pathname}`);
        },
    });

    const activity = activities?.find(activity => pathname.indexOf(activity._id) > 0);

    let menuList = [{
        text: "Home",
        url: "/"
    }, {
        text: "Spiagge",
        url: "/spiagge",
        routeActive: "/spiagge"
    }, {
        text: "Hotel",
        url: "/hotel",
        routeActive: "/hotel"
    }, {
        text: "Offerte",
        url: "/offerte-pacchetti-hotel-alberghi-miglior-prezzo",
        routeActive: "/offerte-pacchetti-hotel-alberghi-miglior-prezzo"
    }, {
        text: "Blog",
        url: "/blog",
        routeActive: "/blog"
    }, {
        text: "Classifiche",
        url: "/classifiche",
        routeActive: "/classifiche"
    }].map(route => {
        return {
            ...route,
            active: route.active !== undefined ? route.active : pathname.includes(route.routeActive)
        }
    })

    if (!user) {
        menuList = [
            ...menuList,
            {
                text: 'Accedi',
                url: '/user/login',
                highlight: true
            }
        ]
    } else {
        menuList = [
            ...menuList,
            {
                text: "Profilo",
                url: "/profilo/prenotazioni",
                routeActive: "/profilo",
                noMobile: true
            }
        ]
    }

    if (!!user && user?.roles?.includes("business")) {
        menuList = [
            ...menuList,
            {
                text: 'Business',
                href: `${process.env.REACT_APP_BUSINESS_URL}/user/login`,
                target: "_blank",
                highlight: true,
            }
        ]
    }

    function logoutHandler() {
        logout();
    }

    var userLang = navigator.language || navigator.userLanguage

    return widget !== "true" && (
        <React.Fragment>
            <Header
                menuList={(externalWebsiteUrl || externalSocialLink || pathname?.includes("spiagge-promo")) ? [] : menuList}
                empty={empty}
                user={user}
                activityId={activity?._id}
                userName={profile && `${profile.firstName} ${profile.lastName}`}
                activities={activities}
                linkComponent={NavLink}
                logoUrl={"/"}
                openMenu={() => setMenuOpen(true)}
                backAction={() => history.goBack()}
                logoutHandler={logoutHandler}
                translate={!userLang?.includes("it")}
            />
            <MobileMenu
                appStyle
                linkComponent={Link}
                user={user}
                open={menuOpen}
                list={(externalWebsiteUrl || externalSocialLink) ? [] : menuList}
                closeMenu={() => setMenuOpen(false)}
                profileAction={() => history.push("/profilo/prenotazioni")}
                loginAction={() => history.push("/user/login")}
            />
        </React.Fragment>
    );
}
