// import { hasRoles } from '@beacharound/beacharound-ui-helpers';
import Cookies from 'js-cookie';
import decode from 'jwt-decode';
import { uploadImage } from './images';

import axiosV2 from './_defaults/v2/anonymous';
import userAxiosV2 from './_defaults/v2/user';

const DEFAULT_EXPIRES = 1;

export function setLocalUser(data) {
    return window.localStorage.setItem("user", JSON.stringify(data));
}

export function removeLocalUser() {
    Cookies.remove("user-authorization");
    Cookies.remove("user-refreshToken");
    Cookies.remove("user-id");

    return window.location === window.parent.location && window.localStorage.removeItem("user");
}

export function getLocalUser() {
    const userId = Cookies.get("user-id");
    const storageUser = JSON.parse(window.localStorage.getItem("user"));

    return (storageUser?._id === userId) && storageUser;
}

export function decodeUser(externalToken) {
    const token = externalToken || Cookies.get("user-authorization");
    if (!token) {
        console.log("DECODE", "nessun token trovato");
        removeLocalUser();
        return;
    }

    const user = decode(token);

    if (!user) {
        console.log("DECODE", "nessun user trovato");
        removeLocalUser();
        return;
    }

    return user;
}

function setUserCookies(response) {

    const user = decode(response.data.token);

    Cookies.set("user-authorization", response.data.token, {
        expires: DEFAULT_EXPIRES,
        // expires: expires_token.asDays(),
        // domain: window.location.host,
    });
    Cookies.set("user-refreshToken", response.data.refreshToken, {
        expires: DEFAULT_EXPIRES,
        // domain: window.location.host,
    });
    Cookies.set("user-id", user._id, {
        expires: DEFAULT_EXPIRES,
        // expires: expires_token.asDays(),
        // domain: window.location.host,
    });

}

export async function createUser(data) {
    const response = await axiosV2.post("/users", data);

    if (response.data?.auth) {

        const user = decode(response.data.token);

        setLocalUser({
            email: user.email,
            ...user.profile,
            _id: user._id
        })

        setUserCookies(response);

        return user.profile
    }

    return response
}

export async function loginUser(data) {
    const response = await axiosV2.post("/users/login", data);

    if (response.data && response.data.auth) {

        const user = decode(response.data.token);

        if (!user) {
            throw Error(
                "Questo utente non è abilitato per lavorare in questa app"
            )
        }

        setUserCookies(response);

        setLocalUser({
            email: user.email,
            ...user.profile,
            _id: user._id
        })

        return user.profile
    }

    return response
}

export async function updateUser({ data }) {
    const response = await userAxiosV2.put("/users/profile", data);

    if (response.data) {
        return response.data
    }

    return response
}

export async function getUser() {

    let refreshToken = Cookies.get("user-refreshToken");

    if (!refreshToken) {
        return;
    }

    const response = await userAxiosV2.get("/users/me");

    if (response.data) {
        return response.data
    }

    return response
}

export async function logoutUser() {
    removeLocalUser();
    return true;
}

export async function refreshUser() {

    let refreshToken = Cookies.get("user-refreshToken");
    let userId = Cookies.get("user-id");

    if (!userId || !refreshToken) {
        return;
    }

    const response = await axiosV2.post("/users/token", { _id: userId, refreshToken });

    if (response.data && response.data.auth) {

        const user = decode(response.data.token);

        if (!user) {
            throw Error(
                "Questo utente non è abilitato per lavorare in questa app"
            );
        }

        setUserCookies(response);

        return user.profile
    }

    return response
}

export async function getPutUrl({ file }) {
    const response = await userAxiosV2.post(`/users/images`, {
        contentType: file.type,
        extension: file.name.split(".")[1],
    });

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function uploadUserImage({ file }) {
    try {
        const { url, key } = await getPutUrl({ file });

        await uploadImage({ url, file });

        await updateUser({
            data: {
                image: {
                    url: key,
                },
            },
        });
    } catch (error) {
        throw error;
    }
}

export async function requestUserToken({ data }) {

    const response = await axiosV2.post("/users/password/ask", data);

    if (response.data) {
        return response.data
    }

    return response
}

export async function changeUserPassword({ data }) {
    const response = await axiosV2.post("/users/password/reset", data);

    if (response.data) {
        return response.data;
    }

    return response;
}