import React from 'react';

import { MainContainer, TitleContainer, MainTitle, MainButton, Paragraph, ButtonsRow, SimpleImage, CardContainer, ColumnContainer, Column } from '@beacharound/beacharound-ui';

import { getPlaceholderImage } from '../../../helpers/images/getPlaceholderImage';

export default function PaymentThanks({history}) {

    return (
        <MainContainer>
            <ColumnContainer lowMargin>
                <Column>
                    <SimpleImage image={getPlaceholderImage('home')}/>
                </Column>
            </ColumnContainer>

            <CardContainer highPadding>
                <TitleContainer>
                    <MainTitle text="Grazie per aver pagato la tua prenotazione con Beacharound!" center/>
                </TitleContainer>
                <TitleContainer>
                    <Paragraph text="Stiamo verificando il tuo pagamento.<br/> Riceverai una mail di conferma quando tutto sarà apposto!" center/>
                </TitleContainer>

                <ButtonsRow fill center>
                    <MainButton
                        text="Vedi le tue prenotazioni"
                        action={() => history.push(`/profilo/prenotazioni`)}
                    />
                </ButtonsRow>
            </CardContainer>
        </MainContainer>
    )
}