import React, { Fragment } from 'react';
import { useQuery } from 'react-query';

import { SimpleField, SectionTitle, LoadingContainer, CardContainer, TitleContainer, ColumnContainer, Column, Paragraph, SubTitle, Divider } from '@beacharound/beacharound-ui';
import { getReservationSchedulePrices } from '../../../api/reservations/beaches';
import { getDatesStringWithGaps } from '../../../helpers/dates';

export default function SchedulePricesSection({ reservationId, beachSlug, reservation, groupId, showFee }) {

    const { data: schedules, isLoading } = useQuery(["reservations", reservationId, "schedule"], () =>
        getReservationSchedulePrices({ beachSlug, reservationId }),
        { enabled: !!reservationId && !!beachSlug }
    );

    let products = [];
    if (schedules) {
        for (const product of (schedules || [])) {
            if (product.dates) {
                for (const date of product?.dates) {
                    const addedProductIndex = products.findIndex(({ _id: addedId, quantity: addedQuantity, type: addedType, reservationProduct: addedReservationProduct }) => {
                        const sameId = addedId === product._id;
                        const sameQuantity = addedQuantity === date.quantity;
                        const isSeasonal = addedType === "seasonal";
                        let sameReservationProduct = true;
                        if (addedType === "voucher") {
                            sameReservationProduct = addedReservationProduct === product.reservationProduct;
                        }
                        return sameId && (sameQuantity || isSeasonal) && sameReservationProduct;
                    });

                    if (addedProductIndex > -1) {
                        const hasSameDate = products[addedProductIndex].dates?.some(({ date: addedDate }) => addedDate === date?.date);
                        if (!hasSameDate) {
                            products[addedProductIndex].dates = [
                                ...products[addedProductIndex].dates,
                                date
                            ];
                        }
                    } else {
                        products = [
                            ...products,
                            {
                                ...product,
                                dates: [date],
                                quantity: date.quantity
                            }
                        ];
                    }
                }
            }
        }
    }

    let couponsPercentage;
    if (reservation?.prices?.coupons > 0) {
        let coupons = reservation?.prices?.coupons;
        let price = reservation?.price;
        if (reservation?.prices?.vouchers) {
            price = price - reservation?.prices?.vouchers;
        }
        if (coupons && price) {
            price = price + coupons;
            couponsPercentage = coupons / price * 100;
        }
    }

    let title;
    if (groupId && reservation?.firstPlacedProduct) {
        title = `Posto ${reservation.firstPlacedProduct?.number}`;
    }

    const productsTotalPrice = Math.round(((products?.reduce((acc, prod) => acc + (!!prod?.price ? prod?.price : 0), 0) * (100 - (couponsPercentage || 0)) / 100) + Number.EPSILON) * 100) / 100;
    const totalFee = Math.round((reservation?.transactions?.reduce((acc, transaction) => acc + transaction?.subjects?.reduce((tAcc, tSubject) => {
        if (tSubject?.subject === reservation?._id) {
            let payload = tAcc;
            if (!!transaction?.customerFee) {
                payload += (tSubject?.amount * (transaction?.customerFee || 0) / 100);
                payload += ((transaction?.fixedFee || 0) / transaction?.subjects?.length)
            }
            if (!!transaction?.userFee) {
                payload += (tSubject?.amount * (transaction?.userFee || 0) / 100);
            }
            return payload;
        }
        return tAcc;
    }, 0) / 100, 0) + Number.EPSILON) * 100) / 100;
    const reservationTotalPrice = Math.round(((productsTotalPrice + totalFee) + Number.EPSILON) * 100) / 100;

    return (
        <LoadingContainer isLoading={isLoading}>
            <CardContainer marginBottom={!!groupId}>
                {!!groupId ?
                    <TitleContainer>
                        <SubTitle text={title} />
                    </TitleContainer>
                    :
                    <TitleContainer>
                        <SectionTitle text="Prodotti e servizi prenotati" />
                    </TitleContainer>
                }
                {products?.map((prod, index) => {
                    const dateString = prod.dates ? getDatesStringWithGaps(prod.dates) : '';
                    let name = prod.name;
                    let subname;

                    if (["voucher", "normal", "seasonal"].includes(prod.type)) {
                        subname = prod.mainItem?.name || prod.name;

                        if (prod.relatedProducts) {
                            subname = `${subname} con ${prod.relatedProducts.map(({ name, quantity }) => `${quantity} ${name}`).join(", ")}`;
                        }
                    }

                    const hasPrice = prod.price && prod.price > 0 && prod.type !== "voucher" && !reservation?.increments?.length;

                    return (
                        <ColumnContainer lowMargin key={index}>
                            <Column column={2}>
                                <SimpleField
                                    key={index}
                                    noMargin
                                    label={subname || name}
                                    value={dateString}
                                />
                            </Column>
                            <Column column={2} flexEnd>
                                <Paragraph text={!hasPrice ? "" : `${prod.price?.toFixed(2)} €`} align="right" />
                            </Column>
                        </ColumnContainer>
                    );
                })}

                {couponsPercentage > 0 &&
                    <ColumnContainer lowMargin>
                        <Column column={2}>
                            <SimpleField
                                noMargin
                                label={`Sconto applicato`}
                            />
                        </Column>
                        <Column column={2} flexEnd>
                            <Paragraph text={`${couponsPercentage?.toFixed(2)}%`} align="right" />
                        </Column>
                    </ColumnContainer>
                }

                <Divider />

                {!!products?.length &&
                    <SimpleField
                        noMargin={!(!!showFee && !!totalFee && totalFee > 0)}
                        inline
                        label="Totale prenotazione"
                        value={`${productsTotalPrice?.toFixed(2)} €`}
                    />
                }

                {!!showFee && !!totalFee && totalFee > 0 &&
                    <Fragment>
                        <SimpleField
                            inline
                            label="Costo del servizio"
                            value={`<small> ${totalFee?.toFixed(2)} €</small>`}
                        />

                        <SimpleField
                            noMargin
                            inline
                            label="Totale pagato"
                            value={`${reservationTotalPrice.toFixed(2)} €`}
                        />
                    </Fragment>
                }
            </CardContainer>
        </LoadingContainer >
    );
}
