/* eslint-disable import/no-anonymous-default-export */
import Login from "../pages/User/Login";
import SignupAccount from "../pages/User/Signup/SignupAccount";
import SignupThanks from "../pages/User/Signup/SignupThanks";
import ForgotPassword from "../pages/User/ForgotPassword";
import ChangePassword from "../pages/User/ChangePassword";

import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
import LoginLayout from "../layouts/LoginLayout/LoginLayout";

export default [
    {
        path: "/user/login",
        component: Login,
        layout: LoginLayout,
        analytics: {
            name: "Login",
        },
    },
    {
        path: "/user/signup",
        component: SignupAccount,
        layout: LoginLayout,
        exact: true,
        analytics: {
            name: "Signup | account",
        },
    },
    {
        path: "/user/signup/thanks",
        component: SignupThanks,
        layout: EmptyLayout,
        analytics: {
            name: "Signup | thanks",
        },
    },
    {
        path: "/user/forgot-password",
        component: ForgotPassword,
        layout: EmptyLayout,
        analytics: {
            name: "Password | forgot",
        },
    },
    {
        path: "/user/restore-password/:token",
        component: ChangePassword,
        layout: EmptyLayout,
        analytics: {
            name: "Password | restore",
        },
    },
];
