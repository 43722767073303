const oldRoutes = [
    ["/strutture", "/spiagge"],
    ["/strutture/", "/spiagge"],
    ["/strutture/page", "/spiagge"],
    ["/strutture/page/", "/spiagge"],
    ["/strutture/page/*", "/spiagge"],
    ["/strutture/:beachSlug", "/spiagge/:beachSlug"],
    ["/strutture/:beachSlug/", "/spiagge/:beachSlug"],
    ["/strutture/:beachSlug/*", "/spiagge/:beachSlug"],
    ["/spiagge-libere", "/spiagge"],
    ["/spiagge-libere/", "/spiagge"],
    ["/zona/:placeSlug", "/luoghi/:placeSlug"],
    ["/zona/:placeSlug/", "/luoghi/:placeSlug"],
    ["/zona/:placeSlug/page", "/luoghi/:placeSlug"],
    ["/zona/:placeSlug/page/", "/luoghi/:placeSlug"],
    ["/zona/:placeSlug/page/*", "/luoghi/:placeSlug"],
    // ["/blog/", "/blog"],
    ["/f-a-q", "/"],
    ["/f-a-q/", "/"],
    ["/contatti", "/"],
    ["/contatti/", "/"],
    ["/area-riservata", "/beacharound-business"],
    ["/area-riservata/", "/beacharound-business"],
    ["/area-riservata/*", "/beacharound-business"],
    ["/vuoi-inserire-il-tuo-hotel", "/beacharound-business"],
    ["/vuoi-inserire-il-tuo-hotel/", "/beacharound-business"],
    ["/vuoi-inserire-il-tuo-hotel/*", "/beacharound-business"],
];

export default oldRoutes.map(route => {
    return {
        path: route[0],
        redirectPath: route[1]
    }
})