import axios from 'axios';
import Cookies from 'js-cookie';

import { refreshUser } from '../../user';

const anonymousUser = axios.create({
    baseURL: `${process.env.REACT_APP_API_V2_URL}`,
    headers: {
        'content-type': 'application/json'
    }
})

anonymousUser.interceptors.request.use(async(config) => {
    let token = Cookies.get('user-authorization');
    let refreshToken = Cookies.get("user-refreshToken");

    if(!token && !!refreshToken) {
        const data = await refreshUser();
        token = data.token;
    }

    config.headers.authorization = `Bearer ${token || "anonymous"}`

    return config;
});


export default anonymousUser;