import axiosV2 from './_defaults/v2/user';

export async function getProducts({ activitySlug, ...params }) {
    try {
        const response = await axiosV2.get(`/products/${activitySlug}`, {
            params
        });

        return response?.data || [];
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function getProduct({ activitySlug, productId, params }) {
    try {
        const response = await axiosV2.get(`/products/${activitySlug}/${productId}`, { params });

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function getProductBundles({ productId, activitySlug, params }) {
    try {
        const response = await axiosV2.get(`/products/${activitySlug}/${productId}/bundles`, {
            params
        });

        return response?.data;
    } catch (error) {
        console.log(error?.response?.status);
        if (error?.response?.status === 404) {
            return [];
        }
        throw error;
    }
}

export async function getProductRelated({ productId, activitySlug, params }) {
    try {
        const response = await axiosV2.get(`/products/${activitySlug}/${productId}/relatedes`, {
            params
        });

        return response?.data;
    } catch (error) {
        console.log(error?.response?.status);
        if (error?.response?.status === 404) {
            return [];
        }
        throw error;
    }
}

export async function getBeachProducts({ activitySlug, params }) {
    try {
        const response = await axiosV2.get(`beaches/${activitySlug}/products`, {
            params
        });

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function getProductPlacedProducts({ mapId, activitySlug, params }) {
    try {
        const response = await axiosV2.get(`/placedproducts/${activitySlug}`, {
            params: {
                map: mapId,
                ...params
            }
        });

        return response?.data;
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }
        throw error;
    }
}