const placeholderFolder = `${process.env.REACT_APP_IMAGES_PATH_ROOT}/placeholders`;
const initialSlug = "trova-prenota-spiagge-attrezzate-accessibili-disabili";
const initialTitle = "Trova e prenota spiagge attrezzate e accessibili a disabili";
const initialCoverImage = `${placeholderFolder}/beach.jpg`;

const regions = [
    {
        name: "Piemonte",
        coordinates: {
            latitude: 45.2618591,
            longitude: 7.360072,
        },
    },
    {
        name: "Valle d'Aosta",
        coordinates: {
            latitude: 45.7275065,
            longitude: 7.2303578,
        },
    },
    {
        name: "Liguria",
        coordinates: {
            latitude: 44.4470336,
            longitude: 8.8558129,
        },
    },
    {
        name: "Lombardia",
        coordinates: {
            latitude: 45.4627887,
            longitude: 9.142713,
        },
    },
    {
        name: "Trentino",
        coordinates: {
            latitude: 46.3820078,
            longitude: 10.8693141,
        },
    },
    {
        name: "Alto Adige",
        coordinates: {
            latitude: 46.3820078,
            longitude: 10.8693141,
        },
    },
    {
        name: "Veneto",
        coordinates: {
            latitude: 45.2050199, // CHIOGGIA
            longitude: 12.2700443,
        },
    },
    {
        name: "Friuli",
        coordinates: {
            latitude: 45.6523748, // TRIESTE
            longitude: 13.7486427,
        },
    },
    {
        name: "Emilia",
        coordinates: {
            latitude: 44.6948472, // COMACCHIO
            longitude: 12.1707217,
        },
    },
    {
        name: "Romagna",
        coordinates: {
            latitude: 44.6948472, // COMACCHIO
            longitude: 12.1707217,
        },
    },
    {
        name: "Toscana",
        coordinates: {
            latitude: 44.064104, // MASSA CARRARA
            longitude: 10.0509706,
        },
    },
    {
        name: "Umbria",
        coordinates: {
            latitude: 43.1349706, // MAGIONE
            longitude: 12.1957236,
        },
    },
    {
        name: "Marche",
        coordinates: {
            latitude: 43.5822285, // ANCONA
            longitude: 13.4718072,
        },
    },
    {
        name: "Lazio",
        coordinates: {
            latitude: 41.7872723, // FIUMICINO
            longitude: 12.2348101,
        },
    },
    {
        name: "Abruzzo",
        coordinates: {
            latitude: 42.4555923, // PESCARA
            longitude: 14.1861554,
        },
    },
    {
        name: "Molise",
        coordinates: {
            latitude: 41.9868044, // TERMOLI
            longitude: 14.986159,
        },
    },
    {
        name: "Campania",
        coordinates: {
            latitude: 41.9868044, // NAPOLI
            longitude: 14.986159,
        },
    },
    {
        name: "Puglia",
        coordinates: {
            latitude: 40.5316392, // FRANCAVILLA
            longitude: 17.5843135,
        },
    },
    {
        name: "Basilicata",
        coordinates: {
            latitude: 40.2523749, // SCANZANO
            longitude: 16.6930864,
        },
    },
    {
        name: "Calabria",
        coordinates: {
            latitude: 38.882592, // CATANZARO
            longitude: 16.5660602,
        },
    },
    {
        name: "Sicilia",
        coordinates: {
            latitude: 37.4858009, // CATANISETTA
            longitude: 14.0393391,
        },
    },
    {
        name: "Sardegna",
        coordinates: {
            latitude: 40.2330384, // OTTANA
            longitude: 9.0415737,
        },
    },
].map((region) => {
    return {
        ...region,
        slug: region.name.toLocaleLowerCase().replace(/\s/g, "-").replace("'", "-"),
    };
});

function getSubtitle(text) {
    return `Trova e prenota spiagge attrezzate e accessibili a disabili ${text ? ` in ${text}` : ""}`
}

function getDescription(text) {
    return `Scegli tra i migliori stabilimenti senza barriere architettoniche con accesso in sedia a rotelle ${text && ` in ${text}`}. Non perdere l'occasione per vivere il mare. Trova e prenota le spiagge attrezzate e accessibili a disabili. Gli stabilimenti di seguito riportati sono senza barriere architettoniche e permettono l'accesso in sedia a rotelle. Passerelle per raggiungere l'ombrellone, lettini alti per facilitare il trasferimento dalla carrozzina, Sand&Sea, sedia Job e Tiralo tutto l'occorrente`
}

function getMetaDescription(text) {
    return `Trova e prenota spiagge attrezzate e accessibili a disabili ${text ? ` in ${text}` : ""}. Scegli gli stabilimenti senza barriere architettoniche con accesso da passerella in sedia a rotelle `
}

function getServicePage({slug, name, image, listName, icon, coordinates}) {
    return {
        slug: `${initialSlug}-${slug}`,
        title: `${initialTitle} ${name}`,
        subtitle: getSubtitle(name),
        description: getDescription(name),
        cover: `${initialCoverImage},${image}`,
        name: listName,
        icon: icon,
        meta: { description: getMetaDescription(name) },
        nearest: {
            title: "Le spiagge più vicine a te",
            subtitle: getSubtitle(name),
            nearest: true,
            // services: services?.reduce((accumulator, service) => { return accumulator = [...accumulator, ...service.serviceSlugs?.map((s) => s)]}, [])
        },
        highlight: {
            title: "Le spiagge consigliate",
            subtitle: getSubtitle(name),
            highlight: true,
            // services: services?.reduce((accumulator, service) => { return accumulator = [...accumulator, ...service.serviceSlugs?.map((s) => s)]}, [])
        },
        list: {
            title: "Tutte le spiagge",
            subtitle: getSubtitle(name),
            coordinates
        }
    }
}

const beachesDisablesPages = [{
    slug: `${initialSlug}`,
    title: `${initialTitle}`,
    subtitle: getSubtitle(),
    description: getDescription(),
    image: 'accessibility',
    cover: `${initialCoverImage}`,
    meta: { description: getMetaDescription() },
    nearest: {
        title: "Le spiagge più vicine a te",
        subtitle: getSubtitle(),
        nearest: true,
        // services: services?.reduce((accumulator, service) => { return accumulator = [...accumulator, ...service.serviceSlugs?.map((s) => s)]}, [])
    },
    highlight: {
        title: "Le spiagge consigliate",
        subtitle: getSubtitle(),
        highlight: true,
        // services: services?.reduce((accumulator, service) => { return accumulator = [...accumulator, ...service.serviceSlugs?.map((s) => s)]}, [])
    },
    blocks: regions?.map((region) => {
        return {
            title: `${initialTitle} ${`in ${region.name}`}`,
            description: getDescription(region.name),
            slug: `${initialSlug}-${region.slug}`,
            coordinates: region.coordinates,
            accessibilities: true
        }
    })
}, ...regions.map(region => getServicePage(region))]

export default beachesDisablesPages;

