import MainLayout from "../layouts/MainLayout/MainLayout";

import BookingStepsHybrid from "../pages/Beach/Booking/BookingStepsHybrid";
import BookingThanks from "../pages/Beach/Booking/BookingThanks";

import BookingBeachPublicPeople from "../pages/Beach/Booking/BookingBeachPublicPeople";
import BookingBeachMap from "../pages/Beach/Booking/BookingBeachMap";
import BookingBeachHybridDate from "../pages/Beach/Booking/BookingBeachHybridDate";
import BookingBeachPublicReview from "../pages/Beach/Booking/BookingBeachPublicReview";

const generalRoutes = [{
    path: "/prenotazione/spiaggia-ibrida/:beachSlug",
    component: BookingStepsHybrid,
    layout: MainLayout,
    layoutOptions: { noPadding: true },
    routes: [{
        path: "/prenotazione/spiaggia-ibrida/:beachSlug/data",
        component: BookingBeachHybridDate,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia ibrida", "Data"]
        }
    },{
        path: "/prenotazione/spiaggia-ibrida/:beachSlug/posto",
        component: BookingBeachMap,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia ibrida", "Posto"]
        }
    },{
        path: "/prenotazione/spiaggia-ibrida/:beachSlug/persone",
        component: BookingBeachPublicPeople,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia ibrida", "Persone"]
        }
    },{
        path: "/prenotazione/spiaggia-ibrida/:beachSlug/riepilogo",
        component: BookingBeachPublicReview,
        layout: MainLayout,
        exact: true,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia ibrida", "Riepilogo"]
        }
    }]
},{
    path: "/prenotazione/spiaggia-ibrida/:beachSlug/:reservationId",
    component: BookingStepsHybrid,
    layout: MainLayout,
    layoutOptions: { noPadding: true },
    routes: [{
        path: "/prenotazione/spiaggia-libera/:beachSlug/:reservationId/persone",
        component: BookingBeachPublicPeople,
        layout: MainLayout,
        layoutOptions: { noPadding: true },
        analytics: {
            name: ["Booking", "Spiaggia ibrida", "Persone"]
        }
    }]
},{
    path: "/prenotazione/grazie/:reservationId",
    component: BookingThanks,
    layout: MainLayout,
    analytics: {
        name: ["Booking", "Spiaggia ibrida", "Grazie"],
    }
}]

export default generalRoutes