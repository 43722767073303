import Cookies from 'js-cookie';

import axiosV2 from "./_defaults/v2/user";
import anonymousV2 from './_defaults/v2/anonymous';

export async function createQuotation({ data }) {
    try {
        const userId = Cookies.get("user-id");
        const response = await axiosV2.post(
            `/quotations`,
            data,
            { user: userId }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function updateQuotation({ quotationId, data }) {
    try {
        const userId = Cookies.get("user-id");
        const response = await axiosV2.post(
            `/quotations/${quotationId}`,
            data,
            { user: userId }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getQuotations({ params }) {
    try {
        const response = await axiosV2.get(`/quotations`, {
            params,
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getQuotation({ quotationId, params }) {
    try {
        const response = await axiosV2.get(
            `/quotations/${quotationId}`,
            {
                params,
            }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getPublicQuotation({ quotationId }) {
    try {
        const response = await anonymousV2.get(`/quotations/public/anonymous/${quotationId}`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return {};
        console.log(error.response);
        throw error;
    }
}

export async function sendQuotation({ quotationId }) {
    try {
        const response = await axiosV2.post(
            `/quotations/${quotationId}/send`,
            {
                status: "PENDING",
            }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function replyQuotation({ quotationId, data }) {
    try {
        const response = await axiosV2.post(`/quotations/${quotationId}/send`, {
            ...data,
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}


export async function connectQuotation({ quotationId }) {
    try {
        const response = await axiosV2.post(`/quotations/${quotationId}/user`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function connectAndSendQuotation({ quotationId }) {
    try {
        await connectQuotation({ quotationId });
        const response = await sendQuotation({ quotationId });
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}